import React from "react";
import { useBreakPointUp } from "blace-frontend-library";
import { ListingsCardList } from "@/src/component/view/Listings/component/ListingsCardList";
import { ListingsTable } from "@/src/component/view/Listings/component/ListingsTable";
import { ManageListingsFallback } from "@/src/component/view/Listings/component/ManageListingsFallback";
import { SearchItem } from "@/src/type/blaceV2/search/SearchType";

interface ListingsContainerProps {
  listings: SearchItem[];
  isLoading: boolean;
  isError: boolean;
}

export default function ListingsContainer({
  listings,
  isLoading,
  isError
}: ListingsContainerProps) {
  const isDesktop = useBreakPointUp("md");
  const haveNoListing = !isLoading && !listings.length;
  const isMobileLoading = !isDesktop && isLoading;
  const isFallbackVisible = haveNoListing || isMobileLoading || isError;

  if (isFallbackVisible) {
    return (
      <ManageListingsFallback
        isLoading={isMobileLoading}
        haveNoListings={haveNoListing}
        isError={isError}
      />
    );
  }

  return isDesktop ? (
    <ListingsTable listings={listings} isLoading={isLoading} />
  ) : (
    <ListingsCardList listings={listings} />
  );
}
