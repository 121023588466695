import { INQUIRY_SUB_STATUS_EXCHANGE } from "@/src/component/view/Dashboard/DashboardConstant";
import { BlaceB2BType } from "@/src/type";
import styles from "./InquirySecondaryStatusLabel.module.scss";

interface InquirySecondaryStatusLabelProps {
  //todo: need to fix frontend types in client
  inquiryStatus: any;
}

//styles.due
//styles.overdue
//styles.processing

function InquirySecondaryStatusLabel({
  inquiryStatus,
}: InquirySecondaryStatusLabelProps) {

  if (!inquiryStatus) {
    return <></>;
  }

  //switch based on inquiry status  
  if (INQUIRY_SUB_STATUS_EXCHANGE?.[inquiryStatus.toLowerCase()]) {
    switch (INQUIRY_SUB_STATUS_EXCHANGE?.[inquiryStatus.toLowerCase()]) {
      case BlaceB2BType.B2BInquiryType.InquirySubStatus.RESPONDED:
        return (
          <div className={styles.processing}>
            {BlaceB2BType.B2BInquiryType.InquirySubStatus.RESPONDED}
          </div>
        );
    }
  }

  return <></>;
}

export default InquirySecondaryStatusLabel;
