import React from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseModal } from "blace-frontend-library";
import styles from "./BaseInfoModal.module.scss";

interface BaseInfoModalProps {
  isOpen: boolean;
  handleClose: () => void;
  infoText: string;
}

function BaseInfoModal({ isOpen, infoText, handleClose }: BaseInfoModalProps) {
  return (
    <BaseModal
      dialogProps={{
        open: isOpen,
        handleClose,
      }}
      onExit={handleClose}
    >
      <div className={styles.infoModalTitleWrapper}>
        <Typography className={styles.infoModalTitle}>{infoText}</Typography>
      </div>
      <BaseButton className={styles.infoButton} onClick={handleClose}>
        OK
      </BaseButton>
    </BaseModal>
  );
}

export default BaseInfoModal;
