import {
  HttpServiceV2,
  KEYS,
  SharedConfigManager,
} from "blace-frontend-library";
// eslint-disable-next-line import/no-unresolved
import { HTTPResponse } from "blace-frontend-library/src/service/BlaceV2/HTTP";
import { ListingItemEditableData } from "@/src/component/view/ListingManagement/ListingManagement";
import { ENV } from "@/src/const";
import { BlaceB2BType, BlaceV2Type } from "@/src/type";
import { SearchStatusAction } from "@/src/type/blaceV2";

export async function getAllSearchItems(): Promise<
  HTTPResponse<
    BlaceV2Type.BlaceV2Response<BlaceB2BType.B2BSearchType.B2BSearchAccount[]>
  >
> {
  const action = new HttpServiceV2.HTTP(
    `${SharedConfigManager.getValue(
      KEYS.BLACE_V2_API
    )}/b2b/search/all/dropdown`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}

export async function getAllListings(
  request: BlaceV2Type.BlaceV2SearchRequest
): Promise<
  HTTPResponse<
    BlaceV2Type.BlaceV2Response<
      BlaceV2Type.BlaceV2SearchResponse<BlaceV2Type.SearchType.SearchItem>
    >
  >
> {
  const action = new HttpServiceV2.HTTP(
    `${SharedConfigManager.getValue(KEYS.BLACE_V2_API)}/b2b/search/query`,
    HttpServiceV2.HTTP.methods.POST,
    request
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}

export async function getSearchItem(searchId: string) {
  const action = new HttpServiceV2.HTTP(
    `${SharedConfigManager.getValue(KEYS.BLACE_V2_API)}/b2b/search/${searchId}`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}

export async function postSearchItem(
    request: ListingItemEditableData
) {
  const action = new HttpServiceV2.HTTP(
      `${SharedConfigManager.getValue(KEYS.BLACE_V2_API)}/b2b/search`,
      HttpServiceV2.HTTP.methods.POST,
      request
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}

export async function patchSearchItem(
  searchId: string,
  request: ListingItemEditableData
) {
  const action = new HttpServiceV2.HTTP(
    `${SharedConfigManager.getValue(KEYS.BLACE_V2_API)}/b2b/search/${searchId}`,
    HttpServiceV2.HTTP.methods.PATCH,
    request
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}

export async function getSearchItemFileUploadLink(
  searchId: string,
  name: string,
  type: string,
  contentType: string,
  size: number
) {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/search/${searchId}/fileUploadLink`,
    HttpServiceV2.HTTP.methods.POST,
    {
      name,
      type,
      contentType,
      size,
    }
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function getSearchItemImageUploadLink(
  searchId: string,
  contentType: string,
  size: number
) {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/search/${searchId}/imageUploadLink`,
    HttpServiceV2.HTTP.methods.POST,
    {
      contentType,
      size,
    }
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function patchSearchItemStatus(
  searchId: string,
  status: SearchStatusAction
) {
  const action = new HttpServiceV2.HTTP(
    `${SharedConfigManager.getValue(KEYS.BLACE_V2_API)}/b2b/search/${searchId}/${status}`,
    HttpServiceV2.HTTP.methods.PATCH,
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}
