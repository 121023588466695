import { FEATURE_INQUIRY_NEEDS_UPDATE_LAUNCH_DATE, INQUIRY_DONE_STATUSES } from "@/src/const";
import { AppSearchFilterType, BlaceB2BType } from "@/src/type";

export const DASHBOARD_FILTER_PARAM = {
  SEARCH_ITEM: "searchItem", 
  INQUIRY_STATUS: "inquiryStatus", 
  SHOW_NEEDS_UPDATE_INQUIRIES: "lastActivityDate"
};

export const DASHBOARD_FILTERS: Record<string, AppSearchFilterType.AppSearchFilter> = {
  searchItem: {
    dataKey: DASHBOARD_FILTER_PARAM.SEARCH_ITEM,
    property: "catalogId",
    propertyType: "string",
    title: "Venue or Vendor Name",
    type: "multi-choice"
  },
  inquiryStatus: {
    dataKey: DASHBOARD_FILTER_PARAM.INQUIRY_STATUS,
    property: "inquiryStatus",
    propertyType: "string",
    title: "Inquiry Status",
    type: "multi-choice"
  },
  lastActivityDate: {
    dataKey: DASHBOARD_FILTER_PARAM.SHOW_NEEDS_UPDATE_INQUIRIES,
    property: "lastActivityDate",
    propertyType: "object",
    title: "Last Activity Date",
    type: "callback",
    callback: (expression: string, propertyData: string|Date) => {
      const dateLe = new Date(Number(propertyData));
      if (Number.isNaN(dateLe.getTime())) {
        return ""; // broken or unsupported data
      }
      const delimiter = "|";
      return [
        `not search.in(inquiryStatus,'${INQUIRY_DONE_STATUSES.join(delimiter)}','${delimiter}')`,
        `${expression} le ${dateLe.getTime()}`,
        `createDate ge ${FEATURE_INQUIRY_NEEDS_UPDATE_LAUNCH_DATE.getTime()}`,
      ].join(" and ");
    },
  },
};

export const INQUIRY_STATUS_EXCHANGE: Record<string,BlaceB2BType.B2BInquiryType.InquiryStatus> = {
  draft: BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN,
  submitted: BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN,
  "new": BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN,
  responded: BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN,
  closed: BlaceB2BType.B2BInquiryType.InquiryStatus.CLOSED,
  completed: BlaceB2BType.B2BInquiryType.InquiryStatus.COMPLETED,
  booked: BlaceB2BType.B2BInquiryType.InquiryStatus.BOOKED,
};

export const INQUIRY_SUB_STATUS_EXCHANGE: Record<string,BlaceB2BType.B2BInquiryType.InquirySubStatus> = {
  responded: BlaceB2BType.B2BInquiryType.InquirySubStatus.RESPONDED
};

export const INQUIRY_CLOSED_REASON_EXCHANGE: Record<BlaceB2BType.B2BInquiryType.CloseInquiryOptions,string> = {
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.ANOTHER_BUSINESS]: "Went with a Different Venue",
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.BUDGET_LOW]: "Unqualified Budget",
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.NOT_AVAILABLE_DATES]: "Date Unavailable",
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.NO_AMENITY]: "No Venues that Match",
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.NO_EVENT]: "Event Cancelled",
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.OTHER]: "Other",
  [BlaceB2BType.B2BInquiryType.CloseInquiryOptions.UNRESPONSIVE_BUSINESS]: "Unresponsive",
};

export const DASHBOARD_SEARCH_ID_PARAM = "searchId";

export const DASHBOARD_PAGE_PARAM = "page";

export const DASHBOARD_FREE_TEXT_SEARCH_PARAM = "query";

export const DASHBOARD_INQUIRY_SELECTED_PARAM = "inquiryId";

export const DASHBOARD_SORT_PARAM = "sort";

export const DASHBOARD_NAV_DRAWER_PARAM = "navDrawer";
