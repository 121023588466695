import React, { useState } from "react";
import SubjectIcon from "@mui/icons-material/Subject";
import { Box, Tab, Tabs } from "@mui/material";
import { FileDockSVG, TabPanel } from "blace-frontend-library";
import cn from "classnames";
import { ChatTabContent } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent";
import { InvoiceTabContent } from "@/src/component/view/Dashboard/component/MainContainer/components/InvoiceTabContent";
import { BlaceV2Type } from "@/src/type";
import theme from "@/src/styles/scss/theme.module.scss";
import styles from "./MiddlePanelTabs.module.scss";

export enum TabsIndexes {
  CHAT_TAB = "History",
  INVOICE_TAB = "Invoice",
}
interface MiddlePanelTabsProps {
  selectedInquiry?: BlaceV2Type.InquirySearchType;
}

function MiddlePanelTabs({ selectedInquiry }: MiddlePanelTabsProps) {
  const hasNotificationsMock = {
    History: false,
    Invoice: true,
  };
  
  const [openTab, setOpenTab] = useState(TabsIndexes.CHAT_TAB);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newOpenTab: TabsIndexes
  ) => {
    setOpenTab(newOpenTab);
  };
  const getTabClasses = (tab: TabsIndexes) => {
    const tabClasses = cn(styles.middlePanelTab, {
      [styles.selected]: tab === openTab,
      [styles.withNotifications]: hasNotificationsMock[tab],
    });
    return tabClasses;
  };

  const getIconColor = (tab: TabsIndexes) => {
    return tab === openTab ? theme.dark300 : theme.grey800;
  };

  return (
    <>
      <Tabs
        value={openTab}
        onChange={handleTabChange}
        aria-label="icon position tabs example"
        className={styles.middlePanelTabs}
      >
        <Tab
          className={getTabClasses(TabsIndexes.CHAT_TAB)}
          icon={
            <Box sx={{ margin: "2px 4px -2px 0px !important" }}>
              <SubjectIcon
                sx={{ fontSize: 16, color: getIconColor(TabsIndexes.CHAT_TAB) }}
              />
            </Box>
          }
          iconPosition="start"
          label={TabsIndexes.CHAT_TAB}
          value={TabsIndexes.CHAT_TAB}
        />
        {false && (
          <Tab
            className={getTabClasses(TabsIndexes.INVOICE_TAB)}
            icon={<FileDockSVG color={getIconColor(TabsIndexes.INVOICE_TAB)} />}
            iconPosition="start"
            label={TabsIndexes.INVOICE_TAB}
            value={TabsIndexes.INVOICE_TAB}
          />
        )}
      </Tabs>
      <TabPanel openTabName={openTab} tabName={TabsIndexes.CHAT_TAB}>
        <ChatTabContent selectedInquiry={selectedInquiry} />
      </TabPanel>
      {false && (
        <TabPanel openTabName={openTab} tabName={TabsIndexes.INVOICE_TAB}>
          <InvoiceTabContent />
        </TabPanel>
      )}
    </>
  );
}

export default MiddlePanelTabs;
