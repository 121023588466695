import React, { useCallback, useEffect, useState } from "react";
import { BaseModal } from "blace-frontend-library";
import { useSearchParams } from "react-router-dom";
import { DashboardSkeleton } from "@/src/component/base";
import { Header } from "@/src/component/partial";
import { LINK_EXPIRED_MESSAGE, SERVER_ERROR_MESSAGE } from "@/src/const";
import { B2BInquiryServiceV2 } from "@/src/service";
import { ErrorComponent } from "./components/ErrorComponent";
import { LoadingComponent } from "./components/LoadingComponent";
import { SuccessComponent } from "./components/SuccessComponent";

enum OneTimeLinkURLParams {
  HASH = "hash",
  STATUS = "at",
  INQUIRY_ID = "ri",
}

enum StatusAtValues {
  inquiry_still_in_contact = "still-in-contact",
  inquiry_booked = "booked",
  inquiry_closed = "closed",
}

function StatusChangePage() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const status = searchParams.get(OneTimeLinkURLParams.STATUS);
  const hash = searchParams.get(OneTimeLinkURLParams.HASH);
  const inquiryId = searchParams.get(OneTimeLinkURLParams.INQUIRY_ID);

  const checkForErrorByStatusCode = (statusCode: number) => {
    if (statusCode < 200 || statusCode > 299) {
      const errorText =
        statusCode === 401 || statusCode === 403
          ? LINK_EXPIRED_MESSAGE
          : SERVER_ERROR_MESSAGE;
      setError(errorText);
    }
  };

  const updateStatus = useCallback(async () => {
    if (!inquiryId || !hash || !status) {
      return;
    }

    try {
      const updateStatusResponse =
        await B2BInquiryServiceV2.updateInquiryStatusByOneTimeLink(
          inquiryId,
          hash,
          StatusAtValues[status as keyof typeof StatusAtValues]
        );
      checkForErrorByStatusCode(updateStatusResponse.status || 0);
      setIsLoading(false);
    } catch (err) {
      setError(SERVER_ERROR_MESSAGE);
      setIsLoading(false);
    }
  }, [inquiryId, hash, status]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  return (
    <>
      <Header />
      <DashboardSkeleton />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <BaseModal
          dialogProps={{
            open: true,
          }}
        >
          {!!error ? <ErrorComponent error={error} /> : <SuccessComponent />}
        </BaseModal>
      )}
    </>
  );
}

export default StatusChangePage;
