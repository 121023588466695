import React from "react";
import { Typography } from "@mui/material";
import { ListingStatus, ListingStatusLabel as ListingStatusLabelEnum } from "@/src/type/blaceV2";
import styles from "./ListingStatusLabel.module.scss";

interface ListingStatusLabelProps {
  listingStatus: ListingStatus;
}

function ListingStatusLabel({ listingStatus }: ListingStatusLabelProps) {
  const NO_DATA_TEXT = "---";
  switch (listingStatus) {
    case ListingStatus.DRAFT:
      return <div className={styles.draft}>{ListingStatusLabelEnum.DRAFT}</div>;
    case ListingStatus.PENDING_REVIEW:
      return <div className={styles.pending}>{ListingStatusLabelEnum.PENDING_REVIEW}</div>;
    case ListingStatus.PUBLISHED:
      return <div className={styles.published}>{ListingStatusLabelEnum.PUBLISHED}</div>;
    case ListingStatus.UNPUBLISHED:
      return <div className={styles.unpublished}>{ListingStatusLabelEnum.UNPUBLISHED}</div>;
    default:
      return <Typography className={styles.defaultStatus}>{NO_DATA_TEXT}</Typography>;
  }
}

export default ListingStatusLabel;
