import { InquiryMessagesList } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/ChatTabContent";
import { LogListItemManager } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/LogListItemManager";
import { BlaceV2Type } from "@/src/type";
import styles from "./LogListItem.module.scss";

interface LogListItemProps {
  inquiryMessages: InquiryMessagesList;
}

function checkIfSomethingChanged(message: BlaceV2Type.InquiryLog) {
  const { shortProperties, eventType } = message;
  if (BlaceV2Type.InquiryLogEventTypes.Change === eventType) {
    return (
      shortProperties.newStartDate !== shortProperties.oldStartDate ||
      shortProperties.newEndDate !== shortProperties.oldEndDate ||
      shortProperties.newFlexibleDates !== shortProperties.oldFlexibleDates ||
      shortProperties.newGuests !== shortProperties.oldGuests
    );
  }
  return true;
}

function LogListItem({ inquiryMessages }: LogListItemProps) {
  const messages: BlaceV2Type.InquiryLog[] = Object.values(inquiryMessages).flat();

  return (
    <ul className={styles.loglist}>
      {messages?.map((inquiryMessage, index) => {
        const show = checkIfSomethingChanged(inquiryMessage);
        if (show) {
          return (
            <li key={index} className={styles.listItem}>
              <LogListItemManager inquiryMessage={inquiryMessage} />
            </li>
          );
        }
        
          return <li key={index} />;
        
      })}
    </ul>
  );
}

export default LogListItem;
