import { Fragment, useState } from "react";
import { CircularProgress, Divider, Typography } from "@mui/material";
import {
  BaseButton,
  KEYS,
  Log,
  SharedConfigManager,
  StringHelper
} from "blace-frontend-library";
import { B2BInquiryServiceV2 } from "@/src/service";
import { EmailAttachment, EmailAttachmentContentTypes } from "@/src/type/blaceV2";
import styles from "./EmailAttachments.module.scss";


interface EmailAttachmentsProps {
  attachments?: EmailAttachment[];
  inquiryLogId: string;
}

function EmailAttachments({ attachments, inquiryLogId }: EmailAttachmentsProps) {
  const [fetchedBlobName, setFetchedBlobName] = useState<string | null>(null);
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const ASSET_URL: string = SharedConfigManager.getValue(KEYS.ASSET_URL);

  const getIsWithPreview = (contentType: string) => {
    const contentTypesWithPreview: string[] = [
      EmailAttachmentContentTypes.PDF,
      EmailAttachmentContentTypes.MP4,
      EmailAttachmentContentTypes.OGV,
    ];
    return (
      contentTypesWithPreview.includes(contentType) ||
      contentType.includes("image/")
    );
  };

  const handlePreview = async (attachment: EmailAttachment, download?: boolean) => {
    try {
      setFetchedBlobName(attachment.blobName);
      const responseData = await B2BInquiryServiceV2.getEmailLogAttachment(inquiryLogId, attachment.blobName, download);
      if (responseData.body?.metadata?.statusCode === 200) {      
        const { link } = responseData.body.payload;
        window.open(link, "_blank");
      } else {
        logToDataDog(responseData);
      }
      setFetchedBlobName(null);
    } catch (error) {
      setFetchedBlobName(null);
      logToDataDog(error);
    }
  };
  
  function logToDataDog(error: any) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "EmailAttachments.tsx",
      "AttachmentError",
      [error]
    );
  }

  const getFileIconURL = (contentType: string) => {
    switch (contentType) {
      case EmailAttachmentContentTypes.PDF:
        return `${ASSET_URL}/pdf.png`;
      case EmailAttachmentContentTypes.DOC:
      case EmailAttachmentContentTypes.DOCX:
        return `${SVG_URL}/wordIcon.svg`;
      case EmailAttachmentContentTypes.XLS:
      case EmailAttachmentContentTypes.XLSX:
        return `${SVG_URL}/excelIcon.svg`;
      case EmailAttachmentContentTypes.PPT:
      case EmailAttachmentContentTypes.PPTX:
        return `${ASSET_URL}/powerPoint.png`;
      default:
        return `${SVG_URL}/fileIcon.svg`;
    }
  };

  return (
    attachments && (
      <div className={styles.attachments}>
        {attachments.map((attachment, index) => (
          <Fragment key={attachment.blobName}>
            <div className={styles.attachmentWrapper}>
              <div className={styles.attachmentInfo}>
                <img
                  src={getFileIconURL(attachment.contentType)}
                  height="36"
                  width="32"
                  alt="file preview icon"
                />
                <div className={styles.attachmentInfoContainer}>
                  <Typography className={styles.attachmentName}>
                    {attachment.name}
                  </Typography>
                  <Typography className={styles.attachmentFileSize}>
                    {StringHelper.formatFileSize(attachment.size)}
                  </Typography>
                </div>
              </div>
              <div className={styles.attachmentActions}>
                {fetchedBlobName === attachment.blobName && <CircularProgress size={15} color="secondary" />}
                {getIsWithPreview(attachment.contentType) && (
                  <BaseButton
                    onClick={() => handlePreview(attachment, false)}
                    className={styles.action}
                    disabled={!!fetchedBlobName}
                  >
                    <img
                      src={`${SVG_URL}/eyeIcon.svg`}
                      height="20"
                      width="20"
                      alt="file preview icon"
                    />
                  </BaseButton>
                )}
                <BaseButton
                  onClick={() => handlePreview(attachment, true)}
                  className={styles.action}
                  disabled={!!fetchedBlobName}
                >
                  <img
                    src={`${SVG_URL}/downloadIcon.svg`}
                    height="20"
                    width="20"
                    alt="file download icon"
                  />
                </BaseButton>
              </div>
            </div>
            {index !== attachments.length - 1 && (
              <Divider className={styles.divider} />
            )}
          </Fragment>
        ))}
      </div>
    )
  );
}

export default EmailAttachments;
