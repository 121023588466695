import { useContext, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import {
  FormattedDateHelper,
  KEYS,
  SharedConfigManager,
  useScrollToInquiry
} from "blace-frontend-library";
import cn from "classnames";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import InquirySecondaryStatusLabel from "@/src/component/view/Dashboard/component/LeftContainer/component/InquirySecondaryStatusLabel/InquirySecondaryStatusLabel";
import InquiryStatusLabel from "@/src/component/view/Dashboard/component/LeftContainer/component/InquiryStatusLabel/InquiryStatusLabel";
import { InquiryLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import styles from "./Event.module.scss";

interface EventProps {
  inquiry: BlaceV2Type.InquirySearchType;
  selectedInquiryId?: string;
  onInquiryItemClick: (id: string) => void;
}

function Event({ inquiry, selectedInquiryId, onInquiryItemClick }: EventProps) {
  const dashboardContext = useContext(DashboardContext);
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const [isMouseEnterToEvent, setIsMouseEnterToEvent] = useState(false);

  const isCompleted = inquiry.inquiryStatus === BlaceV2Type.InquiryStatusLabels.Completed;
  const isNeedUpdate = InquiryLogic.isInquiryNeedsUpdate(inquiry);
  const isSelected = inquiry.inquiryId === selectedInquiryId;

  const scrollOffset = 250;
  const { eventRef } = useScrollToInquiry({isSelected, scrollOffset, searchId: dashboardContext.lastSearchId });

  const inquiryItemClasses = cn(styles.inquiryItem, {
    [styles.active]: inquiry.inquiryId === selectedInquiryId,
    [styles.needsUpdate]: isNeedUpdate,
  });

  const handleMouseEnter = () => {
    setIsMouseEnterToEvent(true);
  };

  const handleMouseLeave = () => {
    setIsMouseEnterToEvent(false);
  };

  const lastUpdateDate = useMemo(() => {
    const nonFormattedDate = inquiry.lastActivityDate ?? inquiry.updateDate;

    if (nonFormattedDate) {
      return isMouseEnterToEvent
        ? FormattedDateHelper.formatGlobalDate(nonFormattedDate)
        : FormattedDateHelper.formatRelativeDate(nonFormattedDate);
    }
  }, [inquiry.updateDate, inquiry.lastActivityDate, isMouseEnterToEvent]);

  return (
    <div
      className={inquiryItemClasses}
      onClick={() => onInquiryItemClick(inquiry.inquiryId)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={inquiry.inquiryId}
      data-testid="event"
      ref={eventRef}
    >
      <div className={styles.inquiryInfo}>
        <Typography className={styles.inquiryType}>
          {inquiry.inquiryType} &#x2022; {inquiry.catalogItem?.title}
        </Typography>
      </div>
      <div className={styles.eventNameWrapper}>
        {isCompleted && (
          <img
            src={`${SVG_URL}/completeIcon.svg`}
            width="16"
            height="16"
            alt="completed icon"
            className={styles.icon}
          />
        )}
        <Typography variant="h5" className={styles.inquiryTitle}>
          {inquiry.eventName}
        </Typography>
      </div>
      <Typography className={styles.dateTime}>
        {FormattedDateHelper.formatExtendedDate(inquiry.startDate)} -{" "}
        {FormattedDateHelper.formatExtendedDate(inquiry.endDate)}
      </Typography>
      <div className={styles.cardBottomWrapper}>
        <div className={styles.buttonsWrapper}>
          {isNeedUpdate && (
            <img
              src={`${SVG_URL}/InfoIcon.svg`}
              alt="warning info icon"
              width="20"
              height="20"
            />
          )}
          <InquiryStatusLabel inquiryStatus={inquiry.inquiryStatus} />
          <InquirySecondaryStatusLabel
            inquiryStatus={inquiry.inquiryStatus}
          />
        </div>
        {lastUpdateDate && (
          <div className={styles.amountDaysContainer}>
            <Typography className={styles.amountDaysPayment}>
              {isNeedUpdate ? "Needs update" : "Last update"}
            </Typography>
            <Typography className={cn(styles.lastUpdateDate, {[styles.needsUpdate]: isNeedUpdate})}>
              {lastUpdateDate}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default Event;
