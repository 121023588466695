import { FiltersList } from "@/src/component/view/Dashboard/component/LeftContainer/component/Filters/FiltersList";
import { SortingList } from "@/src/component/view/Dashboard/component/LeftContainer/component/Filters/SortingList";

interface OptionsWrapperProps {
  isSortingOpened: boolean;
  isFiltersOpened: boolean;
  handleClosePopperDropDown: () => void;
  handleMobileDialogClose: () => void;
}

function OptionsWrapper({
  isSortingOpened,
  isFiltersOpened,
  handleClosePopperDropDown,
  handleMobileDialogClose,
}: OptionsWrapperProps) {
  if (isFiltersOpened) {
    return (
      <FiltersList
        handleClosePopperDropDown={handleClosePopperDropDown}
        handleMobileDialogClose={handleMobileDialogClose}
      />
    );
  }
  if (isSortingOpened) {
    return (
      <SortingList
        handleClosePopperDropDown={handleClosePopperDropDown}
        handleMobileDialogClose={handleMobileDialogClose}
      />
    );
  }
}

export default OptionsWrapper;
