import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Log } from "blace-frontend-library";
import { useAuth } from "@/src/hook";

const AuthCallback = () => {
  const auth = useAuth();

  useEffect(() => {
    const timeout = setTimeout(() => {
      auth.redirectToSSO();
    }, 3000);

    try {
      (async ()=>{
        await auth.processAuthToken(()=>{
          clearTimeout(timeout);
        });
      })();
    }
    catch (err) {
      Log.logToDataDog(
        "ERROR",
        "AuthCallback.tsx",
        "error processing response from azure authentication",
        [err]
      );
    }

    return () => clearTimeout(timeout);
  }, [auth]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <CircularProgress data-testid="loading"/>
    </Box>
  );
};

export default AuthCallback;
