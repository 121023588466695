export enum CloseInquiryOptions {
  BUDGET_LOW = "Budget was too low",
  NOT_AVAILABLE_DATES = "Requested dates are unavailable",
  NO_AMENITY = "Client is looking for an amenity not offered",
  ANOTHER_BUSINESS = "Client booked with another business",
  NO_EVENT = "Client is not longer having the event",
  UNRESPONSIVE_BUSINESS = "Client was unresponsive",
  OTHER = "Other",
}

export enum InquiryStatus {
  OPEN = "Open",
  BOOKED = "Booked",
  COMPLETED = "Completed",
  CLOSED = "Closed",
}

export enum InquirySubStatus {
  RESPONDED = "Responded",
}
