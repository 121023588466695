import React from "react";
import { useBreakPointDown, useBreakPointUp } from "blace-frontend-library";
import { ItemInterface, ReactSortable } from "react-sortablejs";
import styles from "./ReactSortableManager.module.scss";

interface ReactSortableManagerProps {
  list: ItemInterface[];
  setList: (newState: ItemInterface[]) => void;
  markHasUnsavedData: () => void;
  children: React.ReactNode;
}

function ReactSortableManager({
  list,
  setList,
  markHasUnsavedData,
  children,
}: ReactSortableManagerProps) {
  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointUp("md");

  if (isMobile) {
    return (
      <ReactSortable
        list={list}
        setList={setList}
        className={styles.uploadedPhotos}
        handle=".dots-handle"
        scrollSensitivity={100}
        forceAutoScrollFallback={isMobile}
        scroll={true}
        onUpdate={markHasUnsavedData}
      >
        {children}
      </ReactSortable>
    );
  }
  if (isDesktop) {
    return (
      <ReactSortable
        list={list}
        setList={setList}
        className={styles.uploadedPhotos}
        filter=".exclude"
        onUpdate={markHasUnsavedData}
      >
        {children}
      </ReactSortable>
    );
  }

  return <></>;
}

export default ReactSortableManager;
