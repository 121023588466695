import { ItemInterface } from "react-sortablejs";
import { SearchImage } from "@/src/type/blaceV2/search/SearchType";

export interface SortablePhotoFile extends ItemInterface {
  id: string;
  originLink?: string;
  imageHash?: string;
  contentType?: string;
  file?: File;
  loading?: boolean;
  failed?: boolean;
}

export enum PriceDurationBE {
  PerDay = "per_day",
  PerHalfDay = "per_half_day",
  PerHour = "per_hour",
  PerPerson = "per_person",
}

export enum PriceDurationFE {
  PerDay = "Per Day",
  PerHalfDay = "Per Half Day",
  PerHour = "Per Hour",
  PerPerson = "Per Person",
}

export interface RoomPhotoFile extends SearchImage {
  loading?: boolean;
  failed?: boolean;
  isSelected?: boolean;
  file?: File;
}

export interface ListingTag {
  label: string;
  value: string;
}

export interface ListingTagSectionType {
  title: string;
  dataKey: string;
  options: ListingTag[];
}

export enum ToastMessages {
  Error = "An error occurred while updating the Listing. Please try again later.",
  Success = "The Listing has been updated successfully.",
  Saving = "Please, do not close the browser during the Save operation.",
  HasLegacyData = "You open the Listing with legacy data structure, after hit Save button the data will be converted to new data structure. This operation might take a while. Please, do not close the browser during the Save operation.",
  RoomsBeforeSaveMigrateSuffix = "Rooms data will be migrated within the next 5 minutes after Save.",
  RoomsSavedMigrateSuffix = "Rooms data migration is underway and should be complete in about 5 minutes.",
}
