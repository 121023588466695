import React from "react";
import { Box, Typography } from "@mui/material";
import { BaseIcon } from "blace-frontend-library";
import styles from "./InfoAlert.module.scss";

interface InfoAlertProps {
  text: string;
}

function InfoAlert({ text }: InfoAlertProps) {
  return (
    <Box className={styles.infoWrapper}>
      <BaseIcon iconFileName="lampIcon" iconAlt="info icon" iconSize={24} />
      <Typography className={styles.infoText}>{text}</Typography>
    </Box>
  );
}

export default InfoAlert;
