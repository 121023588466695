import { Box, Typography } from "@mui/material";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { BlaceV2Type } from "@/src/type";
import styles from "./ReopenMessage.module.scss";

interface ReopenMessageProps {
  message: BlaceV2Type.InquiryLog;
}

function ReopenMessage({ message }: ReopenMessageProps) {
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper}>
        <Typography className={styles.contentTitle}>Comment</Typography>
        <Typography className={styles.contentText}>
          {message?.shortProperties?.reopenReason ??
            "The inquiry has been reopened"}
        </Typography>
      </Box>
    </ChatLogMessage>
  );
}

export default ReopenMessage;
