import React from "react";
import { useBreakPointUp } from "blace-frontend-library";
import { RoomCardList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomCardList";
import { RoomRowList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomRowList";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";

interface RoomListContainerProps {
  rooms: Partial<SearchRoomV2>[];
}

export default function RoomListContainer({ rooms }: RoomListContainerProps) {
  const isDesktop = useBreakPointUp("md");

  return isDesktop ? (
    <RoomRowList rooms={rooms} />
  ) : (
    <RoomCardList rooms={rooms} />
  );
}
