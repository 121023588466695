import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Box, Divider, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  BaseButton,
  CopyButton,
  EmailLink,
  FormattedDateHelper,
  KEYS,
  NumberHelper,
  PhoneLink,
  RightSidebarAccordion,
  SharedConfigManager,
  TestableComponent,
  useBreakPointDown,
  useBreakPointUp,
  VendorLogic,
  VenueLogic,
} from "blace-frontend-library";
import cn from "classnames";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { IconBlock } from "@/src/component/view/Dashboard/component/IconBlock";
import { ENV, INQUIRY_CLOSED_STATUSES, INQUIRY_DONE_STATUSES } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import styles from "./RightContainer.module.scss";

interface RightContainerProps {
  isCloseInquiryModalOpen: boolean;
  isRightContainerCollapsed: boolean;
  isConfirmBookingInquiryModalOpen: boolean;
  isReopenInquiryModalOpen: boolean;
  setIsRightContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setIsCloseInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsConfirmBookingInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsReopenInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
  inquiry?: BlaceV2Type.InquirySearchType;
}

const initialOpenPanels = {
  businessInfo: true,
  eventInfo: true,
  businessContact: true,
  clientInfo: true,
};

const initialClosedPanels = {
  businessInfo: false,
  eventInfo: false,
  clientInfo: false,
  businessContact: true,
};

enum Panels {
  BUSINESS_INFO = "businessInfo",
  EVENT_INFO = "eventInfo",
  BUSINESS_CONTACT = "businessContact",
  CLIENT_INFO = "clientInfo",
}

export enum RightContainerPanelsTitle {
  BUSINESS_INFO_VENUE = "VENUE",
  BUSINESS_INFO_VENDOR = "VENDOR",
  EVENT_INFO = "EVENT",
  BUSINESS_CONTACT = "YOUR EVENT MANAGER",
  CLIENT_INFO = "CLIENT",
}

function RightContainer({
  isRightContainerCollapsed,
  isCloseInquiryModalOpen,
  isReopenInquiryModalOpen,
  isConfirmBookingInquiryModalOpen,
  setIsRightContainerCollapsed,
  setIsCloseInquiryModalOpen,
  setIsConfirmBookingInquiryModalOpen,
  setIsReopenInquiryModalOpen,
  inquiry,
}: RightContainerProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const dashboardContext = useContext(DashboardContext);
  const isSmallerDesktop = useBreakPointDown("lg");
  const isLargerMobile = useBreakPointUp("md");
  const isMediumToLargeDesktop = isLargerMobile && isSmallerDesktop;
  const { catalogItem } = inquiry ?? {};
  const [isCollapseButtonVisible, setIsCollapseButtonVisible] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState<{ [key: string]: boolean; }>(initialOpenPanels);

  const isVenue =
    inquiry?.inquiryType?.toUpperCase() === RightContainerPanelsTitle.BUSINESS_INFO_VENUE;

  const eventStartDate = inquiry?.startDate
    ? FormattedDateHelper.formatExtendedDate(inquiry.startDate)
    : "";

  const eventEndDate = inquiry?.endDate
    ? FormattedDateHelper.formatExtendedDate(inquiry.endDate)
    : "";

  const getDisplayAddress = () => {
    return isVenue
      ? VenueLogic.getVenueAddressForDisplay(catalogItem?.locations)
      : VendorLogic.getVendorAddressForDisplay(catalogItem?.regions);
  };

  const onClickCollapseHandler = () => {
    setIsRightContainerCollapsed(!isRightContainerCollapsed);
  };

  const handleChange = (panel: string) => {
    setExpandedPanels(prevExpandedPanels => ({
      ...prevExpandedPanels,
      [panel]: !prevExpandedPanels[panel],
    }));
  };

  const closeInquiryHandler = () => {
    setIsCloseInquiryModalOpen(true);
  };

  const reopenInquiryHandler = () => {
    setIsReopenInquiryModalOpen(true);
  };

  const confirmBookingHandler = () => {
    setIsConfirmBookingInquiryModalOpen(true);
  };

  function budgetStrToDollars(budget: string) {
    if (!(budget ?? "").includes("-")) {
      return "Unknown budget";
    }
    const range = budget.split("-");
    return `$${NumberHelper.withCommas(range[0])} - $${NumberHelper.withCommas(range[1])}`;
  }

  function canShowCloseBtn(): boolean {
    if (!inquiry?.inquiryStatus) {
      return false;
    }

    return !INQUIRY_CLOSED_STATUSES.includes(inquiry.inquiryStatus as BlaceV2Type.InquiryStatusLabels);
  }

  function canShowReopenBtn(): boolean {
    if (!inquiry?.inquiryStatus) {
      return false;
    }

    return inquiry.inquiryStatus === BlaceV2Type.InquiryStatusLabels.Closed;
  }

  function canShowBookedBtn(): boolean {
    if (!inquiry?.inquiryStatus) {
      return false;
    }

    return !INQUIRY_DONE_STATUSES.includes(inquiry.inquiryStatus as BlaceV2Type.InquiryStatusLabels);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapseButtonVisible(isRightContainerCollapsed);
    }, 300);

    return () => clearTimeout(timer);
  }, [isRightContainerCollapsed]);

  useEffect(() => {
    const panelsState = isMediumToLargeDesktop
      ? initialClosedPanels
      : initialOpenPanels;
    setExpandedPanels(panelsState);
  }, [isMediumToLargeDesktop]);

  return (
    <div className={styles.rightSidebarWrapper}>
      {isCollapseButtonVisible && (
        <BaseButton
          className={styles.buttonRightSidebarCollapse}
          onClick={onClickCollapseHandler}
        >
          <img
            src={`${SVG_URL}/arrowLeft.svg`}
            width="40"
            height="40"
            alt="collapse icon"
          />
        </BaseButton>
      )}
      {inquiry && (
        <>
          {catalogItem && (
            <RightSidebarAccordion
              isExpanded={expandedPanels.businessInfo}
              onChange={handleChange}
              id={Panels.BUSINESS_INFO}
              title={
                isVenue
                  ? RightContainerPanelsTitle.BUSINESS_INFO_VENUE
                  : RightContainerPanelsTitle.BUSINESS_INFO_VENDOR
              }
            >
              <AccordionDetails className={styles.accordionDetails}>
                <Box
                  className={cn(
                    styles.panelDetailsWrapper,
                    styles.marginBottomMd,
                    styles.title
                  )}
                >
                  {dashboardContext?.catalogItemImageUrl && (
                    <img
                      src={dashboardContext?.catalogItemImageUrl}
                      alt="business photo"
                      width={40}
                      height={40}
                      className={styles.bussinessImage}
                    />
                  )}
                  <Typography variant="h4" className={styles.panelDetailsTitle}>
                    {catalogItem?.title}
                  </Typography>
                </Box>
                <Box className={styles.marginBottomSm}>
                  <IconBlock icon={`${SVG_URL}/placeIcon.svg`}>
                    {getDisplayAddress()}
                  </IconBlock>
                </Box>
                <a
                  className={styles.panelDetailsWrapper}
                  href={`${ENV.ROOT_DOMAIN_URL}/d/en/${catalogItem?.dataType}/${catalogItem?.slug}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={`${SVG_URL}/outIcon.svg`}
                    alt="out icon"
                    width="16"
                    height="16"
                    className={styles.bussinessImage}
                  />
                  <Typography variant="subtitle1" className={styles.link}>
                    View listing
                  </Typography>
                </a>
              </AccordionDetails>
            </RightSidebarAccordion>
          )}
          <Divider />
          <RightSidebarAccordion
            isExpanded={expandedPanels.clientInfo}
            onChange={handleChange}
            id={Panels.CLIENT_INFO}
            title={RightContainerPanelsTitle.CLIENT_INFO}
          >
            <AccordionDetails className={styles.accordionDetails}>
              <Box
                className={cn(
                  styles.panelDetailsWrapper,
                  styles.marginBottomMd,
                  styles.title
                )}
              >
                <div className={styles.userInitial}>
                  <p className={styles.userInitialText}>
                    {dashboardContext?.clientInitials ?? ""}
                  </p>
                </div>
                <Typography variant="h4" className={styles.panelDetailsTitle}>
                  {inquiry?.clientName}
                </Typography>
              </Box>
              {false && inquiry?.phone && (
                <TestableComponent currentUser={dashboardContext.currentUser}>
                  <Box className={styles.marginBottomSm}>
                    <IconBlock icon={`${SVG_URL}/phoneIcon.svg`}>
                      <PhoneLink phoneNumber={inquiry?.phone} />
                    </IconBlock>
                  </Box>
                </TestableComponent>
              )}
              {
                //@ts-ignore
                inquiry?.emailProxy && (
                  <div className={styles.managerEmail}>
                    <IconBlock icon={`${SVG_URL}/emailFillIcon.svg`}>
                      <EmailLink email={inquiry?.emailProxy} />
                    </IconBlock>
                    <Box className={styles.copyButtonContainer}>
                      <CopyButton textToCopy={inquiry?.emailProxy} hasOnlyIcon={isMediumToLargeDesktop} />
                    </Box>
                  </div>
                )
              }
            </AccordionDetails>
          </RightSidebarAccordion>
          <Divider />
          <RightSidebarAccordion
            isExpanded={expandedPanels.eventInfo}
            onChange={handleChange}
            id={Panels.EVENT_INFO}
            title={RightContainerPanelsTitle.EVENT_INFO}
          >
            <AccordionDetails className={styles.accordionDetails}>
              <Typography
                variant="h4"
                className={cn(styles.panelDetailsTitle, styles.marginBottomMd)}
              >
                {inquiry?.eventName}
              </Typography>
              <Box className={styles.marginBottomSm}>
                <IconBlock icon={`${SVG_URL}/personIcon.svg`}>
                  {inquiry?.guests} guests
                </IconBlock>
              </Box>
              <Box className={styles.marginBottomSm}>
                <IconBlock icon={`${SVG_URL}/placeIcon.svg`}>
                  {
                    //@ts-ignore
                    budgetStrToDollars(inquiry?.budget)
                  }
                </IconBlock>
              </Box>
              <IconBlock icon={`${SVG_URL}/calendarIcon.svg`}>
                {`${eventStartDate} - ${eventEndDate}`}
              </IconBlock>
              {inquiry?.flexibleDates && (
                <IconBlock>Dates are flexible</IconBlock>
              )}
            </AccordionDetails>
          </RightSidebarAccordion>
          <Divider />
        </>
      )}
      <div className={styles.buttonWrapper}>
        {canShowBookedBtn() && (
          <BaseButton
            className={styles.buttonConfirmBookingInquiry}
            disabled={isConfirmBookingInquiryModalOpen}
            onClick={confirmBookingHandler}
            data-testid="book-btn"
          >
            Confirm Booking
          </BaseButton>
        )}
        {canShowReopenBtn() && (
          <BaseButton
            className={styles.buttonReopenInquiry}
            disabled={isReopenInquiryModalOpen}
            onClick={reopenInquiryHandler}
            data-testid="reopen-btn"
          >
            Reopen Inquiry
          </BaseButton>
        )}
        {canShowCloseBtn() && (
          <BaseButton
            className={styles.buttonCloseInquiry}
            disabled={isCloseInquiryModalOpen}
            onClick={closeInquiryHandler}
            data-testid="close-btn"
          >
            <img
              src={`${SVG_URL}/closeInquiryIcon.svg`}
              alt="close inquiry icon"
              width="16"
              height="16"
              className={styles.closeInquiryIcon}
            />
            Close Inquiry
          </BaseButton>
        )}
        {(canShowBookedBtn() || canShowCloseBtn()) && (
          <Divider className={styles.divider} />
        )}
      </div>
    </div>
  );
}

export default RightContainer;
