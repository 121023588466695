import { InquiryStatusLabels } from "@/src/type/blaceV2";

export const INQUIRY_DONE_STATUSES = [
    InquiryStatusLabels.Closed,
    InquiryStatusLabels.Booked,
    InquiryStatusLabels.Qualified,
    InquiryStatusLabels.Completed,
];

export const INQUIRY_CLOSED_STATUSES = [
    InquiryStatusLabels.Closed,
    InquiryStatusLabels.Qualified,
    InquiryStatusLabels.Completed,
];
