import { useMemo } from "react";
import { Typography } from "@mui/material";
import { ArrowSVG, CheckFillSVG, ErrorFillSVG, FormattedDateHelper } from "blace-frontend-library";
import cn from "classnames";
import { BlaceV2Type } from "@/src/type";
import styles from "./ChangedEventDataItem.module.scss";

interface ChangedEventDataItemProps {
  oldValue?: string | number | boolean;
  newValue?: string | number | boolean;
  title: BlaceV2Type.ChangedShortProperties;
}
function ChangedEventDataItem({
  oldValue,
  newValue,
  title,
}: ChangedEventDataItemProps) {
  const isValueChanged = oldValue !== newValue;
  const getDataItemContent = useMemo(() => {
    const showStartDateValue = BlaceV2Type.ChangedShortProperties.StartDate === title;
    const showEndDateValue = BlaceV2Type.ChangedShortProperties.EndDate === title;
    const showFlexibleDatesValue = BlaceV2Type.ChangedShortProperties.DateFlexibility === title;
    const showGuestsValue = BlaceV2Type.ChangedShortProperties.Guests === title;

    if (showStartDateValue || showEndDateValue) {
      return (
        <>
          <Typography className={styles.marked}>
            {FormattedDateHelper.formatExtendedDate(Number(oldValue))}
          </Typography>
          <ArrowSVG />
          <Typography className={styles.marked}>
            {FormattedDateHelper.formatExtendedDate(Number(newValue))}
          </Typography>
        </>
      );
    }

    if (showFlexibleDatesValue) {
      return (
        <>
          <Typography
            className={cn(styles.marked, styles.dateFlexibilityElementWrapper)}
          >
            {oldValue ? <CheckFillSVG /> : <ErrorFillSVG />}
            {oldValue ? "Yes" : "No"}
          </Typography>
          <ArrowSVG />
          <Typography
            className={cn(styles.marked, styles.dateFlexibilityElementWrapper)}
          >
            {newValue ? <CheckFillSVG /> : <ErrorFillSVG />}
            {newValue ? "Yes" : "No"}
          </Typography>
        </>
      );
    }

    if (showGuestsValue) {
      return (
        <>
          <Typography className={styles.marked}>{oldValue}</Typography>
          <ArrowSVG />
          <Typography className={styles.marked}>{newValue}</Typography>
        </>
      );
    }
  }, [oldValue, newValue, title]);

  if (!isValueChanged) {
    return null;
  }

  return (
    <div className={styles.dataItemWrapper}>
      <Typography className={styles.dataItemTitle}>{title}</Typography>
      <div className={styles.dataItemContent}>{getDataItemContent}</div>
    </div>
  );
}

export default ChangedEventDataItem;
