import { Box, Typography } from "@mui/material";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { BlaceV2Type } from "@/src/type";
import styles from "./ClosedMessage.module.scss";

interface ClosedMessageProps {
  message: BlaceV2Type.InquiryLog;
}

function ClosedMessage({ message }: ClosedMessageProps) {
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper}>
        <Typography className={styles.contentTitle}>
          {//@ts-ignore
          message.sourceType === "business" ? "Initiated by business" : "Initiated by client"
        }
        </Typography>
        <Typography className={styles.contentText}>
          {//@ts-ignore
            message?.shortProperties?.fullReason ?? "The inquiry has been closed"
          }
        </Typography>
      </Box>
    </ChatLogMessage>
  );
}

export default ClosedMessage;
