import React from "react";
import { List, ListItem } from "@mui/material";
import { ListingCard } from "@/src/component/view/Listings/component/ListingsCardList/component/ListingCard";
import { SearchItem } from "@/src/type/blaceV2/search/SearchType";
import styles from "./ListingsCardList.module.scss";

interface ListingCardListProps {
  listings: SearchItem[];
}

function ListingsCardList({ listings }: ListingCardListProps) {

  return (
    <List className={styles.listingsList} data-testid="listingsCardList">
      {listings.map((listing) => (
        <ListItem className={styles.inquiryCard} key={listing.searchId}>
          <ListingCard listing={listing} />
        </ListItem>
      ))}
    </List>
  );
}

export default ListingsCardList;
