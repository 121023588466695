import { useContext } from "react";
import { List } from "@mui/material";
import { CategoryItem } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { ListingStatus } from "@/src/type/blaceV2";
import styles from "./MainSectionStepper.module.scss";
import { ItemStep } from "./components/ItemStep";

interface MainSectionStepperProps {
  categoriesList: CategoryItem[];
}

function MainSectionStepper({ categoriesList }: MainSectionStepperProps) {
  const { listingItemData } = useContext(ListingManagementContext) || {};

  if (!!listingItemData && listingItemData.status !== ListingStatus.DRAFT) {
    return false;
  }

  return (
    <List className={styles.stepperWrapper}>
      {categoriesList.map(({ id, category, completed }) => (
        <ItemStep id={id} category={category} completed={completed} key={id} />
      ))}
    </List>
  );
}

export default MainSectionStepper;
