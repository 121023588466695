import React from "react";
import { BaseLoader } from "blace-frontend-library";
import styles from "./FallbackListingManager.module.scss";

interface FallbackListingManagerProps {
  isLoading: boolean;
}

function FallbackListingManager({
  isLoading,  
}: FallbackListingManagerProps) {

  if (isLoading) {
    return <div className={styles.pageLoadingLayout}><BaseLoader/></div>;
  }

  return <></>;
}

export default FallbackListingManager;
