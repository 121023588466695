import { Box, Typography } from "@mui/material";
import { PriceHelper } from "blace-frontend-library";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { BlaceV2Type } from "@/src/type";
import styles from "./BookedMessage.module.scss";

interface BookedMessageProps {
  message: BlaceV2Type.InquiryLog;
}

function BookedMessage({ message }: BookedMessageProps) {
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper}>
        <Typography className={styles.contentTitle}>
          Inquiry Totals
        </Typography>
        <Typography className={styles.contentText}>
          Total Booking Amount: {PriceHelper.formatFormInputValue(PriceHelper.centsToDollars(message.shortProperties.totalAmountInCents).toString())}
        </Typography>
        <Typography className={styles.contentText}>
          BLACE Referral Amount: {PriceHelper.formatFormInputValue(PriceHelper.centsToDollars(message.shortProperties.referralAmountInCents).toString())}
        </Typography>
      </Box>
    </ChatLogMessage>
  );
}

export default BookedMessage;
