import React, { PropsWithChildren, useState } from "react";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import {
  createTheme,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import type { EmotionCache } from "@emotion/cache";
import createCache from "./createCache";
import { BREAKPOINTS } from "./customization/breakpoints";
import componentOverride from "./customization/componentOverride";
import palette from "./customization/palette";
import typography from "./customization/typography";

function createAppTheme(
  navType: "dark" | "light" = "light",
  borderRadius: number = 2,
  fontFamily: string = "'Poppins', sans-serif",
  outlinedFilled: boolean = true
): { themes: Theme; colorTheme: Theme } {
  /**
   * set up the theme with color
   */
  const colorTheme: Theme = palette(navType);

  /**
   * set up the theme with color
   */
  const typographyTheme: TypographyOptions = typography(
    colorTheme,
    borderRadius,
    fontFamily
  );

  /**
   * memorize the theme options
   */
  const themeOptions: ThemeOptions = {
    direction: "ltr",
    palette: colorTheme.palette,
    typography: typographyTheme,
    shape: {
      borderRadius,
    },
    /**
     * these need to be the same as in variables.scss
     * TODO: fix this issue result from global import
     */
    breakpoints: {
      values: BREAKPOINTS,
    },
    //customShadows: themeCustomShadows,
  };

  /**
   * finalize the theme and provided to mui
   */
  const themes: Theme = createTheme(themeOptions);
  themes.components = componentOverride(
    themes,
    borderRadius,
    outlinedFilled,
    BREAKPOINTS
  );
  return { themes, colorTheme };
}

interface ThemeCustomizationProps {
  emotionCache: EmotionCache;
}

//https://dev.to/hpouyanmehr/nextjs-mui-v5-tutorial-2k35
const clientSideCache = createCache();

function ThemeCustomization({
  children,
  emotionCache = clientSideCache,
}: PropsWithChildren<ThemeCustomizationProps>) {
  //TODO: make this read from the to be created app config
  const navType: "dark" | "light" = "light";
  const borderRadius = 2;
  const fontFamily = "'Poppins', sans-serif";
  const outlinedFilled = true;
  const [theme] = useState<{ themes: Theme; colorTheme: Theme }>(
    createAppTheme(navType, borderRadius, fontFamily, outlinedFilled)
  );

  return (
    <>
      <CacheProvider value={emotionCache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme.themes}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </>
  );
}

export default ThemeCustomization;
