import { memo } from "react";
import { Event } from "@/src/component/view/Dashboard/component/LeftContainer/component/Event";
import { BlaceV2Type } from "@/src/type";

interface EventListProps {
  inquriesList?: BlaceV2Type.InquirySearchType[] | [];
  selectedInquiryId?: string;
  onInquiryItemClick: (id: string) => void;
}

function EventList({
  inquriesList,
  selectedInquiryId,
  onInquiryItemClick,
}: EventListProps) {
  return (
    <>
      {inquriesList?.map((inquiry: BlaceV2Type.InquirySearchType) => {
        return (
          <Event
            key={inquiry.inquiryId}
            inquiry={inquiry}
            selectedInquiryId={selectedInquiryId}
            onInquiryItemClick={onInquiryItemClick}
          />
        );
      })}
    </>
  );
}

export default memo(EventList);
