import { memo, useMemo } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import {
  DASHBOARD_FILTER_PARAM,
  INQUIRY_STATUS_EXCHANGE
} from "@/src/component/view/Dashboard/DashboardConstant";
import { BlaceB2BType } from "@/src/type";
import styles from "./StatusFilter.module.scss";

export interface StatusFilters {
  open: boolean;
  completed: boolean;
  canceled: boolean;
}

interface StatusFilterProps {
  preFilterState: any;
  setPreFilterState: (state: any) => void;
}

const FILTER_OBJECT_STATE = `${DASHBOARD_FILTER_PARAM.INQUIRY_STATUS}ObjectState`;

function StatusFilter({
  preFilterState,
  setPreFilterState
}: StatusFilterProps) {
  const customLabelStyles = useMemo(() => {
    //todo - create and use theme variables for all colours
    return {
      "& .MuiFormControlLabel-label": {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        color: "#455064",
      },
    };
  }, []);

  const customCheckboxStyles = useMemo(() => {
    //todo - create and use theme variables for all colours
    return {
      color: "#455064",
      "&.Mui-checked": {
        color: "#205CDF",
      },
    };
  }, []);

  /**
   * exchange the frontend values for the backend inquiry status values
   * 
   * @param newState - the frontend state values
   * @returns 
   */
  function translateStatusForRequest(
    newState: string[]
  ): string[] | "" {
    const forRequest: string[] = [];
    if ((newState ?? []).length === 0) {
      return "";
    }

    //exchange the keys frontend -> backend
    for (const optKey of Object.keys(INQUIRY_STATUS_EXCHANGE)) {
      if (newState.includes(INQUIRY_STATUS_EXCHANGE[optKey].toString())) {
        forRequest.push(
          optKey
        );
      }
    }
    return forRequest;
  }

  /**
   * check for a default state to return filters based on url params
   * 
   * @returns 
   */
  function checkForDefaultState(): undefined | string[] {
    if (
      !preFilterState?.[DASHBOARD_FILTER_PARAM.INQUIRY_STATUS] || 
      !Array.isArray(preFilterState[DASHBOARD_FILTER_PARAM.INQUIRY_STATUS])
    ) {
      return;
    }

    const defaultState: string[] = [];
    for (const opt of (preFilterState[DASHBOARD_FILTER_PARAM.INQUIRY_STATUS] ?? [])) {
      const frontendFilterValue = INQUIRY_STATUS_EXCHANGE[opt];
      if (!defaultState.includes(frontendFilterValue)) {
        defaultState.push(frontendFilterValue);
      }
    }
    return defaultState;
  }

  /**
   * check if the filter should be active
   * 
   * @param inquiryStatus - the status the filter represents
   * @returns 
   */
  function checkStateForStatus(
    inquiryStatus: BlaceB2BType.B2BInquiryType.InquiryStatus
  ): boolean {
    const defaultState = checkForDefaultState();
    if (Array.isArray(preFilterState[FILTER_OBJECT_STATE]) || Array.isArray(defaultState)) {
      const options: string[] = preFilterState[FILTER_OBJECT_STATE] ?? defaultState;
      const selected = (options ?? [])?.find(
        opt => opt === inquiryStatus
      );
      return typeof selected === "string";
    }
    return false;
  }

  /**
   * when a inquiry status filter is clicked handle the action
   * 
   * @param inquiryStatus - the status the filter represents
   */
  function onChangeHandler(
    inquiryStatus: BlaceB2BType.B2BInquiryType.InquiryStatus
  ) {
    const defaultState = checkForDefaultState();
    const isSelected = checkStateForStatus(inquiryStatus);
    const options: string[] = Array.isArray(preFilterState[FILTER_OBJECT_STATE])
      ? preFilterState[FILTER_OBJECT_STATE]
      : defaultState ?? [];

    const newState: string[] = isSelected
      ? options.filter(opt => opt !== inquiryStatus)
      : [...options, ...[inquiryStatus]];

    setPreFilterState({
      ...preFilterState,
      ...{
        [FILTER_OBJECT_STATE]: newState,
        [DASHBOARD_FILTER_PARAM.INQUIRY_STATUS]: translateStatusForRequest(newState)
      }
    });
  }

  return (
    <>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={checkStateForStatus(BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN)}
              onChange={() => {
                onChangeHandler(BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN);
              }}
            />
          }
          label="Open"
        />
      </FormGroup>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={checkStateForStatus(BlaceB2BType.B2BInquiryType.InquiryStatus.BOOKED)}
              data-testid="checkbox"
              onChange={() => {
                onChangeHandler(BlaceB2BType.B2BInquiryType.InquiryStatus.BOOKED);
              }}
            />
          }
          label="Booked"
        />
      </FormGroup>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={checkStateForStatus(BlaceB2BType.B2BInquiryType.InquiryStatus.COMPLETED)}
              data-testid="checkbox"
              onChange={() => {
                onChangeHandler(BlaceB2BType.B2BInquiryType.InquiryStatus.COMPLETED);
              }}
            />
          }
          label="Completed"
        />
      </FormGroup>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={checkStateForStatus(BlaceB2BType.B2BInquiryType.InquiryStatus.CLOSED)}
              onChange={() => {
                onChangeHandler(BlaceB2BType.B2BInquiryType.InquiryStatus.CLOSED);
              }}
            />
          }
          label="Closed"
        />
      </FormGroup>
    </>
  );
}

export default memo(StatusFilter);
