import React, { forwardRef, PropsWithChildren, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DraggablePaper, useDragToClose } from "blace-frontend-library";
import cn from "classnames";
import { InquiryModalTypes } from "@/src/type/blaceV2";
import styles from "./BaseInquiryModal.module.scss";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface BaseInquiryModalProps {
  isOpen: boolean;
  onClose: () => void;
  error: string | null;
  inquiryModalType: InquiryModalTypes;
}

enum InquiryModalTitles {
  REOPEN = "Reopen inquiry?",
  CLOSE = "Close Inquiry",
  BOOK = "Confirm Booking Amounts",
}

enum InquiryModalSubTitles {
  REOPEN = "Tell the client why you’re reopening the inquiry",
  CLOSE = "Please provide a close reason",
  BOOK = "If you have successfully booked with the client please report the total amount of the booking and the referral amount due to BLACE below",
}

function BaseInquiryModal({
  isOpen,
  onClose,
  error,
  inquiryModalType,
  children,
}: PropsWithChildren<BaseInquiryModalProps>) {
  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: onClose,
  });

  const modalTitle = useMemo(() => {
    switch (inquiryModalType) {
      case InquiryModalTypes.REOPEN:
        return InquiryModalTitles.REOPEN;
      case InquiryModalTypes.CLOSE:
        return InquiryModalTitles.CLOSE;
      case InquiryModalTypes.BOOK:
        return InquiryModalTitles.BOOK;
      default:
        return "";
    }
  }, [inquiryModalType]);

  const modalSubTitle = useMemo(() => {
    switch (inquiryModalType) {
      case InquiryModalTypes.REOPEN:
        return InquiryModalSubTitles.REOPEN;
      case InquiryModalTypes.CLOSE:
        return InquiryModalSubTitles.CLOSE;
      case InquiryModalTypes.BOOK:
        return InquiryModalSubTitles.BOOK;
      default:
        return "";
    }
  }, [inquiryModalType]);

  if (isOpen) {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        keepMounted
        className={styles.closeModal}
        TransitionComponent={Transition}
        PaperComponent={(props) => (
          <DraggablePaper
            handleStop={handleDragStop}
            handleDrag={handleDrag}
            dragId={dragId}
            {...props}
          />
        )}
      >
        <div className={styles.dialogWithExitBtnContainer}>
          <div className={styles.dialogWithExitExitBtnInner}>
            <IconButton color="inherit" onClick={onClose} data-testid="close-modal-btn">
              <CloseIcon color="inherit" />
            </IconButton>
          </div>
        </div>
        <Typography className={styles.modalTitle}>{modalTitle}</Typography>
        <Typography className={cn(styles.modalSubtitle, { [styles.error]: error })}>
          {!error ? modalSubTitle : error}
        </Typography>

        {children}
      </Dialog>
    );
  }

  return <></>;
}

export default BaseInquiryModal;
