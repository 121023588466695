import React, { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { TextField } from "@mui/material";
import { FormHelper } from "blace-frontend-library";
import cn from "classnames";
import { useBlocker } from "react-router-dom";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { SaveButton } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/SaveButton";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { useContactForm } from "@/src/hook";
import { FormLogic } from "@/src/model";
import { BlockerArgs, FormRef } from "@/src/type/app";
import styles from "./ContactForm.module.scss";

export enum InputList {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
}

interface ContactFormProps {
  onSaveContactFormData?: () => void;
}

function ContactForm(props: ContactFormProps, ref: React.Ref<FormRef>) {
  const { onSaveContactFormData } = props;

  const {
    setIsSaveButtonDisabled,
    isEditRequestSubmitting,
    isSaveButtonDisabled,
    listingItemData,
    listingItemSaveHandler,
    hasUnsavedData,
    setHasUnsavedData,
  } = useContext(ListingManagementContext) || {};

  const { formik } = useContactForm({
    contactSaveHandler: listingItemSaveHandler,
    contactData: listingItemData?.contacts?.[0],
    listingItemData,
  });

  const isSaveDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      !!hasUnsavedData && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

   // prevent data loss
   useEffect(() => {
    setHasUnsavedData && setHasUnsavedData(formik.dirty);

    if (formik.dirty) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [formik.dirty, setHasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button status
  useEffect(() => {
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isSaveDisabled);
  }, [isSaveDisabled, setIsSaveButtonDisabled]);

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));

  return (
    <div className={styles.contactForm}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <label htmlFor={InputList.FirstName} className={cn(styles.inputLabel, styles.required)}>
            First Name
          </label>
          <TextField
            className={styles.textField}
            fullWidth
            required
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.FirstName]}
            onChange={(e) => {
              formik.setFieldValue(InputList.FirstName, e?.target?.value ?? "");
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.FirstName)}
            error={FormHelper.formikCheckError(formik, InputList.FirstName)}
            id={InputList.FirstName}
          />
        </div>
        <div className={styles.formItem}>
          <label htmlFor={InputList.LastName} className={cn(styles.inputLabel)}>
            Last Name
          </label>
          <TextField
            className={styles.textField}
            fullWidth
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.LastName]}
            onChange={(e) => {
              formik.setFieldValue(InputList.LastName, e?.target?.value ?? "");
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.LastName)}
            error={FormHelper.formikCheckError(formik, InputList.LastName)}
            id={InputList.LastName}
          />
        </div>
        <div className={styles.formItem}>
          <label htmlFor={InputList.Email} className={cn(styles.inputLabel, styles.required)}>
            Email Name
          </label>
          <TextField
            className={styles.textField}
            fullWidth
            required
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.Email]}
            onChange={(e) => {
              formik.setFieldValue(InputList.Email, e?.target?.value ?? "");
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.Email)}
            error={FormHelper.formikCheckError(formik, InputList.Email)}
            id={InputList.Email}
          />
        </div>
      </form>
      <div className={styles.formActionButtons}>
        <SaveButton
          isFullWidth
          isSaveButtonLoading={Boolean(isEditRequestSubmitting)}
          isSaveButtonDisabled={Boolean(isSaveButtonDisabled)}
          onSaveFormData={onSaveContactFormData}
        />
      </div>
    </div>
  );
}

export default forwardRef(ContactForm);
