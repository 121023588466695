import { HttpServiceV2, KEYS, SharedConfigManager } from "blace-frontend-library";
import { BlaceV2Type, GoogleType } from "@/src/type";

export async function getGoogleReverseGeoCode(
  lat: number,
  lng: number
): Promise<BlaceV2Type.BlaceV2HTTPResponse<BlaceV2Type.BlaceV2Response<GoogleType.MapsType.ReverseGeocodedAddress>>> {
  const action = new HttpServiceV2.HTTP(
    `${SharedConfigManager.getValue(KEYS.BLACE_V2_API)}/external/google/reverseGeoCode?lat=${lat}&lng=${lng}`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(SharedConfigManager.getValue(KEYS.APP_TENANT));
  return await action.execute();
}
