import React, { useContext } from "react";
import { ImageFromCDN, Log } from "blace-frontend-library";
import { TwoOptionsPopup } from "@/src/component/base";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";

interface EntryPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

function EntryPopup({ isOpen, onClose }: EntryPopupProps) {
  const { categorySelectionHandler, listingItemSaveHandler } =
    useContext(ListingManagementContext) || {};

  const listingRoomsImageUrl = ImageFromCDN.imageSizeAndQuality(
    "listingRooms.png",
    80,
    300,
    true
  );
  const listingNoRoomsImageUrl = ImageFromCDN.imageSizeAndQuality(
    "listingNoRooms.jpg",
    80,
    300,
    true
  );

  const saveEmptyRooms = async () => {
    const errors = listingItemSaveHandler
      ? await listingItemSaveHandler({ rooms: [] }, false)
      : {};
    if (Object.keys(errors).length) {
      Log.logToDataDog(Log.LogLevel.ERROR, "EntryPopup.tsx", "saveRoomsError", [
        errors,
      ]);
    }
  };

  const handleHasRoomOption = async () => {
    saveEmptyRooms();
    onClose();
  };

  const handleNoRoomOption = () => {
    saveEmptyRooms();
    categorySelectionHandler &&
      categorySelectionHandler(ListingCategories.Tags);
    onClose();
  };

  return (
    <TwoOptionsPopup
      isOpen={isOpen}
      onClose={handleHasRoomOption}
      popupTitle="Does your venues have multiple 
Rooms clients can book?"
      firstOption={{
        img: listingRoomsImageUrl,
        text: "Yes",
        onClick: handleHasRoomOption,
      }}
      secondOption={{
        img: listingNoRoomsImageUrl,
        text: "No",
        onClick: handleNoRoomOption,
      }}
    />
  );
}

export default EntryPopup;
