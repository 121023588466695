import { Dispatch, SetStateAction, useContext } from "react";
import { ActivityLogic, BaseButton } from "blace-frontend-library";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { uniqueId } from "@/src/util";
import styles from "./DashboardMobileManager.module.scss";

interface DashboardMobileManagerProps {
  setIsFAQMobileOpen: Dispatch<SetStateAction<boolean>>;
  onClickHandlerContactSupport: () => void;
}

function DashboardMobileManager({
  setIsFAQMobileOpen,
  onClickHandlerContactSupport,
}: DashboardMobileManagerProps) {
  const dashboardContext = useContext(DashboardContext);

  const onClickHandlerFAQ = () => {
    setIsFAQMobileOpen(true);
    const trackingId = uniqueId();
    ActivityLogic.toActivityService({
      action: "faqButton",
      actionId: trackingId,
      actionIdType: "click",
      actionMessage: trackingId,
      data1Type: "inquiry",
      data1Value: dashboardContext.selectedInquiry?.inquiryId || "",
      locationInApp: "DashboardMobileManager.tsx",
    });
  };

  return (
    <div className={styles.inquiryManagerList}>
      <BaseButton className={styles.linkButton} onClick={onClickHandlerFAQ}>
        FAQ
      </BaseButton>
      <BaseButton
        className={styles.linkButton}
        onClick={onClickHandlerContactSupport}
      >
        Contact Support
      </BaseButton>
    </div>
  );
}

export default DashboardMobileManager;
