import React, { useMemo } from "react";
import { StatusChangePage } from "@/src/component/view/StatusChangePage";
import { OneTimeLinkPages } from "@/src/const";

function OneTimeLinkPage() {
  const actionType = OneTimeLinkPages.INQUIRY_STATUS_CHANGE;
  
  const OneTimeLinkComponent = useMemo(() => {
    switch (actionType) {
      case OneTimeLinkPages.INQUIRY_STATUS_CHANGE:
        return <StatusChangePage />;
      default:
        return false;
    }
  }, [actionType]);

  return <>{OneTimeLinkComponent}</>;
}

export default OneTimeLinkPage;
