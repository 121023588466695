import { useLocation, useParams } from "react-router-dom";
import { SearchType } from "@/src/type/blaceV2";

interface ListingManagerPageInfoResponse {
  searchId?: string;
  searchDataType?: SearchType.SearchDataType;
}

export function useListingManagerPageInfo(): ListingManagerPageInfoResponse {
  const { id } = useParams();

  const regex = /\/listings\/(\w+)(\/|$)/;
  const location = useLocation();

  const match = location.pathname.match(regex);
  const searchDataType = match ? match[1] as SearchType.SearchDataType : undefined;

  return {
    searchId: id,
    searchDataType,
  };
}
