import React from "react";
import { useNavigate } from "react-router-dom";
import { BaseSuccessModalContent } from "@/src/component/base";
import { DASHBOARD_PATH } from "@/src/const";

function SuccessComponent() {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate(DASHBOARD_PATH);
  };

  return (
    <BaseSuccessModalContent
      title="Thank you"
      subtitle="Inquiry status was successfully updated"
      ctaText="Go to dashboard"
      onCtaClick={goToDashboard}
    />
  );
}

export default SuccessComponent;
