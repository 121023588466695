import React, { useState } from "react";
import { Typography } from "@mui/material";
import { CopyButton } from "blace-frontend-library";
import { InquiryTypes } from "@/src/type/blaceV2";
import { SearchType } from "@/src/type/blaceV2/search";
import styles from "./ListingContactCell.module.scss";

interface ListingContactCellProps {
  listing: SearchType.SearchItem;
}

function ListingContactCell({ listing }: ListingContactCellProps) {
  const MAX_CONTACTS_PREVIEW = 3;
  const NO_DATA_TEXT = "---";
  const [isAllContactsShown, setIsAllContactsShown] = useState(false);
  const showButtonText = isAllContactsShown ? "Show less" : "Show more";

  const { dataType, data } = listing;

  const getContactEmails = () => {
    const email =
      dataType === InquiryTypes.Vendor
        ? data?.contact_email
        : data?.primary_contact?.email;
    if (!email) {
      return [];
    }
    return [email];
  };

  const toggleShowMore = () => setIsAllContactsShown(!isAllContactsShown);

  const listingContacts = getContactEmails();

  if (!listingContacts.length) {
    return (
      <Typography className={styles.notificationContact}>
        {NO_DATA_TEXT}
      </Typography>
    );
  }

  return (
    <div className={styles.contactsWrapper}>
      {listingContacts
        ?.slice(
          0,
          isAllContactsShown ? listingContacts.length : MAX_CONTACTS_PREVIEW
        )
        .map((email) => {
          return (
            <Typography key={email} className={styles.notificationContact}>
              {email} <CopyButton textToCopy={email} hasOnlyIcon />
            </Typography>
          );
        })}
      {listingContacts.length > 3 && (
        <Typography onClick={toggleShowMore} className={styles.showMoreBtn}>
          {showButtonText}
        </Typography>
      )}
    </div>
  );
}

export default ListingContactCell;
