import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  BaseButton,
  DraggablePaper,
  Log,
  PriceHelper,
  StringHelper,
  useDragToClose,
} from "blace-frontend-library";
import cn from "classnames";
import { FileUpload } from "@/src/component/base";
import {
  FileUploadErrors,
  UploaderInfo,
} from "@/src/component/base/FileUpload/FileUpload";
import {
  BookingDataInquiry,
  FileData,
} from "@/src/component/view/Dashboard/Dashboard";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { AzureBlobService, B2BInquiryServiceV2 } from "@/src/service";
import { InquiryLoadingProcess } from "@/src/type/blaceV2";
import styles from "./ConfirmBookingModal.module.scss";


interface ConfirmBookingModalProps {
  isOpen: boolean;
  id?: string;
  onClose: () => void;
  handleInquiryToConfirmBooking: (bookingData: BookingDataInquiry) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmBookingModal({
  isOpen,
  id,
  onClose,
  handleInquiryToConfirmBooking,
}: ConfirmBookingModalProps) {
  const dashboardContext = useContext(DashboardContext);
  const [totalBookingValue, setTotalBookingValue] = useState<string>("");
  const [totalBookingFormattedValue, setTotalBookingFormattedValue] =
    useState<string>("");
  const [blaceRefferalValue, setBlaceRefferralValue] = useState<string>("");
  const [blaceRefferalFormattedValue, setBlaceRefferalFormattedValue] =
    useState<string>("");

  const [uploadedPdf, setUploadedPdf] = useState<FileList | null>(null);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [fileDataForBooking, setFileDataForBooking] =
    useState<Array<FileData> | null>(null);
  const [isMaxSizeFileLimited, setIsMaxSizeFileLimited] =
    useState<boolean>(false);

  const [fileName, setFileName] = useState<string>("");
  const [fileSize, setFileSize] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const [fileUploadError, setFileUploadError] = useState<string>("");

  const isBookLoading =
    dashboardContext.loadingProcess === InquiryLoadingProcess.BOOK;

  const inputTotalBookingRef = useRef(null);
  const inputBlaceRefferalRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const limitFileSizeMb = 20;

  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: onClose,
  });

  // TODO: move this method to the `PriceHelper` under blace library
  function inputValueParser(text: string): number {
    const defaultResponse = 0;

    if (!text) {
      return defaultResponse;
    }

    const numberValue = Number(text.replace(/[\$,]/g, "").trim());
    if (Number.isNaN(numberValue)) {
      return defaultResponse;
    }

    return numberValue;
  }

  async function fileUploadHanlder(data: FileList) {
    setUploadedPdf(data);

    if (id) {
      const file = data[0];
      const { name, size, type } = file;
      if (type !== "application/pdf") {
        setFileUploadError(FileUploadErrors.NonPDFFormat);
        return;
      }

      const fileSizeMb = file.size / (1024 * 1024);

      if (fileSizeMb > limitFileSizeMb) {
        setIsMaxSizeFileLimited(true);
        return;
      }

      setIsFileLoading(true);

      try {
        fileUploadError && setFileUploadError("");
        isMaxSizeFileLimited && setIsMaxSizeFileLimited(false);

        const linkFileUploadResponse =
          await B2BInquiryServiceV2.getInvoiceFileUploadLink(id, name);

        //@ts-ignore
        const { fileNameCanonical, link, blobName } =
          linkFileUploadResponse?.body?.payload;
        const putFileToServerResponse =
          await AzureBlobService.putFileToServer(
            link,
            fileNameCanonical,
            file
          );
        //@ts-ignore
        if (putFileToServerResponse.ok) {
          setIsFileLoading(false);
          setFileDataForBooking([{ name, size, contentType: type, blobName }]);
        }
      } catch (e) {
        setFileUploadError(FileUploadErrors.UploadFailed);
        setIsFileLoading(false);
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "ConfirmBookingModal.tsx",
          "uploadBookingFileAttachmentError",
          [e]
        );
      }
    }
  }

  function clearFields() {
    setTotalBookingFormattedValue("");
    setBlaceRefferalFormattedValue("");
    setBlaceRefferralValue("");
    setTotalBookingValue("");
    setUploadedPdf(null);
    setFileName("");
    setFileSize(0);
    setIsMaxSizeFileLimited(false);
    setError(null);
  }

  function handleCloseDialog() {
    clearFields();

    onClose();
  }

  function handleConfirmBookInquiry() {
    const referralValue = inputValueParser(blaceRefferalValue);
    const totalValue = inputValueParser(totalBookingValue);

    const bookingData = {
      referralValue: referralValue,
      uploadedPdfData: fileDataForBooking,
      totalValue,
    };
    handleInquiryToConfirmBooking(bookingData);

    clearFields();
  }

  function onChangeTotalBookingValueHandler(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const value = event.target.value;
    setTotalBookingValue(value);

    const formattedValue = PriceHelper.formatFormInputValue(value);
    setTotalBookingFormattedValue(formattedValue);
  }

  function onChangeBlaceRefferalValueHandler(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const value = event.target.value;
    setBlaceRefferralValue(value);
    const formattedValue = PriceHelper.formatFormInputValue(value);
    setBlaceRefferalFormattedValue(formattedValue);
  }

  const openInNewTabHandler = useCallback(() => {
    const uploadedFile = uploadedPdf && uploadedPdf[0];

    if (uploadedFile) {
      const blobData = new Blob([uploadedFile], { type: uploadedFile.type });
      const blobUrl = URL.createObjectURL(blobData);
      window.open(blobUrl, "_blank");
    }
  }, [uploadedPdf]);

  const removeFileHandler = useCallback(() => {
    if (inputRef?.current) {
      //@ts-ignore
      inputRef.current.value = null;
    }
    setUploadedPdf(null);
    setFileName("");
    setFileSize(0);
    setIsMaxSizeFileLimited(false);
  }, []);

  useEffect(() => {
    if (uploadedPdf) {
      const file = uploadedPdf[0];

      if (file) {
        setFileName(file.name);
        const formattedFileSize = StringHelper.formatFileSize(file.size);
        setFileSize(formattedFileSize);
      }
    }
  }, [uploadedPdf]);

  useEffect(() => {
    //@ts-ignore
    inputTotalBookingRef.current && inputTotalBookingRef.current.focus();
  }, [totalBookingValue]);

  useEffect(() => {
    //@ts-ignore
    inputBlaceRefferalRef.current && inputBlaceRefferalRef.current.focus();
  }, [blaceRefferalValue]);

  const isFieldsWithoutValue = useMemo(() => {
    return (
      !inputValueParser(totalBookingValue) ||
      !inputValueParser(blaceRefferalValue)
    );
  }, [blaceRefferalValue, totalBookingValue]);

  const isError = useMemo(() => {
    if (
      inputValueParser(blaceRefferalValue) &&
      inputValueParser(totalBookingValue)
    ) {
      return (
        inputValueParser(blaceRefferalValue) >=
        inputValueParser(totalBookingValue)
      );
    }
    return false;
  }, [totalBookingValue, blaceRefferalValue]);

  const inputStyles = useMemo(() => {
    return isError ? [styles.textFieldError] : [styles.textField];
  }, [isError]);

  const isButtonSubmitDisabled =
    isFieldsWithoutValue || isError || isMaxSizeFileLimited || isBookLoading;

  if (isOpen) {
    return (
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        keepMounted
        className={styles.confirmBookingModal}
        TransitionComponent={Transition}
        PaperComponent={(props) => (
          <DraggablePaper
            handleStop={handleDragStop}
            handleDrag={handleDrag}
            dragId={dragId}
            {...props}
          />
        )}
      >
        <div className={styles.dialogWithExitBtnContainer}>
          <div className={styles.dialogWithExitExitBtnInner}>
            <IconButton
              color="inherit"
              onClick={handleCloseDialog}
              data-testid="close-modal-btn"
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </div>
        </div>
        <Typography className={styles.modalTitle}>
          Confirm Booking Amounts
        </Typography>
        {isMaxSizeFileLimited && !fileUploadError ? (
          <Typography className={styles.maxSizeFileLimitedError}>
            {FileUploadErrors.MaxSizeLimited}
          </Typography>
        ) : (
          <Typography
            className={cn(styles.modalSubtitle, { [styles.error]: error })}
          >
            {!error
              ? " If you have successfully booked with the client please report the total amount of the booking and the referral amount due to BLACE below"
              : error}
          </Typography>
        )}

        <div className={styles.inputWrapper}>
          <label htmlFor="total-booking" className={styles.textFieldLabel}>
            Total Booking Amount
          </label>
          <input
            value={totalBookingFormattedValue}
            ref={inputTotalBookingRef}
            id="total-booking"
            onChange={onChangeTotalBookingValueHandler}
            className={styles.textField}
            inputMode="numeric"
            required
          />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="blace-refferal" className={styles.textFieldLabel}>
            Blace Refferal Amount
          </label>
          <input
            value={blaceRefferalFormattedValue}
            ref={inputBlaceRefferalRef}
            id="blace-refferal"
            onChange={onChangeBlaceRefferalValueHandler}
            className={cn(inputStyles)}
            inputMode="numeric"
            required
          />
        </div>
        {isError && (
          <Typography className={styles.error}>
            Referral amounts cannot exceed the Total Booking
          </Typography>
        )}
        <FileUpload
          fileType={UploaderInfo.Invoice}
          fileName={fileName}
          fileSize={fileSize}
          isFileLoading={isFileLoading}
          isMaxSizeFileLimited={isMaxSizeFileLimited}
          fileUploadError={fileUploadError}
          setUploadFileData={fileUploadHanlder}
          openInNewTabHandler={openInNewTabHandler}
          removeFileHandler={removeFileHandler}
        />
        <BaseButton
          fullWidth
          loading={isBookLoading}
          disabled={isButtonSubmitDisabled}
          className={styles.confirmBookingButton}
          onClick={handleConfirmBookInquiry}
        >
          Confirm
        </BaseButton>
      </Dialog>
    );
  }

  return <></>;
}

export default ConfirmBookingModal;
