import { Typography } from "@mui/material";
import {
  BaseButton,
  KEYS,
  SharedConfigManager,
} from "blace-frontend-library";
import { useSearchParams } from "react-router-dom";
import { 
  DASHBOARD_FILTER_PARAM, 
  DASHBOARD_FREE_TEXT_SEARCH_PARAM,
  DASHBOARD_PAGE_PARAM,
  DASHBOARD_SEARCH_ID_PARAM 
} from "@/src/component/view/Dashboard/DashboardConstant";
import { uniqueId } from "@/src/util";
import styles from "./SearchFiltersFallback.module.scss";

interface SearchFiltersFallbackProps {
  fallbackType: "error" | "noResults" | "loading";
}

function SearchFiltersFallback({
  fallbackType
}: SearchFiltersFallbackProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const [searchParams, setSearchParams] = useSearchParams();

  function onClearAllHandler() {
    const filterParams: string[] = Object.values(DASHBOARD_FILTER_PARAM);
    for (const filterParam of filterParams) {
      searchParams.set(filterParam,"");
    }
    searchParams.set(DASHBOARD_SEARCH_ID_PARAM,uniqueId());
    searchParams.set(DASHBOARD_FREE_TEXT_SEARCH_PARAM,"");
    searchParams.set(DASHBOARD_PAGE_PARAM,"1");
    setSearchParams(searchParams);
  }
  
  if (fallbackType === "noResults") {
    return (
      <div className={styles.noMatchesWrapper}>
        <img
          src={`${SVG_URL}/searchResult.svg`}
          width="40"
          height="40"
          alt="No matches icon"
        />
        <Typography className={styles.title}>No inquires found</Typography>
        <Typography className={styles.description}>
          Your search and filters did not match any inquires.
        </Typography>
        <BaseButton
          className={styles.clearButton}
          onClick={onClearAllHandler}
        >
          Clear Search and Filters
        </BaseButton>
      </div>
    );
  }

  if (fallbackType === "error") {
    return (
      <div className={styles.errorWrapper}>
        <img
          src={`${SVG_URL}/searchError.svg`}
          width="40"
          height="40"
          alt="Error icon"
          className={styles.errorIcon}
        />
        <Typography className={styles.description}>
          We faced internal server error
        </Typography>
      </div>
    );
  }

  return (
    <></>
  );
}

export default SearchFiltersFallback;
