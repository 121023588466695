import { APIProvider } from "@vis.gl/react-google-maps";
import { KEYS, SharedConfigManager } from "blace-frontend-library";
import { Navigate, Route, Routes } from "react-router-dom";
import { MainLayout } from "@/src/component/layout";
import { ToastProvider, WithAuth } from "@/src/component/provider";
import {
  Dashboard,
  Debug,
  ListingManagement,
  Listings,
} from "@/src/component/view";
/**
 * All paths in this router require the user to be authenticated
 */
const AuthenticatedRouter = () => (
  <WithAuth>
    <ToastProvider>
      <APIProvider apiKey={SharedConfigManager.getValue(KEYS.GOOGLE_MAPS_KEY)}>
        <MainLayout>
          <Routes>
            {/**paths to a direct access a page */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/listings" element={<Listings />} />
            <Route path="/listings/venue" element={<ListingManagement />} />
            <Route path="/listings/venue/:id" element={<ListingManagement />} />
            <Route path="/listings/vendor" element={<ListingManagement />} />
            <Route
              path="/listings/vendor/:id"
              element={<ListingManagement />}
            />
            <Route path="/debug" element={<Debug />} />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </MainLayout>
      </APIProvider>
    </ToastProvider>
  </WithAuth>
);

export default AuthenticatedRouter;
