import { BaseIcon } from "blace-frontend-library";
import { ListingStatus, ListingStatusLabel } from "@/src/type/blaceV2";
import styles from "./StatusIcon.module.scss";

interface StatusIconProps {
  status?: ListingStatus;
}

function StatusIcon({ status }: StatusIconProps) {

  if (!status) {
    return (
      <div className={styles.newStatusLabel}>
        <BaseIcon
          iconFileName="newStatusIcon"
          iconAlt="status icon"
          iconSize={20}
          className={styles.statusIcon}
        />
        New
      </div>
    );
  }

  switch (status) {
    case ListingStatus.DRAFT:
      return <div className={styles.draftStatusLabel}>
        <BaseIcon
          iconFileName="fileIconYellow"
          iconAlt="status icon"
          iconSize={20}
          className={styles.statusIcon}
        />
        {ListingStatusLabel.DRAFT}
      </div>;
    case ListingStatus.PENDING_REVIEW:
      return <div className={styles.pendingStatusLabel}>
        <BaseIcon
          iconFileName="infoBlueIcon"
          iconAlt="status icon"
          iconSize={20}
          className={styles.statusIcon}
        />
        {ListingStatusLabel.PENDING_REVIEW}
      </div>;
    case ListingStatus.PUBLISHED:
      return <div className={styles.publishedStatusLabel}>
        <BaseIcon
          iconFileName="completeIcon"
          iconAlt="status icon"
          iconSize={20}
          className={styles.statusIcon}
        />
        {ListingStatusLabel.PUBLISHED}
      </div>;
    case ListingStatus.UNPUBLISHED:
      return <div className={styles.unpublishedStatusLabel}>
        <BaseIcon
          iconFileName="closeNotCircleGreyIcon"
          iconAlt="status icon"
          iconSize={20}
          className={styles.statusIcon}
        />
        {ListingStatusLabel.UNPUBLISHED}
      </div>;
    default:
      return <></>;
  }
}

export default StatusIcon;
