import React from "react";
import { Typography } from "@mui/material";
import {
  ActivityLogic,
  KEYS,
  SharedConfigManager,
} from "blace-frontend-library";
import { subDays } from "date-fns";
import { useSearchParams } from "react-router-dom";
import {
  DASHBOARD_FILTER_PARAM,
  DASHBOARD_FREE_TEXT_SEARCH_PARAM,
  DASHBOARD_PAGE_PARAM,
  DASHBOARD_SEARCH_ID_PARAM,
} from "@/src/component/view/Dashboard/DashboardConstant";
import { INQUIRY_NEEDS_UPDATE_THRESHOLD_DAYS } from "@/src/const";
import { uniqueId } from "@/src/util";
import styles from "./NeedsUpdateInquiriesCounter.module.scss";

interface NeedsUpdateInquiriesCounterProps {
  counterValue?: number;
}

function NeedsUpdateInquiriesCounter({
  counterValue,
}: NeedsUpdateInquiriesCounterProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const [searchParams, setSearchParams] = useSearchParams();
  const hasLastUpdateDateInURL = !!searchParams.get(
    DASHBOARD_FILTER_PARAM.SHOW_NEEDS_UPDATE_INQUIRIES
  );

  const handleCTAClick = () => {
    hasLastUpdateDateInURL
      ? handleHideNeedsUpdateInquiries()
      : handleShowNeedsUpdateInquiries();
  };

  function handleHideNeedsUpdateInquiries() {
    searchParams.delete(DASHBOARD_FILTER_PARAM.SHOW_NEEDS_UPDATE_INQUIRIES);
   
    const trackingId = uniqueId();
    ActivityLogic.toActivityService({
      action: "hideNeedsUpdate",
      actionId: trackingId,
      actionIdType: "click",
      actionMessage: trackingId,
      locationInApp: "NeedsUpdateInquiriesCounter.tsx",
    });
    
    searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
    setSearchParams(searchParams);
  }

  function handleShowNeedsUpdateInquiries() {
    const needsUpdateInquiriesDate = subDays(
      new Date(),
      INQUIRY_NEEDS_UPDATE_THRESHOLD_DAYS
    );
    const filterParams: string[] = Object.values(DASHBOARD_FILTER_PARAM);
    for (const filterParam of filterParams) {
      searchParams.delete(filterParam);
    }
    searchParams.set(DASHBOARD_PAGE_PARAM, "1");
    searchParams.delete(DASHBOARD_FREE_TEXT_SEARCH_PARAM);
    searchParams.set(
      DASHBOARD_FILTER_PARAM.SHOW_NEEDS_UPDATE_INQUIRIES,
      `${needsUpdateInquiriesDate.getTime()}`
    );
      
    const trackingId = uniqueId();
    ActivityLogic.toActivityService({
      action: "showNeedsUpdate",
      actionId: trackingId,
      actionIdType: "click",
      actionMessage: trackingId,
      locationInApp: "NeedsUpdateInquiriesCounter.tsx",
    });

    searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
    setSearchParams(searchParams);
  }

  return (
    <div className={styles.counterWrapper}>
      <div className={styles.counterInner}>
        <img
          src={`${SVG_URL}/InfoIcon.svg`}
          alt="info icon"
          width="24"
          height="24"
        />
        <Typography className={styles.counterValue}>
          {counterValue ?? 0}
        </Typography>
        <Typography className={styles.counterInfo}>
          inquiry updates needed
          <img
            src={`${SVG_URL}/questionIcon.svg`}
            alt="question icon"
            width="16"
            height="16"
            className={styles.questionIcon}
          />
        </Typography>
      </div>
      <div className={styles.counterCtaContainer}>
        <div className={styles.counterCtaWrapper} onClick={handleCTAClick}>
          <Typography className={styles.counterCta}>
            {hasLastUpdateDateInURL ? "Hide" : "Show"} inquiries
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default NeedsUpdateInquiriesCounter;
