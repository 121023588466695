import { Box, Typography } from "@mui/material";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { BlaceV2Type } from "@/src/type";
import styles from "./SubmittedMessage.module.scss";

interface SubmittedMessageProps {
  message: BlaceV2Type.InquiryLog;
}

function SubmittedMessage({ message }: SubmittedMessageProps) {
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper}>
        <Typography className={styles.contentTitle}>
          Event description
        </Typography>
        <Typography className={styles.contentText}>
          {message?.shortProperties?.notes}
        </Typography>
      </Box>
    </ChatLogMessage>
  );
}

export default SubmittedMessage;
