import { HttpServiceV2 } from "blace-frontend-library";
// eslint-disable-next-line import/no-unresolved
import { HTTPResponse } from "blace-frontend-library/src/service/BlaceV2/HTTP";
import { FileData } from "@/src/component/view/Dashboard/Dashboard";
import { ENV } from "@/src/const";
//TODO: need to determine if there is a better way to use types
import { BlaceV2Type } from "@/src/type";

export async function postB2BInquiryQuery(
  request: BlaceV2Type.AzureSearchQueryType.Request
): Promise<HTTPResponse<BlaceV2Type.InquiryResponseType>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/query`,
    HttpServiceV2.HTTP.methods.POST,
    request
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function putInquiryClosed(
  inquiryId: string,
  closedReason: string,
  fullReason: string
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<any>>> {
  const params = `closedReason=${closedReason}&fullReason=${fullReason}`;
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/${inquiryId}/closed?${params}`,
    HttpServiceV2.HTTP.methods.PUT
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function putInquiryReopen(
  inquiryId: string,
  reopenReason: string,
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<any>>> {
  const params = `reopenReason=${reopenReason}`;
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/${inquiryId}/reopen?${params}`,
    HttpServiceV2.HTTP.methods.PUT
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function putInquiryBooked(
  inquiryId: string,
  totalAmountInCents: number,
  referralAmountInCents: number,
  files: Array<FileData> | null
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<any>>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/${inquiryId}/booked`,
    HttpServiceV2.HTTP.methods.PUT,
    {
      totalAmountInCents,
      referralAmountInCents,
      files
    }
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function putInquiryStillInContact(
  inquiryId: string
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<any>>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/${inquiryId}/touch`,
    HttpServiceV2.HTTP.methods.PUT
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function getInquiryLogs(
  inquiryId: string
): Promise<HTTPResponse<BlaceV2Type.InquiryLogsResponseType>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/logs?inquiryId=${inquiryId}`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function getInquiryLog(
  inquiryLogId: string
): Promise<HTTPResponse<BlaceV2Type.InquiryLogResponseType>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/logs/${inquiryLogId}`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function getInvoiceFileUploadLink(
  inquiryId: string,
  fileName: string
): Promise<HTTPResponse<BlaceV2Type.InquiryLogResponseType>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/${inquiryId}/invoiceUploadLink?fileName=${fileName}`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function getEmailLogAttachment(
  inquiryLogId: string,
  blobName: string,
  download?: boolean
): Promise<HTTPResponse<BlaceV2Type.EmailLogAttachmentResponseType>> {
  const downloadQuery = download ? "?download" : "";
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/inquiry/logs/${inquiryLogId}/attachment/${blobName}/link${downloadQuery}`,
    HttpServiceV2.HTTP.methods.GET
  );
  action.setAllEnvId(ENV.APP_TENANT);
  return await action.execute();
}

export async function updateInquiryStatusByOneTimeLink(
  inquiryId: string,
  hash: string,
  status: string,
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<any>>> {
  const action = new HttpServiceV2.HTTP(
    `${ENV.BLACE_V2_API}/b2b/event/one-time/inquiry/${inquiryId}/${status}`,
    HttpServiceV2.HTTP.methods.PATCH,
    null,
    false
  );
  action.setAllEnvId(ENV.APP_TENANT);
  action.setUseCustomAuthorization(hash);
  return await action.execute();
}
