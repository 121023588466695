import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { AuthLogic, ToastMessage } from "blace-frontend-library";
import cn from "classnames";
import { Footer, Header } from "@/src/component/partial";
import { useToast } from "@/src/component/provider";
import styles from "./MainLayout.module.scss";

function MainLayout({ children }: PropsWithChildren) {
  const [userName, setUserName] = useState<string | undefined>("");
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(false);

  const { message: generalToastMessage, severity } = useToast();

  useEffect(() => {
    if (AuthLogic.hasAuthToken()) {
      const currentUser = AuthLogic.getAuthCurrentUser();
      const name = currentUser?.name;
      const isAdmin = currentUser?.is_admin;

      name && setUserName(name);
      isAdmin && setIsAdmin(isAdmin);
    }
  }, []);

  const toastTextValue = useMemo(() => {
    return userName ? `You are logged in as ${userName}` : "You are logged in as another user";
  }, [userName]);

  return (
    <>
      <Header />
      <div className={styles.bannerSticky}>
        {isAdmin && (
          <ToastMessage severity="warning" className={cn(styles.withoutShadow)}>
            <Typography variant="h5">{toastTextValue}</Typography>
          </ToastMessage>
        )}
        {generalToastMessage && (
          <ToastMessage severity={severity} className={styles.withoutShadow}>
            <Typography variant="h5">{generalToastMessage}</Typography>
          </ToastMessage>
        )}
      </div>
      <main className={styles.mainLayoutBody}>{children}</main>
      <Footer />
    </>
  );
}

export default MainLayout;
