import React from "react";
import { Typography } from "@mui/material";
import { SUPPORTED_IMAGE_FORMATS } from "@/src/const";
import styles from "./BrowseFileInput.module.scss";

interface BrowseFileInputProps {
  isDisabled: boolean;
  handleFileUpload: React.ChangeEventHandler<HTMLInputElement>;
}

const BrowseFileInput = React.forwardRef(function BrowseFileInput(
  { handleFileUpload, isDisabled }: BrowseFileInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <Typography component="label" className={styles.uploadTextButton}>
      Browse{" "}
      <input
        className={styles.hiddenInput}
        multiple
        ref={ref}
        type="file"
        disabled={isDisabled}
        onChange={handleFileUpload}
        accept={SUPPORTED_IMAGE_FORMATS.join(",")}
      />
    </Typography>
  );
});

export default BrowseFileInput;
