import { PropsWithChildren, useEffect } from "react";
import { ENV } from "@/src/const";
import { useAuth } from "@/src/hook";

function WithAuth({
  children
}: PropsWithChildren<{}>) {
  const auth = useAuth();

  /**
   * on every click check if the user is logged in
   * log the current href so that the user can go back to prior back
   */
  useEffect(() => {
    document.addEventListener("click", (event: any) => {
      if (!window.location.pathname.includes("/login") && "logoutButton" !== event.target.id) {
        auth.setLastPathAndQuery(window.location.href);
        if (!auth.identityValid()) {
          auth.redirectToSSO();
        }
      }
    });

    auth.setLastPathAndQuery(window.location.href);
  }, []);

  /**
   * when app started and user not logged in go to login page
   */
  useEffect(() => {
    if (!auth.identityValid()) {
      window.open(ENV.SSO_URL, "_self");
    }
  }, []);

  /**
   * when logged in show all components
   */
  if (auth.identityValid()) {
    return <>{children}</>;
  }

  return <></>;
}

export default WithAuth;
