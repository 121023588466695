import { ChangeEvent, useContext, useState } from "react";
import { TextField } from "@mui/material";
import { BaseButton } from "blace-frontend-library";
import cn from "classnames";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { BaseInquiryModal } from "@/src/component/view/Dashboard/component/RightContainer/components/BaseInquiryModal";
import { InquiryLoadingProcess, InquiryModalTypes } from "@/src/type/blaceV2";
import styles from "./ReopenInquiryModal.module.scss";

interface ReopenInquiryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ReopenInquiryModal({ isOpen, onClose }: ReopenInquiryModalProps) {
  const dashboardContext = useContext(DashboardContext);
  const [reopenReason, setReopenReason] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const isReopenLoading = dashboardContext.loadingProcess === InquiryLoadingProcess.REOPEN;
  const isReopenBtnDisabled = !reopenReason || reopenReason.length > 75 || isReopenLoading;
  const isReopenInputApplyError = reopenReason.length === 0 || reopenReason.length > 75;

  const clearReopenInquiryFields = () => {
    setReopenReason("");
    setError(null);
  };

  const handleReopenReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setReopenReason(event.target.value);
  };

  function handleReopenInquiry() {
    if (typeof dashboardContext?.reopenInquiry !== "function") {
      return;
    }
    clearReopenInquiryFields();
    dashboardContext?.reopenInquiry(reopenReason);
  }

  const handleCloseModal = () => {
    clearReopenInquiryFields();
    onClose();
  };

  return (
    <BaseInquiryModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      error={error}
      inquiryModalType={InquiryModalTypes.REOPEN}
    >
      <TextField
        inputProps={{ maxLength: 200 }}
        helperText={`${reopenReason.length}/75`}
        inputRef={(input) => input && input.focus()}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
        className={cn(styles.reasonField, {
          [styles.error]: isReopenInputApplyError,
        })}
        fullWidth
        placeholder="In a few words explain why you are reopening the inquiry"
        value={reopenReason}
        onChange={handleReopenReasonChange}
      />
      <BaseButton
        fullWidth
        loading={isReopenLoading}
        disabled={isReopenBtnDisabled}
        className={styles.reopenRequestButton}
        onClick={handleReopenInquiry}
      >
        Reopen Inquiry
      </BaseButton>
    </BaseInquiryModal>
  );
}

export default ReopenInquiryModal;
