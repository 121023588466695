import { memo } from "react";
import { DesktopPopper } from "@/src/component/view/Dashboard/component/LeftContainer/component/Filters/DesktopPopper";
import { MobileDialog } from "@/src/component/view/Dashboard/component/LeftContainer/component/Filters/MobileDialog";

interface FilterSortingResponsiveWrapperProps {
  isOpenDropDown: boolean;
  isOpenDialog: boolean;
  isDesktop: boolean;
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  handleMobileDialogClose: () => void;
  handleClosePopperDropDown: () => void;
  children: React.ReactElement;
}

function FilterSortingResponsiveWrapper({
  children,
  isDesktop,
  isOpenDropDown,
  isOpenDialog,
  handleClosePopperDropDown,
  handleListKeyDown,
  handleMobileDialogClose,
}: FilterSortingResponsiveWrapperProps) {
  if (isDesktop) {
    return (
      <DesktopPopper
        isOpenFiltersDropDown={isOpenDropDown}
        handleClosePopperDropDown={handleClosePopperDropDown}
        handleListKeyDown={handleListKeyDown}
      >
        {children}
      </DesktopPopper>
    );
  }

  return (
    <MobileDialog
      isDialogFiltersOpen={isOpenDialog}
      handleDialogClose={handleMobileDialogClose}
    >
      {children}
    </MobileDialog>
  );
}

export default memo(FilterSortingResponsiveWrapper);
