import { BaseLoader } from "blace-frontend-library";
import styles from "./Loading.module.scss";

function Loading() {
  return (
    <BaseLoader size={55} wrapperClassName={styles.loadingSpinnerWrapper} />
  );
}

export default Loading;
