import { useMemo } from "react";
import { DialogContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { KEYS, SharedConfigManager, useBreakPointDown } from "blace-frontend-library";
import styles from "./NoEvents.module.scss";

function NoEvents() {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const isMobile = useBreakPointDown("md");
  const dialogStyles = useMemo(() => {
    return isMobile
      ? {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
          marginLeft: "-20px",
          marginRight: "-20px",
        },
        "& .MuiDialog-paper": {
          maxWidth: "100%",
          width: "100%",
          borderRadius: "15px",
          margin: "0 0 -33px",
        },
      }
      : null;
  }, [isMobile]);

  return (
    <div className={styles.noCreatedIquiriesWrapper}>
      <img
        src={`${SVG_URL}/${isMobile ? "skeletonMobile" : "skeletonDesktop"}.svg`}
        alt="skeleton"
        className={styles.skeletonImage}
      />
      <Dialog open={true} className={styles.noInquiryDialog} sx={dialogStyles} BackdropProps={{invisible: true}}>
        <div className={styles.iconWrapper}>
          <img
            src={`${SVG_URL}/noEventsIcon.svg`}
            alt="no events icon"
            width="40"
            height="40"
          />
        </div>
        <DialogContent>
          <Typography variant="h4" className={styles.noInquiryTitle}>
            You don't have any inquiries at this time
          </Typography>
          <Typography className={styles.noInquiryText}>
            In the meantime, feel free to visit your Profile to view/edit your listings by selecting Profile → Business Details from your navigation menu.  We will notify you once you get an inquiry
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default NoEvents;
