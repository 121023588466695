import { Box, Typography } from "@mui/material";
import { KEYS, SharedConfigManager } from "blace-frontend-library";
import styles from "./Error.module.scss";

function Error() {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  return (
    <Box className={styles.errorWrapper}>
      <img 
        src={`${SVG_URL}/checkRing.svg`}
        alt="Error icon" 
        width="40"
        height="40"
      />
      <Typography className={styles.errorText}>
        We faced internal server error.
      </Typography>
      <Typography className={styles.errorText}>
        Do not worry. We are working on it. Just reload the page and try again.
      </Typography>
    </Box>
  );
}

export default Error;
