//@ts-nocheck
import { EnvType } from "@/src/type";

/**
 * Change this object as needed for local development
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOCAL: EnvType = {
  BLACE_V2_API: "https://core-public-api.blace-stage.com/api",
  APP_API: "https://api.blace-stage.com/api",
  APP_TENANT: "blace",
  ENV: "local",
  DEBUG_MODE: true,
  HOME_URL: "http://localhost:3001/dashboard",
  ROOT_DOMAIN_URL: "https://blace-stage.com",
  CMS_URL: "https://cms.blace-stage.com",
  STRAPI_API_URL: "https://cms.blace-stage.com/api",
  STRAPI_API_TOKEN: "eb0c7ff6f7907a63dd6903f5a2608f1257e5c95102a0d9773dabd93afb6a8d951139d995b3d61129f7f03da854deb8ad6efef21c8f51bb6728bdb4e2459c0a3d1633d0792e6bf6871678f4800a004dfef3dada041d3c5c276d6bb8fa31adf0b86c32e32f836d6516eaf1d1947a8f0b479cdaf24c49ccd7530b784a029ad53c18",
  SSO_URL: "https://blace-stage.com/d/en/auth",
  FRONTEND_CDN_URL: "https://cdn.blace-stage.com",
  ASSET_URL: "https://blace-stage.imgix.net",
  GOOGLE_MAPS_KEY: "AIzaSyANsS324vF_xkIL-r0mKWKQlRE4wIVpWrU",
};

export const DEV: EnvType = {
  BLACE_V2_API: "https://core-public-api.blace-dev.com/api",
  APP_API: "https://api.blace-dev.com/api",
  APP_TENANT: "blace",
  ENV: "dev",
  DEBUG_MODE: true,
  HOME_URL: "https://partner.blace-dev.com/dashboard",
  ROOT_DOMAIN_URL: "https://blace-dev.com",
  CMS_URL: "https://cms.blace-dev.com",
  STRAPI_API_URL: "https://cms.blace-dev.com/api",
  STRAPI_API_TOKEN: "todo",
  SSO_URL: "https://blace-dev.com/d/en/auth",
  FRONTEND_CDN_URL: "https://cdn.blace-dev.com",
  ASSET_URL: "https://blace-dev.imgix.net",
  GOOGLE_MAPS_KEY: "AIzaSyANsS324vF_xkIL-r0mKWKQlRE4wIVpWrU",
};

export const STAGE: EnvType = {
  BLACE_V2_API: "https://core-public-api.blace-stage.com/api",
  APP_API: "https://api.blace-stage.com/api",
  APP_TENANT: "blace",
  ENV: "stage",
  DEBUG_MODE: true,
  HOME_URL: "https://partner.blace-stage.com/dashboard",
  ROOT_DOMAIN_URL: "https://blace-stage.com",
  CMS_URL: "https://cms.blace-stage.com",
  STRAPI_API_URL: "https://cms.blace-stage.com/api",
  STRAPI_API_TOKEN: "eb0c7ff6f7907a63dd6903f5a2608f1257e5c95102a0d9773dabd93afb6a8d951139d995b3d61129f7f03da854deb8ad6efef21c8f51bb6728bdb4e2459c0a3d1633d0792e6bf6871678f4800a004dfef3dada041d3c5c276d6bb8fa31adf0b86c32e32f836d6516eaf1d1947a8f0b479cdaf24c49ccd7530b784a029ad53c18",
  SSO_URL: "https://blace-stage.com/d/en/auth",
  FRONTEND_CDN_URL: "https://cdn.blace-stage.com",
  ASSET_URL: "https://blace-stage.imgix.net",
  GOOGLE_MAPS_KEY: "AIzaSyANsS324vF_xkIL-r0mKWKQlRE4wIVpWrU",
};

export const PROD: EnvType = {
  BLACE_V2_API: "https://core-public-api.blace.com/api",
  APP_API: "https://api.blace.com/api",
  APP_TENANT: "blace",
  ENV: "prod",
  DEBUG_MODE: false,
  HOME_URL: "https://partner.blace.com/dashboard",
  ROOT_DOMAIN_URL: "https://blace.com",
  CMS_URL: "https://cms.blace.com",
  STRAPI_API_URL: "https://cms.blace.com/api",
  STRAPI_API_TOKEN: "3eee0b294e484559182b4fcabc6353439ae278a1ad2f0912553275b47fef9860a6c6f648e62228c4db411145214384bf73a45a49fade4bb217a929d5c1d3781fba91bfe83407fd30a1c414919f1ce573097ff5d38273df04deb3af1e7d90f0f4b5a9c4470e7765cd3c24f48ba2361e03e94132211b9ceb66a39d9592352abc14",
  SSO_URL: "https://blace.com/d/en/auth",
  FRONTEND_CDN_URL: "https://cdn.blace.com",
  ASSET_URL: "https://blace-prod.imgix.net",
  GOOGLE_MAPS_KEY: "AIzaSyANsS324vF_xkIL-r0mKWKQlRE4wIVpWrU",
};

export const ENV: EnvType = !process.env.REACT_APP_ENV
  ? STAGE // "local" - change as needed
  : process.env.REACT_APP_ENV === "prod"
    ? PROD 
    : process.env.REACT_APP_ENV === "stage"
      ? STAGE 
      : DEV;
