import { ChangeEvent, memo, useEffect, useMemo, useState } from "react";
import {
  ActivityLogic,
  BaseButton,
  KEYS,
  SharedConfigManager,
  useBreakPointUp
} from "blace-frontend-library";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  DASHBOARD_FREE_TEXT_SEARCH_PARAM,
  DASHBOARD_PAGE_PARAM,
  DASHBOARD_SEARCH_ID_PARAM,
} from "@/src/component/view/Dashboard/DashboardConstant";
import { uniqueId } from "@/src/util";
import styles from "./InputFilter.module.scss";

interface InputFilterProps {
  appliedFilters?: number;
  onFocusInputHandler: () => void;
  handleDropDownFiltersToggle: () => void;
  handleDropDownSortsToggle: () => void;
}

function initSearchParam(str?: string | null) {
  return !str ? "" : str;
}

const InputFilter = ({
  appliedFilters,
  handleDropDownFiltersToggle,
  handleDropDownSortsToggle,
  onFocusInputHandler,
}: InputFilterProps) => {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const [searchParams, setSearchParams] = useSearchParams();
  const [canReset, setCanReset] = useState<boolean>(false);
  const [value, setValue] = useState<string>(
    initSearchParam(searchParams.get(DASHBOARD_FREE_TEXT_SEARCH_PARAM))
  );
  const isDesktop = useBreakPointUp("md");

  function trackSearchUsed(val: string) {
    if (!val) {
      return;
    }
    ActivityLogic.toActivityService({
      action: "search_bdash",
      actionId: val,
      actionIdType: "searchValue",
      actionMessage: val,
      locationInApp: "InputFilter.tsx",
    });
  }

  /**
   * update the url, this will trigger search after stopping for .5 seconds
   */
  const debouncedCallback = useDebouncedCallback((value: string) => {
    searchParams.set(DASHBOARD_FREE_TEXT_SEARCH_PARAM, value);
    searchParams.set(DASHBOARD_PAGE_PARAM, "1");
    searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
    setSearchParams(searchParams);
    setCanReset(true);
    trackSearchUsed(value);
  }, 500);

  /**
   * save the state to show change instantly, debounce typing
   */
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    setCanReset(false);
    debouncedCallback(newValue);
  };

  /**
   * this allows the search term to reset when the term is change in the url
   * this function requires canReset = true so that when typing the effect doesn't try to reset
   */
  useEffect(() => {
    if (!canReset) {
      return;
    }

    if (searchParams.get(DASHBOARD_FREE_TEXT_SEARCH_PARAM) !== value) {
      setCanReset(false);
      setValue(initSearchParam(searchParams.get(DASHBOARD_FREE_TEXT_SEARCH_PARAM)));
    }
  }, [searchParams, value, canReset]);

  const iconsSize = useMemo(() => {
    return isDesktop ? 16 : 22;
  }, [isDesktop]);

  return (
    <div className={styles.inputWrapper}>
      <label htmlFor="event-search" className={styles.inputFilterLabel}>
        <img
          src={`${SVG_URL}/eventSearch.svg`}
          alt="event search icon"
          width={iconsSize}
          height={iconsSize}
        />
      </label>
      <input
        value={value}
        placeholder="Search"
        className={styles.filtersInput}
        id="event-search"
        onChange={handleInputChange}
        onFocus={onFocusInputHandler}
        data-testid="test-input"
      />
      <div className={styles.buttonWrapper}>
        <BaseButton
          className={styles.filterButton}
          aria-haspopup="true"
          onClick={handleDropDownFiltersToggle}
        >
          <img
            src={`${SVG_URL}/filtersIcon.svg`}
            alt="filter event icon"
            width={iconsSize}
            height={iconsSize}
          />
          {!!appliedFilters && (
          <div
            className={styles.appliedFiltersAmount}
            onClick={handleDropDownFiltersToggle}
          >
            {appliedFilters}
          </div>
          )}
        </BaseButton>
        <BaseButton
          className={styles.sortButton}
          aria-haspopup="true"
          data-testid="sortButton"
          onClick={handleDropDownSortsToggle}
        >
          <img
            src={`${SVG_URL}/sortIcon.svg`}
            alt="sorting event icon"
            width={iconsSize}
            height={iconsSize}
          />
        </BaseButton>
      </div>
    </div>
  );
};

export default memo(InputFilter);
