import { ENV } from "@/src/const";
import styles from "./Footer.module.scss";

function Footer() {
  return (
    <div
      data-testid="footer"
      className={styles.footerIframeWrapper}
    >
      <iframe
        id="footer"
        title="footer"
        src={`${ENV.ROOT_DOMAIN_URL}/w/en/footer?from=partnerApp`}
        style={{
          border: "none",
          padding: "0px",
          margin: "0px",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0
        }}
      />
    </div>
  );
}

export default Footer;
