import { useContext, useState } from "react";
import { Link, Typography } from "@mui/material";
import { BaseButton, BaseIcon } from "blace-frontend-library";
import cn from "classnames";
import { BaseConfirmationModal } from "@/src/component/base";
import { CategoryItem } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { ENV } from "@/src/const";
import { ListingStatus, SearchDataTypes } from "@/src/type/blaceV2";
import styles from "./LeftSidebar.module.scss";
import { CategoriesList } from "./components/CategoriesList";
import { StatusIcon } from "./components/StatusIcon";

interface LeftSidebarProps {
  categoriesList: CategoryItem[];
  canBeDisabled: boolean;
  isPublishButtonDisabled: boolean;
  isStatusChangeLoading: boolean;
  goBackHandler: () => void;
  onPublish: () => Promise<void>;
  onDisable: () => Promise<void>;
}

enum ConfirmLabel {
  PUBLISH = "publish",
  DEACTIVATE = "deactivate",
}

enum ConfirmText {
  PUBLISH = "Are you sure you want to publish this listing?",
  DEACTIVATE = "Are you sure you want to deactivate this listing?",
}

function LeftSidebar({
  categoriesList,
  goBackHandler,
  onPublish,
  onDisable,
  canBeDisabled,
  isStatusChangeLoading,
  isPublishButtonDisabled,
}: LeftSidebarProps) {
  const [confirmModalAction, setConfirmModalAction] = useState<ConfirmLabel | undefined>(undefined);

  const { listingItemData, listingItemMainImageUrl } = useContext(ListingManagementContext) || {};

  const statusChangeButtonLabel = canBeDisabled ? "Deactivate" : "Publish";
  const statusChangeButtonIcon = canBeDisabled ? "trashWhiteIcon" : "publishIcon";

  const handleModalConfirm = async () => {
    if (confirmModalAction === ConfirmLabel.PUBLISH) {
      setConfirmModalAction(undefined);
      await onPublish();
    } else {
      setConfirmModalAction(undefined);
      await onDisable();
    }
  };

  const handleModalClose = () => {
    setConfirmModalAction(undefined);
  };

  const handleStatusChange = () => {
    setConfirmModalAction(canBeDisabled ? ConfirmLabel.DEACTIVATE : ConfirmLabel.PUBLISH);
  };
  
  return (
    <div className={styles.leftSidebar}>
      <BaseButton className={styles.backButton} onClick={goBackHandler}>
        <BaseIcon
          iconFileName="arrowLeftIcon"
          iconAlt="go back event icon"
          iconSize={20}
          className={styles.backButtonIcon}
        />
        Back to my Listings
      </BaseButton>
      <Typography className={styles.listingTitle}>{listingItemData?.title}</Typography>
      {listingItemMainImageUrl && (
        <div className={styles.coverImage}>
          <img src={listingItemMainImageUrl} alt="cover image" />
        </div>
      )}
      <StatusIcon status={(listingItemData?.isActive === undefined || listingItemData?.isActive) ? listingItemData?.status : ListingStatus.UNPUBLISHED} />
      <CategoriesList categoriesList={categoriesList} />
      {listingItemData?.slug && (
        <Link
          href={`${ENV.ROOT_DOMAIN_URL}/d/en/${listingItemData?.dataType || SearchDataTypes.Venue}/${listingItemData?.slug}?p=true`}
          aria-label="Listing preview"
          target="_blank"
        >
          <BaseButton className={styles.priviewButton}>
            <BaseIcon
              iconFileName="previewIcon"
              iconAlt="preview icon"
              iconSize={20}
              className={styles.previewIcon}
            />
            Preview
          </BaseButton>
        </Link>
      )}
      {false && ( // keep this code for now, if we'll need it in future (remove it after Mar'25)
        <BaseButton className={styles.saveDraftButton}>
          <BaseIcon
            iconFileName="saveDraftIcon"
            iconAlt="save as a draft icon"
            iconSize={20}
            className={styles.saveDraftIcon}
          />
          Save as a Draft
        </BaseButton>
      )}
      <BaseButton
        onClick={handleStatusChange}
        className={cn(styles.publishButton, {
          [styles.isDisabled]: isPublishButtonDisabled,
        })}
        loading={isStatusChangeLoading}
        loadingColor="inherit"
        disabled={isPublishButtonDisabled}
      >
        <BaseIcon iconFileName={statusChangeButtonIcon} iconAlt="publish icon" iconSize={20} />
        {statusChangeButtonLabel}
      </BaseButton>
      <BaseConfirmationModal
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        isOpen={!!confirmModalAction}
        confirmationText={
          confirmModalAction === ConfirmLabel.PUBLISH ? ConfirmText.PUBLISH : ConfirmText.DEACTIVATE
        }
      />
    </div>
  );
}

export default LeftSidebar;
