import { useMemo } from "react";
import { EMAIL_VALIDATION_REGEXP, Log } from "blace-frontend-library";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { ListingItemEditableData } from "@/src/component/view/ListingManagement/ListingManagement";
import { InputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/ContactContent/components/ContactForm/ContactForm";
import { FormLogic } from "@/src/model";
import { VendorType, VenueType } from "@/src/type/blaceV1";
import { SearchContactV2 } from "@/src/type/blaceV2/search/SearchType";
import { uniqueId } from "@/src/util";

interface ContactSaveData {
  contacts: Partial<SearchContactV2>[];
}

interface UseContactFormProps {
  contactData?: Partial<SearchContactV2>;
  contactSaveHandler?: (data: ContactSaveData) => Promise<Record<string, string>>;
  listingItemData?: ListingItemEditableData;
}

export interface ContactFormType {
  [InputList.FirstName]: string;
  [InputList.LastName]: string;
  [InputList.Email]: string;
}

export function useContactForm({ contactData, contactSaveHandler, listingItemData }: UseContactFormProps): {
  formik: FormikProps<ContactFormType>;
} {
  // todo: refactor it to SearchLogic / venueLogic / VendorLogic
  let v1FirstName = "";
  let v1LastName = "";
  let v1Email = "";
  if (listingItemData?.dataType === "venue") {
    const primaryContact = (listingItemData?.data as VenueType.VenueItem)?.primary_contact;
    v1FirstName = primaryContact?.first_name ?? v1FirstName;
    v1LastName = primaryContact?.last_name ?? v1LastName;
    v1Email = primaryContact?.email ?? v1Email;
  } else {
    const v1VendorDetails = (listingItemData?.data as VendorType.Vendor);
    v1FirstName = (v1VendorDetails?.contact_name ?? v1FirstName).split(" ")[0].trim();
    v1LastName = (v1VendorDetails?.contact_name ?? v1LastName).replace(v1FirstName, "").trim();
    v1Email = v1VendorDetails?.contact_email ?? v1Email;
  }

  const defaultId = contactData?.id ?? uniqueId();
  const defaultFirstName = (contactData ? contactData?.firstName : v1FirstName) ?? "";
  const defaultLastName = (contactData ? contactData?.lastName : v1LastName) ?? "";
  const defaultEmail = (contactData ? contactData?.email : v1Email) ?? "";

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [InputList.FirstName]: Yup.string()
          .required("First name is required")
          .min(1, "Please enter first name with a character count between 1 and 64")
          .max(64, "Please enter first name with a character count between 1 and 64"),
        [InputList.LastName]: Yup.string()
          .notRequired()
          .min(1, "Please enter first name with a character count between 1 and 64")
          .max(64, "Please enter first name with a character count between 1 and 64"),
        [InputList.Email]: Yup.string()
          .required("Email is required")
          .matches(EMAIL_VALIDATION_REGEXP, "Please enter a valid email")
          .max(255, "Please enter email with a character count up to 255"),
      }),
    [],
  );

  const formik = useFormik<ContactFormType>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      [InputList.FirstName]: defaultFirstName,
      [InputList.LastName]: defaultLastName,
      [InputList.Email]: defaultEmail,
    },
    validationSchema,
    onSubmit: async (values, { setFieldError, setFieldTouched }) => {
      const formValuesData = {
        contacts: [
          {
            firstName: Boolean(values[InputList.FirstName])
              ? values[InputList.FirstName]
              : null,
            lastName: Boolean(values[InputList.LastName]) ? values[InputList.LastName] : null,
            email: Boolean(values[InputList.Email]) ? values[InputList.Email].toLowerCase() : null,
            isPrimary: true,
            isActive: true,
            id: defaultId,
          },
        ],
      };
      // handle backend validation
      const unknownFields: string[] = [];
      const errors = contactSaveHandler ? await contactSaveHandler(formValuesData) : {};

      FormLogic.handleServerErrors(errors, values, setFieldError, unknownFields);

      // handle a case when backend returned non known fields
      if (Boolean(unknownFields.length)) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "useContactForm.tsx",
          "onSubmitError",
          [unknownFields],
          "Unknown fields were returned from the server with errors.",
        );
        setFieldTouched(InputList.FirstName, true, false);
      }
    },
  });

  return {
    formik,
  };
}
