import { ChangeEvent } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import styles from "./CloseReasonRadioGroup.module.scss";

interface CancelReasonRadioGroupProps {
  options: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  value: string | null;
}

function CancelReasonRadioGroup({
  options,
  value,
  onChange,
}: CancelReasonRadioGroupProps) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            className={styles.cancelOption}
            value={option}
            key={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default CancelReasonRadioGroup;
