import { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { AuthLogic, BaseButton, CancelSVG, CheckRingSVG, EmailIconSVG, FormattedDateHelper, InquiryChangedSVG, StorageHelper, StringHelper } from "blace-frontend-library";
import cn from "classnames";
import ConfettiExplosion from "react-confetti-explosion";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { EmailAttachments } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/EmailLogMessage/components/EmailAttachments";
import { BlaceV2Type } from "@/src/type";
import { EmailAttachment } from "@/src/type/blaceV2";
import styles from "./ChatLogMessage.module.scss";

interface ChatLogMessageProps {
  contentWithBorder: boolean;
  hasTruncatedText?: boolean;
  isNeedToShowFull?: boolean;
  isNeedToShowLess?: boolean;
  handleShowFull?: () => void;
  handleShowLess?: () => void;
  inquiryMessage: BlaceV2Type.InquiryLog;
  isFetchingFullInfo?: boolean;
  attachments?: EmailAttachment[];
}

function ChatLogMessage({
  contentWithBorder,
  inquiryMessage,
  hasTruncatedText,
  isNeedToShowFull,
  isNeedToShowLess,
  isFetchingFullInfo,
  handleShowFull,
  handleShowLess,
  children,
  attachments,
}: PropsWithChildren<ChatLogMessageProps>) {
  const [explodeConfetti, setExplodeConfetti] = useState(false);
  const dashboardContext = useContext(DashboardContext);
  const isFromClient = inquiryMessage.sourceType === BlaceV2Type.InquiryLogSourseTypes.Client;
  const isFromCurrentUser =
    inquiryMessage.userId === AuthLogic.getV2AuthUserId();
  const isEmail = inquiryMessage.eventType == BlaceV2Type.InquiryLogEventTypes.Email;
  const isBooked = inquiryMessage.eventType == BlaceV2Type.InquiryLogEventTypes.Booked;

  //flip the direction for the business portal
  const contentClasses = cn(styles.messageContent, {
    [styles.withBorder]: contentWithBorder,
  });
  // TODO : migrate to just `isFromCurrentUser` after BE fix (https://blace.atlassian.net/browse/B20-759)
  const wrapperClasses = cn(styles.messageWrapper, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : !isFromClient,
  });
  const messageClasses = cn(styles.message, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : !isFromClient,
  });
  const innerClasses = cn(styles.inner, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : !isFromClient,
  });
  const messageTimeClasses = cn(styles.messageTime, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : !isFromClient,
  });

  const renderEmailTitle = useMemo(() => {
    return `Email from ${isFromCurrentUser ? "me" : inquiryMessage.shortProperties.author}`;
  }, [inquiryMessage, isFromCurrentUser]);

  useEffect(() => {
    (async ()=>{
      if (isBooked) {
      const key = `wasBookedConfettiExploded.${inquiryMessage.inquiryId}`;
      const wasBookedConfettiExploded = await StorageHelper.StorageHelper().getItem(key);
        if (!wasBookedConfettiExploded) {
          StorageHelper.StorageHelper().setItem(key, true);
          setExplodeConfetti(true);
        }
      }
    })();
  }, [isBooked, inquiryMessage.inquiryId]);

  const handleConfettiComplete = () => {
    setExplodeConfetti(false);
  };

  const handleMessageClick = () => {
    isBooked && setExplodeConfetti(true);
  };

  const renderCurrenTitleIcon = useMemo(() => {
    switch (inquiryMessage.eventType) {
      case BlaceV2Type.InquiryLogEventTypes.Submit:
      case BlaceV2Type.InquiryLogEventTypes.Booked:
      case BlaceV2Type.InquiryLogEventTypes.Reopen:
        return (
          <Box className={cn(styles.titleIcon, styles.submitIconWrapper)}>
            <CheckRingSVG />
          </Box>
        );
      case BlaceV2Type.InquiryLogEventTypes.Closed:
        return <CancelSVG />;
      case BlaceV2Type.InquiryLogEventTypes.Change:
        return <InquiryChangedSVG />;
      case BlaceV2Type.InquiryLogEventTypes.Email:
        return <EmailIconSVG />;
      default:
        return "";
    }
  }, [inquiryMessage]);

  const renderCurrenTitle = useMemo(() => {
    switch (inquiryMessage.eventType) {
      case BlaceV2Type.InquiryLogEventTypes.Submit:
        return BlaceV2Type.InquiryLogTitles.inquirySubmitted;
      case BlaceV2Type.InquiryLogEventTypes.Booked:
          return BlaceV2Type.InquiryLogTitles.inquiryBooked;
      case BlaceV2Type.InquiryLogEventTypes.Closed:
        return BlaceV2Type.InquiryLogTitles.inquiryClosed;
      case BlaceV2Type.InquiryLogEventTypes.Change:
        return BlaceV2Type.InquiryLogTitles.inquiryChanged;
      case BlaceV2Type.InquiryLogEventTypes.Email:
        return renderEmailTitle;
      case BlaceV2Type.InquiryLogEventTypes.Reopen:
        return BlaceV2Type.InquiryLogTitles.inquiryReopened;
      default:
        return "";
    }
  }, [inquiryMessage.eventType, renderEmailTitle]);

  const shortLastUpdateDate = useMemo(() => {
    return FormattedDateHelper.formatTimestampToTime(inquiryMessage.createDate);
  }, [inquiryMessage.createDate]);

  const fullLastUpdateDate = useMemo(() => {
    return FormattedDateHelper.formatGlobalDate(inquiryMessage.createDate);
  }, [inquiryMessage.createDate]);

  const showShortDate = useMemo(() => {
    return (inquiryMessage.createDate ?? 0) > (Date.now() - 24 * 60 * 60 * 100);
  }, [inquiryMessage.createDate]);

  const catalogItemInitials = StringHelper.getInitialLetters(dashboardContext.selectedInquiry?.catalogItem?.title || "");
  const currentUserInitials = StringHelper.getInitialLetters(dashboardContext.currentUser?.name || "");
  const currentUserPhotoUrl = dashboardContext.currentUser?.photo_url;

  // TODO : migrate to just `isFromCurrentUser` after BE fix (https://blace.atlassian.net/browse/B20-759)
  const initials = (isEmail && isFromCurrentUser)
    ? currentUserInitials
    : isFromClient
      ? dashboardContext.clientInitials
      : catalogItemInitials;

  const logProfilePhoto = (isEmail && isFromCurrentUser)
    ? currentUserPhotoUrl
    : isFromClient
      ? undefined
      : dashboardContext.catalogItemImageUrl;

  const logProfilePhotoAlt = ((isEmail && isFromCurrentUser)
    ? dashboardContext.currentUser?.name
    : isFromClient
      ? dashboardContext.selectedInquiry?.clientName
      : dashboardContext.selectedInquiry?.catalogItem?.title) + " photo";

  return (
    <Box className={wrapperClasses} data-testid="chatLog">
      <div className={innerClasses}>
        {!logProfilePhoto && (
          <div className={styles.userInitial}>
            <p className={styles.userInitialText}>
              {initials}
            </p>
          </div>
        )}
        {logProfilePhoto && (
          <img
            src={logProfilePhoto}
            alt={logProfilePhotoAlt}
            width={40}
            height={40}
            className={styles.imageWrapper}
          />
        )}
        <div className={cn(
          styles.messageBox,
          (isEmail && hasTruncatedText) ? styles.messageFullWidth : undefined
        )} onClick={handleMessageClick}
        >
          <Box className={cn(
            messageClasses,
            (isEmail && hasTruncatedText) ? styles.messageFullWidth : undefined
          )}
          >
            <Box className={styles.messageTitle}>
              {renderCurrenTitleIcon}
              {renderCurrenTitle}
            </Box>
            {isEmail && (
              <>
                <Typography className={styles.email}>Email</Typography>
                <Typography className={styles.subject}>
                  {inquiryMessage.shortProperties.subject}
                </Typography>
              </>
            )}
            {explodeConfetti && <div className={styles.confettiWrapper}><ConfettiExplosion onComplete={handleConfettiComplete} force={0.8} zIndex={999}/></div>}
            <Box className={contentClasses}>
              {children}
            </Box>
            {isNeedToShowFull && (
              <BaseButton
                loading={isFetchingFullInfo}
                className={styles.showButton}
                onClick={handleShowFull}
              >
                Show Full
              </BaseButton>
            )}
            {isNeedToShowLess && (
              <BaseButton className={styles.showButton} onClick={handleShowLess}>
                Show Less
              </BaseButton>
            )}
            <EmailAttachments attachments={attachments} inquiryLogId={inquiryMessage.inquiryLogId}/>
          </Box>
          <Typography className={messageTimeClasses}>
            <span className={styles.shortUpdatedDate}>
              {showShortDate ? shortLastUpdateDate : fullLastUpdateDate}
            </span>
            <span className={styles.fullUpdatedDate}>{fullLastUpdateDate}</span>
          </Typography>
        </div>
      </div>
    </Box>
  );
}

export default ChatLogMessage;
