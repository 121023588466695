import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export interface ToastContextType {
  message: string | null;
  severity: string;
  setToastMessage: (
    message: string | null,
    severity?: string,
    time?: number
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const DEFAULT_SEVERITY = "warning";

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<string>(DEFAULT_SEVERITY);
  const [time, setTime] = useState<number | null>(null);

  const setToastMessage = useCallback(
    (message: string | null, severity?: string, time?: number) => {
      setMessage(message);
      setSeverity(severity || DEFAULT_SEVERITY);
      setTime(time !== undefined ? time : null);
    },
    []
  );

  useEffect(() => {
    if (message && time !== null) {
      const timer = setTimeout(() => {
        setMessage(null);
        setSeverity(DEFAULT_SEVERITY);
        setTime(null);
      }, time);

      return () => clearTimeout(timer);
    }
  }, [message, time]);

  return (
    <ToastContext.Provider value={{ message, setToastMessage, severity }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
