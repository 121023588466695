import { useCallback, useMemo, useRef, useState } from "react";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { FormRef } from "@/src/type/app";
import { DetailsForm } from "./components/DetailsForm";
import { MapLocationMarker } from "./components/DetailsForm/DetailsForm";
import { DetailsMap } from "./components/DetailsMap";

interface DetailsProps {}

function DetailsContent({}: DetailsProps) {
  const DEFAULT_LOCATION = useMemo(() => {
    return {
      NY: {
        lat: 40.748817,
        lng: -73.985428,
      },
      LA: {
        lat: 34.058605,
        lng: -118.413124,
      },
    };
  }, []);

  const [addressCoordinates, setAddressCoordinates] = useState(DEFAULT_LOCATION.LA);
  const formRef = useRef<FormRef>(null);

  const onAddressChange = useCallback(
    (newAddressCoordinates: MapLocationMarker | null) => {
      setAddressCoordinates(newAddressCoordinates ?? DEFAULT_LOCATION.LA);
    },
    [setAddressCoordinates, DEFAULT_LOCATION.LA],
  );

  const onSaveDetailsFormData = () => {
    if (formRef.current) {
      //@ts-ignore
      formRef.current.submitForm();
    }
  };

  return (
    <CategoryContentWrapper
      category={ListingCategories.Details}
      onSaveFormData={onSaveDetailsFormData}
    >
      <DetailsForm
        ref={formRef}
        onAddressChange={onAddressChange}
        onSaveDetailsFormData={onSaveDetailsFormData}
      />
      <DetailsMap centerMarker={addressCoordinates} />
    </CategoryContentWrapper>
  );
}

export default DetailsContent;
