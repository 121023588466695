import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { AuthLogic, DragDialog, Log, useBreakPointDown } from "blace-frontend-library";
import cn from "classnames";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { B2BInquiryServiceV2 } from "@/src/service/BlaceV2";
import { BlaceV2Type } from "@/src/type";
import styles from "./EmailLogMessage.module.scss";
import { EmailAttachments } from "./components/EmailAttachments";
import { IframeHTMLContent } from "./components/IframeHTMLContent";

interface EmailLogMessageProps {
  message: BlaceV2Type.InquiryLog;
}

enum FullMessageType {
  HTML = "html",
  TEXT = "text",
}

type FullMessage = {
  type: FullMessageType;
  content: string;
};

function EmailLogMessage({ message }: EmailLogMessageProps) {
  const [isNeedToShowFull, setIsNeedToShowFull] = useState(false);
  const [isNeedToShowLess, setIsNeedToShowLess] = useState(false);
  const [fullBodyMessage, setFullBodyMessage] = useState<null | FullMessage>(
    null
  );
  const [isFetching, setIsFetching] = useState(false);
  const [isShortBodyTextTruncated, setIsShortBodyTextTruncated] =
    useState(false);
  const isMobile = useBreakPointDown("md");
  const maxLimitSymbolsForTruncate = 100;
  const isFromCurrentUser =
    message.userId === AuthLogic.getV2AuthUserId();
  const isMobileFullMessage = isMobile && fullBodyMessage;
  const isDesktopFullMessage = !isMobile && fullBodyMessage;
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [iframeHeight, setIframeHeight] = useState("0px");

  const handleIframeLoad = () => {
    const iframeBody = ref?.current?.contentWindow?.document?.body;
    if (iframeBody) {
      iframeBody.style.whiteSpace =
        fullBodyMessage?.type === FullMessageType.TEXT ? "pre-line" : "unset";
      setIframeHeight(iframeBody.getBoundingClientRect().height + 20 + "px");
      if (!isMobile) {
        iframeBody.style.overflowX = "auto";
      }
    }
  };

  const handleShowFull = async () => {
    try {
      setIsFetching(true);
      const responseData = await B2BInquiryServiceV2.getInquiryLog(
        message.inquiryLogId
      );
      if (responseData.body?.metadata?.statusCode === 200) {
        const { fullProperties } = responseData.body.payload;
        const fullMessage =
          fullProperties?.bodyHtml || fullProperties?.bodyText;
        const fullMessageType = fullProperties.bodyHtml
          ? FullMessageType.HTML
          : FullMessageType.TEXT;
        fullMessage &&
          setFullBodyMessage({ type: fullMessageType, content: fullMessage });
        setIsNeedToShowLess(true);
        setIsNeedToShowFull(false);
      } else {
        logToDataDog(responseData);
      }
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      logToDataDog(error);
    }
  };

  function logToDataDog(error: any) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "EmailLogMessage.tsx",
      "EmailLogError",
      [error]
    );
  }

  function handleShowLess() {
    setIsNeedToShowLess(false);
    setIsNeedToShowFull(true);
    setFullBodyMessage(null);
  }

  useEffect(() => {
    if (
      message.shortProperties.introBody &&
      message.shortProperties.introBody.length > maxLimitSymbolsForTruncate
    ) {
      setIsShortBodyTextTruncated(true);
      setIsNeedToShowFull(true);
      //setIsNeedToShowLess(true);
      //handleShowFull();
    }
  }, [message]);

  const shortBodyText = useMemo(() => {
    return message.shortProperties.introBody
      ? message.shortProperties.introBody
      : "";
  }, [message]);

  const bodyTextClasses = useMemo(() => {
    return cn(styles.shortEmailText, {
      [styles.truncated]: isShortBodyTextTruncated,
    });
  }, [isShortBodyTextTruncated]);

  return (
    <ChatLogMessage
      inquiryMessage={message}
      contentWithBorder
      hasTruncatedText={isShortBodyTextTruncated}
      isNeedToShowLess={isNeedToShowLess}
      isNeedToShowFull={isNeedToShowFull}
      isFetchingFullInfo={isFetching}
      handleShowFull={handleShowFull}
      handleShowLess={handleShowLess}
      attachments={message.shortProperties.attachments}
    >
      <Box className={styles.contentWrapper}>
        {isDesktopFullMessage ? (
          <IframeHTMLContent
              onLoad={handleIframeLoad}
              srcDoc={fullBodyMessage.content}
              ref={ref}
              height={iframeHeight}
              style={{ maxHeight: "100%" }}
          />
        ) : (
          <Typography className={bodyTextClasses}>{shortBodyText}</Typography>
        )}
      </Box>
      {isMobileFullMessage && (
        <DragDialog
          onClose={handleShowLess}
          isOpen={!!fullBodyMessage}
          className={styles.dragDialog}
        >
          <Typography className={styles.messageTitle}>
            from {isFromCurrentUser ? "me" : message.shortProperties.author}
          </Typography>
          <Typography className={styles.messageSubtitle}>
            {message.shortProperties.subject}
          </Typography>
          <Divider />
          <Box className={styles.messageContentWrapper}>
            <Box className={styles.messageContent}>
              <IframeHTMLContent
                onLoad={handleIframeLoad}
                srcDoc={fullBodyMessage.content}
                ref={ref}
                height={iframeHeight}
                style={{ maxHeight: "100%" }}
              />
            </Box>
            <Box className={styles.attachments}>
              <EmailAttachments
                attachments={message.shortProperties.attachments}
                inquiryLogId={message.inquiryLogId}
              />
            </Box>
          </Box>
        </DragDialog>
      )}
    </ChatLogMessage>
  );
}

export default EmailLogMessage;
