import React from "react";
import { Loading } from "./Loading";
import { NoListings } from "./NoListings";

interface ChatTabFallbackProps {
  isLoading: boolean;
  haveNoListings: boolean;
  isError: boolean;
}

function ManageListingsFallback({
  isLoading,
  haveNoListings,
  isError,
}: ChatTabFallbackProps) {
  if (isLoading) {
    return <Loading />;
  }

  if (haveNoListings || isError) {
    return <NoListings />;
  }

  return <></>;
}

export default ManageListingsFallback;
