import styles from "@/src/component/partial/Header/Header.module.scss";
import { Account } from "./Account";

export function NavigationDrawer() {
  return (
    <div className={styles.drawerWrapper}>
      <Account />
    </div>
  );
}
