import { BlaceV2Type } from "@/src/type";
import { MapsType } from "@/src/type/google";

export const googleAddressToSearchLocation = (
  geocodedAddress: google.maps.GeocoderResult,
  reversedGeoInfo?: MapsType.ReverseGeocodedAddress,
): BlaceV2Type.SearchType.SearchLocation | null => {
  if (!geocodedAddress) {
    return null;
  }

  const convertedLocation = {
    latitude: geocodedAddress.geometry.location.lat(),
    longitude: geocodedAddress.geometry.location.lng(),
    formattedAddress: geocodedAddress.formatted_address,
    // county: "string", // ???
  } as BlaceV2Type.SearchType.SearchLocation;

  geocodedAddress.address_components.forEach((component) => {
    if (component.types.includes(MapsType.GeocodedAddressComponentType.POSTAL_CODE)) {
      convertedLocation.postalCode = component.long_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.STREET_NUMBER)) {
      convertedLocation.streetNumber = component.long_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.ROUTE)) {
      convertedLocation.street = component.long_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.NEIGHBORHOOD)) {
      convertedLocation.neighborhood = component.long_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.SUBLOCALITY)) {
      convertedLocation.district = component.long_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.LOCALITY)) {
      convertedLocation.city = component.long_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1)) {
      convertedLocation.state = component.short_name;
    }
    if (component.types.includes(MapsType.GeocodedAddressComponentType.COUNTRY)) {
      convertedLocation.country = component.short_name;
    }
  });

  if (!convertedLocation.neighborhood && reversedGeoInfo?.results) {
    const extractedNeighborhood = extractNeighborhood(reversedGeoInfo);
    if (extractedNeighborhood) {
      convertedLocation.neighborhood = extractedNeighborhood;
    }
  }

  return convertedLocation;
};

const extractNeighborhood = (
    reversedGeoInfo: MapsType.ReverseGeocodedAddress,
): string | null => {
  let extractedNeighborhood: string | null = null;

  reversedGeoInfo.results?.some((geoResult: MapsType.GeocodedAddress) => {
    if (extractedNeighborhood) {
      return true;
    }

    geoResult.address_components?.some((component: MapsType.GeocodedAddressComponent) => {
      if (component.types.includes(MapsType.GeocodedAddressComponentType.NEIGHBORHOOD)) {
        extractedNeighborhood = component.long_name;

        return true;
      }
    });
  });

  return extractedNeighborhood;
};
