import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useBreakPointUp } from "blace-frontend-library";
import cn from "classnames";
import { useSearchParams } from "react-router-dom";
import { DashboardSortParams } from "@/src/component/view/Dashboard/Dashboard";
import { DASHBOARD_SEARCH_ID_PARAM, DASHBOARD_SORT_PARAM } from "@/src/component/view/Dashboard/DashboardConstant";
import { MODAL_AUTO_CLOSE_TIMEOUT } from "@/src/const";
import { uniqueId } from "@/src/util";
import styles from "./SortingList.module.scss";

interface SortingListProps {
  handleClosePopperDropDown: () => void;
  handleMobileDialogClose: () => void;
}

function SortingList({ handleClosePopperDropDown, handleMobileDialogClose }: SortingListProps) {
  const [selectedOptionKey, setSelectedOptionKey] = useState("last_update");
  const [searchParams, setSearchParams] = useSearchParams();
  const isDesktop = useBreakPointUp("md");

  const sortingOptions = [
    { option: "Last update", key: "last_update"},
    { option: "Submission date", key: "submission_date"},
  ];

  const handleSortingOptionClick = (key: string) => {
    searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
    searchParams.set(DASHBOARD_SORT_PARAM, key);
    setSearchParams(searchParams);
    setSelectedOptionKey(key);
    setTimeout(() => {
      if (isDesktop) {
        handleClosePopperDropDown();
      } else {
        handleMobileDialogClose();
      }
    }, MODAL_AUTO_CLOSE_TIMEOUT);
  };

  const getListItemClasses = (key: string) => {
    return key === selectedOptionKey
      ? [styles.sortingListItem, styles.active]
      : [styles.sortingListItem];
  };

  useEffect(() => {
    const option = searchParams.get(DASHBOARD_SORT_PARAM);

    if (option === DashboardSortParams.LastUpdate || !option) {
      setSelectedOptionKey(DashboardSortParams.LastUpdate);
      return;
    }

    setSelectedOptionKey(DashboardSortParams.SubmissionDate);

  }, [searchParams]);

  return (
    <div className={styles.sortingWrapper}>
      <Typography variant="h4" className={styles.sortingTitle}>
        Sort By
      </Typography>
      <ul className={styles.sortingOptionsList}>
        {sortingOptions.map(({ option, key }) => (
          <li
            key={key}
            onClick={() => handleSortingOptionClick(key)}
            className={cn(getListItemClasses(key))}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SortingList;
