import React from "react";
import { TagSection } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/TagsContent/components/TagSection";
import { ListingTagSectionType } from "@/src/type/app";
import { SearchAttribute } from "@/src/type/blaceV2/search/SearchType";
import styles from "./TagsSectionList.module.scss";

interface TagsSectionListProps {
  sections: ListingTagSectionType[];
  selectedTags: SearchAttribute[];
  setSelectedTags: React.Dispatch<React.SetStateAction<SearchAttribute[]>>;
}

const TagsSectionList = ({ sections, selectedTags, setSelectedTags }: TagsSectionListProps) => {
  return (
    <div className={styles.sectionsList}>
      {sections.map((section) => (
        <TagSection
          key={section.title}
          sectionTitle={section.title}
          sectionDataType={section.dataKey}
          tags={section.options}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      ))}
    </div>
  );
};

export default TagsSectionList;
