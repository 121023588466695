import React from "react";
import cn from "classnames";
import styles from "./TextareaDescription.module.scss";

interface TextareaDescriptionProps {
  value: string;
  isError: boolean;
  maxChars?: number;
  minChars?: number;
}

const minDetailsDescriptionInputSymbolsAmount = 50;
const maxDetailsDescriptionInputSymbolsAmount = 3000;

function TextareaDescription({
  value,
  isError,
  maxChars = maxDetailsDescriptionInputSymbolsAmount,
  minChars = minDetailsDescriptionInputSymbolsAmount,
}: TextareaDescriptionProps) {
  if (value?.length === 0) {
    return <></>;
  }

  return (
    <div className={cn(styles.descriptionInfo, isError ? styles.isError : styles.isValid)}>
      {`${value?.length}/${value?.length < minChars ? minChars : maxChars}`}
    </div>
  );
}

export default TextareaDescription;
