import React from "react";
import { useNavigate } from "react-router-dom";
import { BaseErrorModalContent } from "@/src/component/base";
import { DASHBOARD_PATH } from "@/src/const";

interface ErrorComponentProps {
  error: string;
}

function ErrorComponent({ error }: ErrorComponentProps) {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate(DASHBOARD_PATH);
  };

  return (
    <BaseErrorModalContent
      title="Oops"
      subtitle={error}
      ctaText="Go to dashboard"
      onCtaClick={goToDashboard}
    />
  );
}

export default ErrorComponent;
