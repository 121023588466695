import React, { useEffect, useState } from "react";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import {
  KEYS,
  SharedConfigManager,
  useBreakPointDown,
} from "blace-frontend-library";
import { MapLocationMarker } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/DetailsForm/DetailsForm";
import styles from "./DetailsMap.module.scss";

interface Props {
  centerMarker?: MapLocationMarker;
}

function DetailsMap({ centerMarker }: Props) {
  const ASSET_URL: string = SharedConfigManager.getValue(KEYS.ASSET_URL);
  const DEFAULT_ZOOM: number = 13;
  const MOBILE_ZOOM: number = 11;

  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [zoomLoaded, setZoomLoaded] = useState<boolean>(false);

  const isMobile = useBreakPointDown("md");

  useEffect(() => {
    if (zoomLoaded) {
      return;
    }

    if (isMobile) {
      setZoom(MOBILE_ZOOM);
      setZoomLoaded(true);
    }
  }, [isMobile, zoomLoaded]);

  if (!centerMarker) {
    return <></>;
  }

  return (
    <div className={styles.detailsMapWrapper} data-testid="details-map">
      <Map
        defaultCenter={centerMarker}
        defaultZoom={zoom}
        mapId="LISTING_MAP"
        className={styles.detailsMap}
        style={{ width: "100%", height: "100%" }}
        gestureHandling={"none"}
        disableDefaultUI
        zoomControl
        scrollwheel={false}
        streetViewControl={false}
        center={centerMarker}
      >
        <AdvancedMarker position={centerMarker}>
          <img
            src={`${ASSET_URL}/blacePinLogo.png?auto=format&w=40&q=100`}
            alt="map marker"
          />
        </AdvancedMarker>
      </Map>
    </div>
  );
}

export default DetailsMap;
