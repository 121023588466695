import { useContext } from "react";
import { Typography } from "@mui/material";
import {
  BaseButton,
  KEYS,
  SharedConfigManager,
  useBreakPointDown,
} from "blace-frontend-library";
import cn from "classnames";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { InquiryLoadingProcess } from "@/src/type/blaceV2";
import styles from "./UpdateInquiryBanner.module.scss";

interface UpdateInquiryBannerProps {
  onClose: () => void;
  onBook: () => void;
  onStillInContact: () => void;
}

function UpdateInquiryBanner({
  onClose,
  onBook,
  onStillInContact,
}: UpdateInquiryBannerProps) {
  const dashboardContext = useContext(DashboardContext);
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const isMobile = useBreakPointDown("md");
  const contactCtaText = isMobile ? "In-contact" : "Still in-contact";
  const bookCtaText = isMobile ? "Book" : "Confirm booking";
  const closeCtaText = isMobile ? "Close" : "Close inquiry";
  const isStillInContactLoading =
    dashboardContext.loadingProcess === InquiryLoadingProcess.STILL_IN_CONTACT;

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerTitleWrapper}>
        <img
          src={`${SVG_URL}/InfoIcon.svg`}
          alt="warning info icon"
          width="24"
          height="24"
        />
        <div>
          <Typography className={styles.bannerTitle}>
            This inquiry needs an update!
          </Typography>
          <Typography className={styles.bannerReason}>
            To ensure all open inquiries are current, please update the status
            of this inquiry by choosing an option below
          </Typography>
          <div className={styles.ctaWrapper}>
            <BaseButton
              loading={isStillInContactLoading}
              disabled={isStillInContactLoading}
              className={cn(styles.bannerCTA, styles.contact)}
              onClick={onStillInContact}
            >
              {!isStillInContactLoading && (
                <img
                  src={`${SVG_URL}/stillInContactIcon.svg`}
                  height="20"
                  width="20"
                  alt="still in contact icon"
                />
              )}
              {contactCtaText}
            </BaseButton>
            <BaseButton
              disabled={isStillInContactLoading}
              className={cn(styles.bannerCTA, styles.book)}
              onClick={onBook}
            >
              <img
                src={`${SVG_URL}/bookIcon.svg`}
                height="20"
                width="20"
                alt="book icon"
              />
              {bookCtaText}
            </BaseButton>
            <BaseButton
              disabled={isStillInContactLoading}
              className={cn(styles.bannerCTA, styles.close)}
              onClick={onClose}
            >
              <img
                src={`${SVG_URL}/closeIcon.svg`}
                height="20"
                width="20"
                alt="close icon"
              />
              {closeCtaText}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateInquiryBanner;
