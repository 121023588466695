import React from "react";

function BlacePartnersLogoSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="10"
      viewBox="0 0 60 10"
      fill="none"
    >
      <path
        d="M6.14157 3.17013C6.14157 3.59413 6.04157 3.99413 5.84157 4.37013C5.64157 4.74613 5.32157 5.05413 4.88157 5.29413C4.44157 5.52613 3.87757 5.64213 3.18957 5.64213H1.67757V9.02613H0.30957V0.686125H3.18957C3.82957 0.686125 4.36957 0.798125 4.80957 1.02213C5.25757 1.23813 5.58957 1.53413 5.80557 1.91013C6.02957 2.28613 6.14157 2.70613 6.14157 3.17013ZM3.18957 4.52613C3.70957 4.52613 4.09757 4.41013 4.35357 4.17813C4.60957 3.93813 4.73757 3.60213 4.73757 3.17013C4.73757 2.25813 4.22157 1.80212 3.18957 1.80212H1.67757V4.52613H3.18957Z"
        fill="white"
      />
      <path
        d="M12.7143 7.32213H9.22229L8.62229 9.02613H7.19429L10.1823 0.674125H11.7663L14.7543 9.02613H13.3143L12.7143 7.32213ZM12.3303 6.20613L10.9743 2.33013L9.60629 6.20613H12.3303Z"
        fill="white"
      />
      <path
        d="M20.6255 9.02613L18.7055 5.69013H17.6615V9.02613H16.2935V0.686125H19.1735C19.8135 0.686125 20.3535 0.798125 20.7935 1.02213C21.2415 1.24612 21.5735 1.54613 21.7895 1.92213C22.0135 2.29813 22.1255 2.71813 22.1255 3.18213C22.1255 3.72613 21.9655 4.22213 21.6455 4.67013C21.3335 5.11013 20.8495 5.41013 20.1935 5.57013L22.2575 9.02613H20.6255ZM17.6615 4.59813H19.1735C19.6855 4.59813 20.0695 4.47013 20.3255 4.21413C20.5895 3.95813 20.7215 3.61413 20.7215 3.18213C20.7215 2.75013 20.5935 2.41413 20.3375 2.17413C20.0815 1.92613 19.6935 1.80212 19.1735 1.80212H17.6615V4.59813Z"
        fill="white"
      />
      <path
        d="M29.5035 0.686125V1.80212H27.2835V9.02613H25.9155V1.80212H23.6835V0.686125H29.5035Z"
        fill="white"
      />
      <path
        d="M37.9809 9.02613H36.6129L32.4969 2.79813V9.02613H31.1289V0.674125H32.4969L36.6129 6.89013V0.674125H37.9809V9.02613Z"
        fill="white"
      />
      <path
        d="M41.3854 1.79013V4.23813H44.2654V5.35413H41.3854V7.91013H44.6254V9.02613H40.0174V0.674125H44.6254V1.79013H41.3854Z"
        fill="white"
      />
      <path
        d="M50.8823 9.02613L48.9623 5.69013H47.9183V9.02613H46.5503V0.686125H49.4303C50.0703 0.686125 50.6103 0.798125 51.0503 1.02213C51.4983 1.24612 51.8303 1.54613 52.0463 1.92213C52.2703 2.29813 52.3823 2.71813 52.3823 3.18213C52.3823 3.72613 52.2223 4.22213 51.9023 4.67013C51.5903 5.11013 51.1063 5.41013 50.4503 5.57013L52.5143 9.02613H50.8823ZM47.9183 4.59813H49.4303C49.9423 4.59813 50.3263 4.47013 50.5823 4.21413C50.8463 3.95813 50.9783 3.61413 50.9783 3.18213C50.9783 2.75013 50.8503 2.41413 50.5943 2.17413C50.3383 1.92613 49.9503 1.80212 49.4303 1.80212H47.9183V4.59813Z"
        fill="white"
      />
      <path
        d="M57.1204 9.11013C56.5604 9.11013 56.0564 9.01413 55.6084 8.82213C55.1604 8.62213 54.8084 8.34213 54.5524 7.98213C54.2964 7.62213 54.1684 7.20213 54.1684 6.72213H55.6324C55.6644 7.08213 55.8044 7.37813 56.0524 7.61013C56.3084 7.84213 56.6644 7.95813 57.1204 7.95813C57.5924 7.95813 57.9604 7.84613 58.2244 7.62213C58.4884 7.39013 58.6204 7.09413 58.6204 6.73413C58.6204 6.45413 58.5364 6.22613 58.3684 6.05013C58.2084 5.87413 58.0044 5.73813 57.7564 5.64213C57.5164 5.54613 57.1804 5.44213 56.7484 5.33013C56.2044 5.18613 55.7604 5.04213 55.4164 4.89813C55.0804 4.74613 54.7924 4.51413 54.5524 4.20213C54.3124 3.89013 54.1924 3.47413 54.1924 2.95413C54.1924 2.47413 54.3124 2.05413 54.5524 1.69413C54.7924 1.33413 55.1284 1.05813 55.5604 0.866126C55.9924 0.674125 56.4924 0.578125 57.0604 0.578125C57.8684 0.578125 58.5284 0.782125 59.0404 1.19012C59.5604 1.59013 59.8484 2.14213 59.9044 2.84613H58.3924C58.3684 2.54213 58.2244 2.28213 57.9604 2.06613C57.6964 1.85013 57.3484 1.74213 56.9164 1.74213C56.5244 1.74213 56.2044 1.84213 55.9564 2.04213C55.7084 2.24213 55.5844 2.53013 55.5844 2.90613C55.5844 3.16213 55.6604 3.37413 55.8124 3.54213C55.9724 3.70213 56.1724 3.83013 56.4124 3.92613C56.6524 4.02213 56.9804 4.12613 57.3964 4.23813C57.9484 4.39013 58.3964 4.54213 58.7404 4.69413C59.0924 4.84613 59.3884 5.08213 59.6284 5.40213C59.8764 5.71413 60.0004 6.13413 60.0004 6.66213C60.0004 7.08613 59.8844 7.48613 59.6524 7.86213C59.4284 8.23813 59.0964 8.54213 58.6564 8.77413C58.2244 8.99813 57.7124 9.11013 57.1204 9.11013Z"
        fill="white"
      />
    </svg>
  );
}

export default BlacePartnersLogoSVG;
