import { AuthLogic } from "blace-frontend-library";
import queryString from "query-string";
import {
  ENV,
  STORAGE_LAST_PATH_AND_QUERY
} from "@/src/const";

type AuthType = {
  identityValid: () => boolean;
  logout: () => Promise<void>;
  setLastPathAndQuery: (path: string) => void;
  redirectToSSO: () => void;
  processAuthToken: (
    callback?: ()=>void
  ) => Promise<void>;
};

export function useAuth(): AuthType {

  const AUTH_PATH_PART: string = "/auth";

  function getLastPathAndQuery() {
    const val: string | null = localStorage.getItem(STORAGE_LAST_PATH_AND_QUERY);

    if (!val || val.includes(AUTH_PATH_PART)) {
      return null;
    }

    return val;
  }

  function setLastPathAndQuery(val: string) {
    if (!val.includes(AUTH_PATH_PART) && val !== getLastPathAndQuery()) {
      localStorage.setItem(STORAGE_LAST_PATH_AND_QUERY, val);
    }
  }

  async function processAuthToken(callback?: ()=>void) {
    const { hash } = window.location;
    const parsed: any = queryString.parse(hash);
    const { token } = parsed;

    await AuthLogic.storeAuthToken(token, undefined);
    if (typeof callback === "function") {
      callback();
    }

    //redirect to previous url or home page
    const last = getLastPathAndQuery();
    const redirect: string = typeof last === "string" ? last : ENV.HOME_URL;
    window.open(redirect, "_self");
  }

  /**
   * Redirect to the sso url
   */
  function redirectToSSO() {
    window.open(ENV.SSO_URL, "_self");
  }

  /**
   * Logout the user by clearing all cookies / storage
   * This function will auto redirect the user to the SSO window
   */
  async function logout() {
    await AuthLogic.logOutCurrentUser();
    window.open(ENV.SSO_URL, "_self");
  }

  return {
    identityValid: AuthLogic.hasAuthToken,
    logout,
    setLastPathAndQuery,
    redirectToSSO,
    processAuthToken,
  };
}
