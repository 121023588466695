import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import {
  ActivityLogic,
  BaseButton,
  BaseLoader,
  KEYS,
  NumberHelper,
  SharedConfigManager,
  useBreakPointUp,
} from "blace-frontend-library";
import cn from "classnames";
import { PaginationComponent } from "@/src/component/partial/Pagination";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { NeedsUpdateInquiriesCounter } from "@/src/component/view/Dashboard/component/LeftContainer/component/NeedsUpdateInquiriesCounter";
import { BlaceV2Type } from "@/src/type";
import { InquiryLoadingProcess } from "@/src/type/blaceV2";
import styles from "./LeftContainer.module.scss";
import { EventList } from "./component/EventList";
import { InputFilter } from "./component/Filters";
import { FilterSortingResponsiveWrapper } from "./component/Filters/FilterSortingResponsiveWrapper";
import { OptionsWrapper } from "./component/Filters/OptionsWrapper";
import { SearchFiltersFallback } from "./component/SearchFiltersFallback";

interface LeftContainerProps {
  isLeftContainerCollapsed: boolean;
  paginationPagesAmount: number;
  paginationCurrentPage: number;
  totalResults: number;
  inquriesList?: BlaceV2Type.InquirySearchType[] | undefined;
  selectedInquiry: BlaceV2Type.InquirySearchType | undefined;
  setSelectedInquiry: (
    inquiry?: BlaceV2Type.InquirySearchType | undefined
  ) => void;
  setIsLeftContainerCollapsed: any;
  appliedFilters?: number;
}

function LeftContainer({
  isLeftContainerCollapsed,
  inquriesList,
  totalResults,
  paginationPagesAmount,
  paginationCurrentPage,
  appliedFilters,
  selectedInquiry,
  setSelectedInquiry,
  setIsLeftContainerCollapsed,
}: LeftContainerProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const dashboardContext = useContext(DashboardContext);
  const isDesktop = useBreakPointUp("md");
  const inquiriesContainerRef = useRef<HTMLDivElement>(null);
  const [isOpenFiltersDropDown, setIsOpenFiltersDropDown] =
    useState<boolean>(false);
  const [isDialogFiltersOpen, setIsDialogFiltersOpen] =
    useState<boolean>(false);
  const [isOpenSortsDropDown, setIsOpenSortsDropDown] =
    useState<boolean>(false);
  const [isDialogSortsOpen, setIsDialogSortsOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEventSelectOpen, setIsEventSelectOpen] = useState(false);
  const isNoSearchMatchesInquiry = false;

  const isFiltersOpened = isOpenFiltersDropDown || isDialogFiltersOpen;
  const isSortingOpened = isOpenSortsDropDown || isDialogSortsOpen;
  const isOpenDropDown = isOpenFiltersDropDown || isOpenSortsDropDown;
  const isOpenDialog = isDialogFiltersOpen || isDialogSortsOpen;

  const onClickCollapseHandler = () => {
    setIsLeftContainerCollapsed(!isLeftContainerCollapsed);
  };

  const onFocusInputHandler = () => {
    isOpenFiltersDropDown && setIsOpenFiltersDropDown(false);
  };

  const handleClosePopperDropDown = () => {
    isOpenFiltersDropDown && setIsOpenFiltersDropDown(false);
    isOpenSortsDropDown && setIsOpenSortsDropDown(false);
  };

  const handleMobileDialogClose = () => {
    isDialogFiltersOpen && setIsDialogFiltersOpen(false);
    isDialogSortsOpen && setIsDialogSortsOpen(false);
  };

  const onInquiryItemClick = useCallback(
    (id: string) => {
      const selectedInquiry = inquriesList?.find(
        (inquiry: BlaceV2Type.InquirySearchType) => inquiry.inquiryId === id
      );
      setSelectedInquiry(selectedInquiry);
      if (selectedInquiry) {
        ActivityLogic.toActivityService({
          action: "selectInquiry_bdash",
          actionId: id,
          actionIdType: "inquiry",
          actionMessage: id,
          locationInApp: "LeftContainer.tsx",
        });
      }
    },
    [inquriesList, setSelectedInquiry]
  );

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsOpenFiltersDropDown(false);
    } else if (event.key === "Escape") {
      setIsOpenFiltersDropDown(false);
    }
  }, []);

  const handleDropDownFiltersToggle = useCallback(() => {
    if (isNoSearchMatchesInquiry) {
      isDesktop
        ? setIsOpenFiltersDropDown(false)
        : setIsDialogFiltersOpen(false);

      return;
    }

    isDesktop
      ? setIsOpenFiltersDropDown((prevOpen) => !prevOpen)
      : setIsDialogFiltersOpen((prevOpen) => !prevOpen);
  }, [isDesktop, isNoSearchMatchesInquiry]);

  const handleDropDownSortsToggle = useCallback(() => {
    if (isNoSearchMatchesInquiry) {
      isDesktop ? setIsOpenSortsDropDown(false) : setIsDialogSortsOpen(false);

      return;
    }

    isDesktop
      ? setIsOpenSortsDropDown((prevOpen) => !prevOpen)
      : setIsDialogSortsOpen((prevOpen) => !prevOpen);
  }, [isDesktop, isNoSearchMatchesInquiry]);

  useEffect(() => {
    if (inquiriesContainerRef) {
      inquiriesContainerRef?.current?.scrollTo(0, 0);
    }
  }, [inquriesList]);

  return (
    <div className={styles.leftContainer}>
      <Typography className={styles.inquriesListTitle} variant="h4">
        Inquiries&nbsp;
        <span
          className={styles.inquriesListAmount}
          data-testid={"inquiriesAmount"}
        >
          {NumberHelper.withCommas(totalResults.toString(), true)}
        </span>
      </Typography>
      {false && (
        <BaseButton
          className={styles.buttonCollapse}
          onClick={onClickCollapseHandler}
        >
          <img
            src={`${SVG_URL}/arrowRight.svg`}
            height="40"
            width="40"
            alt="collapse icon"
          />
        </BaseButton>
      )}
      <Stack className={styles.filters}>
        <div className={styles.filtersWrapper}>
          <InputFilter
            appliedFilters={appliedFilters}
            handleDropDownFiltersToggle={handleDropDownFiltersToggle}
            handleDropDownSortsToggle={handleDropDownSortsToggle}
            onFocusInputHandler={onFocusInputHandler}
          />
          <FilterSortingResponsiveWrapper
            isOpenDropDown={isOpenDropDown}
            isOpenDialog={isOpenDialog}
            isDesktop={isDesktop}
            handleClosePopperDropDown={handleClosePopperDropDown}
            handleListKeyDown={handleListKeyDown}
            handleMobileDialogClose={handleMobileDialogClose}
          >
            <OptionsWrapper
              isFiltersOpened={isFiltersOpened}
              isSortingOpened={isSortingOpened}
              handleClosePopperDropDown={handleClosePopperDropDown}
              handleMobileDialogClose={handleMobileDialogClose}
            />
          </FilterSortingResponsiveWrapper>
        </div>
      </Stack>
      {!!dashboardContext?.needsUpdateInquiryNumber && (
        <div className={styles.counterWrapper}>
          <NeedsUpdateInquiriesCounter
            counterValue={dashboardContext?.needsUpdateInquiryNumber}
          />
        </div>
      )}
      {dashboardContext.loadingProcess === InquiryLoadingProcess.FILTER ? (
        <BaseLoader size={24} />
      ) : (
        <div
          className={cn(styles.inquriesContainer, {
            [styles.withNeedsUpdateInquiriesCounter]: !!dashboardContext?.needsUpdateInquiryNumber,
          })}
          ref={inquiriesContainerRef}
        >
          {(inquriesList ?? []).length > 0 && (
            <EventList
              inquriesList={inquriesList}
              selectedInquiryId={selectedInquiry?.inquiryId}
              onInquiryItemClick={onInquiryItemClick}
            />
          )}
          {(inquriesList ?? []).length === 0 && (
            <SearchFiltersFallback fallbackType={"noResults"} />
          )}
        </div>
      )}
      {(inquriesList ?? []).length > 0 && (
        <PaginationComponent
          paginationPagesAmount={paginationPagesAmount}
          paginationCurrentPage={paginationCurrentPage}
          responsiveSize={true}
        />
      )}
    </div>
  );
}

export default LeftContainer;
