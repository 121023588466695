import { useMemo } from "react";
import { AuthLogic } from "blace-frontend-library";
import cn from "classnames";
import { BookedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/BookedMessage";
import { ChangedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChangedMessage";
import { ClosedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ClosedMessage";
import { EmailLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/EmailLogMessage";
import { ReopenMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ReopenMessage";
import { SubmittedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/SubmittedMessage";
import { BlaceV2Type } from "@/src/type";
import styles from "./LogListItemManager.module.scss";

interface LogListItemManagerProps {
  inquiryMessage: BlaceV2Type.InquiryLog;
}

function LogListItemManager({ inquiryMessage }: LogListItemManagerProps) {

  const LogListItemComponent = useMemo(() => {
    switch (inquiryMessage.eventType) {
      case BlaceV2Type.InquiryLogEventTypes.Submit:
        return <SubmittedMessage message={inquiryMessage} />;
      case BlaceV2Type.InquiryLogEventTypes.Closed:
        return <ClosedMessage message={inquiryMessage} />;
      case BlaceV2Type.InquiryLogEventTypes.Change:
        return <ChangedMessage message={inquiryMessage} />;
      case BlaceV2Type.InquiryLogEventTypes.Booked:
        return <BookedMessage message={inquiryMessage} />;
      case BlaceV2Type.InquiryLogEventTypes.Email:
        return <EmailLogMessage message={inquiryMessage} />;
      case BlaceV2Type.InquiryLogEventTypes.Reopen:
          return <ReopenMessage message={inquiryMessage} />;
      default:
        return <></>;
    }
  }, [inquiryMessage]);

  const logListItemClasses = useMemo(() => {
    // TODO : migrate to just `isFromCurrentUser` after BE fix (https://blace.atlassian.net/browse/B20-759)
    const isEmail = inquiryMessage.eventType === BlaceV2Type.InquiryLogEventTypes.Email;
    const isFromCurrentUser = inquiryMessage.userId === AuthLogic.getV2AuthUserId();
    const isFromClient = inquiryMessage.sourceType === BlaceV2Type.InquiryLogSourseTypes.Client;
    //flip the direction for the B2B portal
    return cn(styles.logListItem, { [styles.isFromRight]: isEmail ? isFromCurrentUser : !isFromClient });
  }, [inquiryMessage]);

  return <div className={cn(logListItemClasses)}>{LogListItemComponent}</div>;
}

export default LogListItemManager;
