import React from "react";
import { Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DragBar, DraggablePaper, useDragToClose } from "blace-frontend-library";
import styles from "./MobileDialog.module.scss";

interface MobileDialogProps {
  isDialogFiltersOpen: boolean;
  handleDialogClose: () => void;
  children: React.ReactElement;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MobileDialog({
  isDialogFiltersOpen,
  handleDialogClose,
  children,
}: MobileDialogProps) {
  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: handleDialogClose,
  });

  return (
    <>
      {isDialogFiltersOpen ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              alignItems: "flex-end",
            },
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "100%",
              margin: "0",
            },
          }}
          open={true}
          TransitionComponent={Transition}
          keepMounted
          PaperComponent={props => (
            <DraggablePaper
              handleStop={handleDragStop}
              handleDrag={handleDrag}
              dragId={dragId}
              {...props}
            />
          )}
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DragBar dragId={dragId} />
          <div className={styles.titleWrapper} data-testid="dialog">
            <Typography variant="h4" className={styles.filterTitle}>
              Filter
            </Typography>
          </div>
          <div className={styles.filtersWrapper}>{children}</div>
        </Dialog>
      ) : null}
    </>
  );
}

export default MobileDialog;
