import React from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseModal, useBreakPointDown } from "blace-frontend-library";
import styles from "./BaseConfirmationModal.module.scss";

interface BaseConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmationText: string;
}

function BaseConfirmationModal({
  isOpen,
  handleClose,
  handleConfirm,
  confirmationText,
}: BaseConfirmationModalProps) {
  const isMobile = useBreakPointDown("md");

  return (
    <BaseModal
      dialogProps={{
        open: isOpen,
        onClose: handleClose,
      }}
      onExit={handleClose}
    >
      <div className={styles.confirmModalTitleWrapper}>
        <Typography className={styles.confirmModalTitle}>{confirmationText}</Typography>
      </div>
      <div className={styles.confirmButtons}>
        {isMobile && (
          <BaseButton className={styles.confirmButton} onClick={handleConfirm}>
            Confirm
          </BaseButton>
        )}
        <BaseButton className={styles.cancelButton} onClick={handleClose}>
          Cancel
        </BaseButton>
        {!isMobile && (
          <BaseButton className={styles.confirmButton} onClick={handleConfirm}>
            Confirm
          </BaseButton>
        )}
      </div>
    </BaseModal>
  );
}

export default BaseConfirmationModal;
