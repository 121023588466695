import React from "react";
import { Typography } from "@mui/material";
import { BaseIcon } from "blace-frontend-library";
import cn from "classnames";
import styles from "./NoImagePlaceholder.module.scss";

interface NoImagePlaceholderProps {
  placeholderText: string;
  isLightBackground?: boolean;
}

function NoImagePlaceholder({
  placeholderText,
  isLightBackground = false,
}: NoImagePlaceholderProps) {
  return (
    <div
      className={cn(styles.imagePlaceholder, {
        [styles.withLightBackground]: isLightBackground,
      })}
      data-testid="no-image-placeholder"
    >
      <BaseIcon iconFileName="imgLoadBoxIcon" iconAlt="no image icon" iconSize={20} />
      <Typography className={styles.noImageText}>{placeholderText}</Typography>
    </div>
  );
}

export default NoImagePlaceholder;
