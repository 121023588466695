import React, { ForwardedRef, useState } from "react";
import { Typography } from "@mui/material";
import { BaseIcon, useBreakPointUp } from "blace-frontend-library";
import cn from "classnames";
import { BrowseFileInput } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PhotosContent/component/BrowseFileInput";
import styles from "./UploadPlaceholder.module.scss";

interface UploadPlaceholderProps {
  handleDrop: React.DragEventHandler<HTMLDivElement>;
  handleFileUpload: React.ChangeEventHandler<HTMLInputElement>;
  isDisabled: boolean;
  dropZoneClassName?: string;
  supportedText?: string;
}

const UploadPlaceholder = React.forwardRef(function UploadPlaceholder(
  {
    handleDrop,
    handleFileUpload,
    isDisabled,
    dropZoneClassName,
    supportedText,
  }: UploadPlaceholderProps,
  ref: ForwardedRef<HTMLInputElement | null>
) {
  const isDesktop = useBreakPointUp("md");
  const [isDragOver, setIsDragOver] = useState(false);

  const desktopUploadText = (
    <>
      Drag & drop files or{" "}
      <BrowseFileInput
        handleFileUpload={handleFileUpload}
        ref={ref}
        isDisabled={isDisabled}
      />
    </>
  );

  const mobileUploadText = (
    <>
      <BrowseFileInput
        handleFileUpload={handleFileUpload}
        ref={ref}
        isDisabled={isDisabled}
      />{" "}
      files
    </>
  );

  function handleDragOver(ev: React.DragEvent<HTMLDivElement>) {
    if (isDisabled) {
      ev.nativeEvent.preventDefault();
      return false;
    }
    ev.preventDefault();

    setIsDragOver(true);
  }

  function handleDragLeave(ev: React.DragEvent<HTMLDivElement>) {
    if (isDisabled) {
      return false;
    }
    ev.preventDefault();
    setIsDragOver(false);
  }

  function handleDragDrop(ev: React.DragEvent<HTMLDivElement>) {
    if (isDisabled) {
      ev.nativeEvent.preventDefault();
      return false;
    }
    handleDrop(ev);
    setIsDragOver(false);
  }

  return (
    <div
      className={cn(styles.dropZone, dropZoneClassName, {
        [styles.activeDrop]: isDragOver,
        exclude: isDesktop,
      })}
      onDrop={handleDragDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      draggable={false}
      data-testid="upload-placeholder"
    >
      <BaseIcon
        iconFileName="uploadCloudIcon"
        iconAlt="cloud to upload photos"
        iconSize={20}
      />
      <Typography className={styles.uploadText}>
        {isDesktop ? desktopUploadText : mobileUploadText}
      </Typography>
      {supportedText && (
        <Typography className={styles.uploadSupportedText}>
          {supportedText}
        </Typography>
      )}
    </div>
  );
});

export default UploadPlaceholder;
