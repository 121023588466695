import React, { useRef } from "react";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { FormRef } from "@/src/type/app";
import { ContactForm } from "./components/ContactForm";

function ContactContent() {
  const formRef = useRef<FormRef>(null);

  const onSaveContactFormData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <CategoryContentWrapper
      category={ListingCategories.Contacts}
      onSaveFormData={onSaveContactFormData}
    >
      <ContactForm ref={formRef} onSaveContactFormData={onSaveContactFormData} />
    </CategoryContentWrapper>
  );
}

export default ContactContent;
