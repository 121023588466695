import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { BlaceV2Type } from "@/src/type";
import { ChangedEventDataItem } from "./component";

interface ChangedMessageProps {
  message: BlaceV2Type.InquiryLog;
}

function ChangedMessage({ message }: ChangedMessageProps) {
  const {
    newStartDate,
    oldStartDate,
    newEndDate,
    oldEndDate,
    newFlexibleDates,
    oldFlexibleDates,
    newGuests,
    oldGuests,
  } = message.shortProperties;
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <div data-testid="changed-message">
        <ChangedEventDataItem
          oldValue={oldStartDate}
          newValue={newStartDate}
          title={BlaceV2Type.ChangedShortProperties.StartDate}
        />
        <ChangedEventDataItem
          oldValue={oldEndDate}
          newValue={newEndDate}
          title={BlaceV2Type.ChangedShortProperties.EndDate}
        />
        <ChangedEventDataItem
          oldValue={oldFlexibleDates}
          newValue={newFlexibleDates}
          title={BlaceV2Type.ChangedShortProperties.DateFlexibility}
        />
        <ChangedEventDataItem
          oldValue={oldGuests}
          newValue={newGuests}
          title={BlaceV2Type.ChangedShortProperties.Guests}
        />
      </div>
    </ChatLogMessage>
  );
}

export default ChangedMessage;
