import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { BaseButton, BaseModal } from "blace-frontend-library";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./TwoOptionsPopup.module.scss";

interface PopupOption {
  img: string;
  text: string;
  onClick?: () => void;
  link?: string;
  icon?: ReactNode;
  isDisabled?: boolean;
}

interface TwoOptionsPopupProps {
  popupTitle: string;
  isOpen: boolean;
  onClose: () => void;
  firstOption: PopupOption;
  secondOption: PopupOption;
}

function TwoOptionsPopup({
  isOpen,
  onClose,
  firstOption,
  secondOption,
  popupTitle,
}: TwoOptionsPopupProps) {
  return (
    <BaseModal dialogProps={{ open: isOpen, onClose }} onExit={onClose}>
      <Box className={styles.modalTitleWrapper}>
        <Typography className={styles.modalTitle}>{popupTitle}</Typography>
      </Box>
      <Box className={styles.optionsContainer}>
        <Link
          className={styles.link}
          to={firstOption.link || ""}
          onClick={(e) => {
            if (!firstOption.link || firstOption.isDisabled) {
              e.preventDefault();
            }
          }}
        >
          <Box
            className={cn(styles.option, { [styles.disabled]: firstOption.isDisabled })}
            onClick={firstOption.onClick}
          >
            <BaseButton
              startIcon={firstOption.icon}
              className={cn(styles.optionButton, { [styles.disabled]: firstOption.isDisabled })}
            >
              {firstOption.text}
            </BaseButton>
            <img
              className={styles.optionImage}
              src={firstOption.img}
              alt={`${firstOption.text}-image`}
              aria-label={`${firstOption.text}-image`}
            />
          </Box>
        </Link>
        <Link
          className={styles.link}
          to={secondOption.link || ""}
          onClick={(e) => {
            if (!secondOption.link || secondOption.isDisabled) {
              e.preventDefault();
            }
          }}
        >
          <Box
            className={cn(styles.option, { [styles.disabled]: secondOption.isDisabled })}
            onClick={secondOption.onClick}
          >
            <BaseButton
              startIcon={secondOption.icon}
              className={cn(styles.optionButton, { [styles.disabled]: secondOption.isDisabled })}
            >
              {secondOption.text}
            </BaseButton>
            <img
              className={styles.optionImage}
              src={secondOption.img}
              alt={`${secondOption.text}-image`}
              aria-label={`${secondOption.text}-image`}
            />
          </Box>
        </Link>
      </Box>
    </BaseModal>
  );
}

export default TwoOptionsPopup;
