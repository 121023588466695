import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { AuthCallback } from "@/src/component/view";
import { OneTimeLinkPage } from "@/src/component/view/OneTimeLinkPage";
import { AuthenticatedRouter } from "./AuthenticatedRouter";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/404" element={<div>Not Found</div>} />
      <Route path="/otl" element={<OneTimeLinkPage />} />
      <Route path="*" element={<AuthenticatedRouter />} />
    </Route>
  )
);
const RootRouter = () => <RouterProvider router={router} />;

export default RootRouter;
