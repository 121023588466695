export type GeocodedAddress = {
  formatted_address: string;
  place_id: string;
  address_components: GeocodedAddressComponent[];
};

export type GeocodedAddressComponent = {
  long_name: string;
  short_name: string;
  types: GeocodedAddressComponentType[];
};

export type ReverseGeocodedAddressResponse = {
  success: boolean;
  location: ReverseGeocodedAddress;
};

export type ReverseGeocodedAddress = {
  status: string;
  results: GeocodedAddress[];
};

export enum GeocodedAddressComponentType {
  ADMINISTRATIVE_AREA_LEVEL_1 = "administrative_area_level_1",
  ADMINISTRATIVE_AREA_LEVEL_2 = "administrative_area_level_2",
  ADMINISTRATIVE_AREA_LEVEL_3 = "administrative_area_level_3",
  COUNTRY = "country",
  LOCALITY = "locality",
  NEIGHBORHOOD = "neighborhood",
  POLITICAL = "political",
  POSTAL_CODE = "postal_code",
  ROUTE = "route",
  STREET_NUMBER = "street_number",
  SUBLOCALITY = "sublocality",
  SUBLOCALITY_LEVEL_1 = "sublocality_level_1",
}
