import React from "react";
import { Chip } from "@mui/material";
import cn from "classnames";
import { ListingTag } from "@/src/type/app";
import styles from "./TagLabel.module.scss";

interface TagItemProps {
  tag: ListingTag;
  isSelected: boolean;
  onClick: (tag: ListingTag, isSelected: boolean) => void;
}

const TagLabel = ({ tag, isSelected, onClick }: TagItemProps) => {
  return (
    <Chip
      className={cn(styles.tagLabel, { [styles.selected]: isSelected })}
      label={tag.label}
      onClick={() => onClick(tag, isSelected)}
    />
  );
};

export default TagLabel;
