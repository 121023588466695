import { useEffect, useMemo, useState } from "react";
import { Drawer } from "@mui/material";
import {
  BlaceLogoSmallSVG,
  BlaceLogoSVG,
  BurgerSVG,
  RightArrowSVG,
  useBreakPointDown,
} from "blace-frontend-library";
import cn from "classnames";
import { Link, useSearchParams } from "react-router-dom";
import BlacePartnersLogoSVG from "@/src/assets/svg/BlacePartnersLogoSVG";
import { NavigationDrawer } from "@/src/component/partial/Header/component/NavigationDrawer";
import { DasboardNavDrawerParams } from "@/src/component/view/Dashboard/Dashboard";
import { DASHBOARD_NAV_DRAWER_PARAM } from "@/src/component/view/Dashboard/DashboardConstant";
import styles from "./Header.module.scss";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useBreakPointDown("md");
  const drawerHeight: number = useMemo(() => (isMobile ? 0 : 80), [isMobile]);

  const drawerStatus = searchParams.get(DASHBOARD_NAV_DRAWER_PARAM);

  const onClickDrawerHandler = () => {
    setDrawerOpen(!drawerOpen);
    
    const drawerStatus = drawerOpen ? DasboardNavDrawerParams.Closed : DasboardNavDrawerParams.Open;
    searchParams.set(DASHBOARD_NAV_DRAWER_PARAM, drawerStatus);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setDrawerOpen(drawerStatus === DasboardNavDrawerParams.Open);
  }, [drawerStatus]);

  return (
    <header className={cn(styles.headerSticky, styles.header)}>
      <div className={cn(styles.headerLeft)}>
        <Link
          to={"/dashboard"}
          aria-label="Go To Dashboard"
          className={styles.logoLink}
        >
          <div className={styles.headerLeftDesktopLogo}>
            <BlaceLogoSVG />
            <div className={styles.headerLogoLabel}>
              <BlacePartnersLogoSVG />
            </div>
          </div>
          <div className={styles.headerLeftMobileLogo}>
            <BlaceLogoSmallSVG />
          </div>
        </Link>
      </div>
      <div className={styles.headerRight}>
        <nav className={styles.headerNav}>
          <div className={styles.headerNavLinks}>
            <Link to={"/listings"}>Manage Listings</Link>
          </div>
          <div className={styles.headerNavLinks}>
            <Link to={"/dashboard"}>Inquiries</Link>
          </div>
          <div
            data-testid="drawer-switcher-wrapper"
            className={styles.headerOptsDrawerBtn}
            onClick={onClickDrawerHandler}
          >
            <div className={styles.headerOptsDrawerBtnInner}>
              {drawerOpen ? (
                <div data-testid="drawer-close">
                  <RightArrowSVG width={9} />
                </div>
              ) : (
                <div data-testid="drawer-opener">
                  <BurgerSVG width={18} />
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
      <Drawer
        slotProps={{
          backdrop: {
            invisible: true,
            sx: {
              top: drawerHeight,
            },
          },
        }}
        sx={{
          top: drawerHeight,
        }}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        className={styles.drawerScrollbar}
        PaperProps={{
          sx: {
            width: 345,
            top: drawerHeight,
            maxWidth: "100vw",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50))",
            backdropFilter: "blur(50px)",
            "@media (max-width: 780px)": {
              width: "100%",
            },
          },
        }}
        transitionDuration={isMobile ? 0 : undefined}
      >
        {isMobile && (
          <div>
            <div
              className={styles.drawerHeaderExitBtn}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <div className={styles.headerOptsDrawerBtnInner}>
                <RightArrowSVG width={9} />
              </div>
            </div>
          </div>
        )}
        <NavigationDrawer />
      </Drawer>
    </header>
  );
}

export default Header;
