import React from "react";
import { Typography } from "@mui/material";
import { ImageLogic } from "blace-frontend-library";
import { NoImagePlaceholder } from "@/src/component/base";
import { SearchType } from "@/src/type/blaceV2/search";
import styles from "./ListingTitleCell.module.scss";

interface ListingTitleCellProps {
  listing: SearchType.SearchItem;
}

function ListingTitleCell({ listing }: ListingTitleCellProps) {
  const imageUrl = ImageLogic.getMainImageUrl(listing);
  return (
    <div className={styles.listingCell}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={`${listing.title} photo`}
          className={styles.listingImage}
        />
      ) : (
        <NoImagePlaceholder placeholderText={"Edit your listing to add a cover photo"} />
      )}
      <Typography className={styles.listingTitle}>{listing.title}</Typography>
    </div>
  );
}

export default ListingTitleCell;
