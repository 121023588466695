import React from "react";
import { BaseIcon } from "blace-frontend-library";

interface PhotoTagProps {
  tagStyles: string;
  tagIconName?: string;
  tagText: string;
  handleClick: () => void;
}

function PhotoCoverTag({
  tagStyles,
  tagIconName,
  tagText,
  handleClick,
}: PhotoTagProps) {
  return (
    <span className={tagStyles} onClick={handleClick}>
      {tagIconName && <BaseIcon iconFileName={tagIconName} iconAlt="star" />}
      {tagText}
    </span>
  );
}

export default PhotoCoverTag;
