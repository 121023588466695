import {
  ClickAwayListener,
  Fade,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import styles from "./DesktopPopper.module.scss";

interface FilterDesktopProps {
  isOpenFiltersDropDown: boolean;
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  handleClosePopperDropDown: () => void;
  children: React.ReactElement;
}

function FilterDesktop({
  isOpenFiltersDropDown,
  handleListKeyDown,
  handleClosePopperDropDown,
  children,
}: FilterDesktopProps) {
  const timeBeforePopperFade = 350;
  
  return (
    <Popper
      className={styles.filtersDropDown}
      open={isOpenFiltersDropDown}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      //anchorEl=//FIX THIS ERROR
    >
      {({ TransitionProps }) => (
        <div className={styles.filtersDropDownInner} data-testid="popper">
          <Fade {...TransitionProps} timeout={timeBeforePopperFade}>
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopperDropDown}>
                <MenuList
                  autoFocusItem={isOpenFiltersDropDown}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        </div>
      )}
    </Popper>
  );
}

export default FilterDesktop;
