import { INQUIRY_STATUS_EXCHANGE } from "@/src/component/view/Dashboard/DashboardConstant";
import { BlaceB2BType } from "@/src/type";
import styles from "./InquiryStatusLabel.module.scss";

interface InquiryStatusLabelProps {
  //todo: need to fix frontend types in client
  inquiryStatus: any;
}

function InquiryStatusLabel({ 
  inquiryStatus 
}: InquiryStatusLabelProps) {
  //switch based on inquiry status  
  if (INQUIRY_STATUS_EXCHANGE?.[inquiryStatus]) {
    switch (INQUIRY_STATUS_EXCHANGE?.[inquiryStatus]) {
      case BlaceB2BType.B2BInquiryType.InquiryStatus.COMPLETED:
        return (
          <div className={styles.completed}>
            {BlaceB2BType.B2BInquiryType.InquiryStatus.COMPLETED}
          </div>
        );
      case BlaceB2BType.B2BInquiryType.InquiryStatus.BOOKED:
        return (
          <div className={styles.completed}>
            {BlaceB2BType.B2BInquiryType.InquiryStatus.BOOKED}
          </div>
        );
      case BlaceB2BType.B2BInquiryType.InquiryStatus.CLOSED:
        return (
          <div className={styles.canceled}>
            {BlaceB2BType.B2BInquiryType.InquiryStatus.CLOSED}
          </div>
        );
    }
  }

  return (
    <div className={styles.open}>
      {BlaceB2BType.B2BInquiryType.InquiryStatus.OPEN}
    </div>
  );
}

export default InquiryStatusLabel;
