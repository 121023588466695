import { createContext, useContext } from "react";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";

export interface RoomContentContextType {
  selectedRoom?: Partial<SearchRoomV2>;
  setRooms: (rooms: Partial<SearchRoomV2>[]) => void;
  allRooms: Partial<SearchRoomV2>[];
  handlePublishRoomClick: (roomId?: string) => void;
  handleDeleteRoom:(roomId?: string) => void;
  deactivateRoom: (roomId?: string) => void;
  setSelectedRoom: (room?: Partial<SearchRoomV2>) => void;
}

const RoomContentContext = createContext<RoomContentContextType | undefined>(
  undefined
);

const useRoomContext = (): RoomContentContextType => {
  const context = useContext(RoomContentContext);
  if (!context) {
    throw new Error("useRoomContent must be used within a RoomContentProvider");
  }
  return context;
};

export default { useRoomContext, RoomContentContext };
