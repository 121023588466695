import React from "react";
import {
  KEYS,
  SharedConfigManager,
  useBreakPointDown,
} from "blace-frontend-library";
import styles from "./DashboardSkeleton.module.scss";

function DashboardSkeleton() {
  const isMobile = useBreakPointDown("md");
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  return (
    <div className={styles.skeletonWrapper}>
      <img
        src={`${SVG_URL}/${isMobile ? "SkeletonMobile" : "SkeletonDesktop"}.svg`}
        alt="skeleton"
        className={styles.skeleton}
      />
    </div>
  );
}

export default DashboardSkeleton;
