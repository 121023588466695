import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { FILTERS, VenueLogic } from "blace-frontend-library";
import { useBlocker } from "react-router-dom";
import { InfoAlert } from "@/src/component/base";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { FormLogic } from "@/src/model";
import { BlockerArgs, ListingTag, ListingTagSectionType } from "@/src/type/app";
import { SearchAttributeType } from "@/src/type/blaceV2";
import { SearchAttribute } from "@/src/type/blaceV2/search/SearchType";
import { TagsSectionList } from "./components/TagsSectionList";

function TagsContent() {
  const tagsSections: ListingTagSectionType[] = [
    FILTERS.venueType,
    FILTERS.bestUsedFor,
    FILTERS.amenities,
  ];

  const {
    listingItemData,
    listingItemSaveHandler,
    hasUnsavedData,
    setHasUnsavedData,
    isEditRequestSubmitting,
    setIsSaveButtonDisabled,
  } = useContext(ListingManagementContext) || {};

  const [selectedTags, setSelectedTags] = useState<SearchAttribute[]>([]);

  const isSaveDisabled = isEditRequestSubmitting || !hasUnsavedData;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      !!hasUnsavedData && currentLocation.pathname !== nextLocation.pathname,
  );

  const onSaveTagsFormData = async () => {
    if (listingItemSaveHandler) {
      await listingItemSaveHandler({ attributes: selectedTags });
    }
  };

  useEffect(() => {
    let currentAttributes = listingItemData?.attributes?.length ? listingItemData.attributes : [];
    const venueTypeAttributes = VenueLogic.getAttributesByType(
      SearchAttributeType.VENUE_TYPE,
      currentAttributes,
    );
    // fill in attributes based on categories
    if (!venueTypeAttributes?.length && listingItemData?.categories?.length) {
      const venueTypeValueToLabel: Record<string, string> = {};
      FILTERS.venueType?.options?.map((option: ListingTag) => {
        venueTypeValueToLabel[option.value] = option.label;
      });

      const venueTypeAttributes = listingItemData.categories.map(category => {
        const categoryLabel = venueTypeValueToLabel[category];
        if (!categoryLabel) {
          return undefined;
        }

        return {
          attributeType: SearchAttributeType.VENUE_TYPE,
          attributeId: category,
          label: categoryLabel,
          value: "true",
        };
      }).filter(attribute => !!attribute);

      // for some reason check doesn't understand undefined filtration `.filter(attribute => !!attribute)`
      //@ts-ignore
      currentAttributes = [...currentAttributes, ...venueTypeAttributes];
    }

    setSelectedTags(currentAttributes);
  }, [listingItemData?.attributes, listingItemData?.categories]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [hasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button and other statuses
  useEffect(() => {
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isSaveDisabled);
  }, [isSaveDisabled, setIsSaveButtonDisabled]);

  return (
    <CategoryContentWrapper category={ListingCategories.Tags} onSaveFormData={onSaveTagsFormData}>
      <Box>
        <InfoAlert text="To ensure clients can find your venue, please select at least one tag per section" />
        <TagsSectionList
          sections={tagsSections}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Box>
    </CategoryContentWrapper>
  );
}

export default TagsContent;
