import { ChangeEvent, useContext, useState } from "react";
import { TextField } from "@mui/material";
import { BaseButton } from "blace-frontend-library";
import cn from "classnames";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { BaseInquiryModal } from "@/src/component/view/Dashboard/component/RightContainer/components/BaseInquiryModal";
import { CloseReasonRadioGroup } from "@/src/component/view/Dashboard/component/RightContainer/components/CloseReasonRadioGroup";
import { BlaceB2BType } from "@/src/type";
import { InquiryLoadingProcess, InquiryModalTypes } from "@/src/type/blaceV2";
import styles from "./CloseInquiryModal.module.scss";

interface CloseInquiryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function CloseInquiryModal({
  isOpen,
  onClose,
}: CloseInquiryModalProps) {
  const dashboardContext = useContext(DashboardContext);
  const [closeReason, setCloseReason] =
    useState<BlaceB2BType.B2BInquiryType.CloseInquiryOptions | null>(null);
  const [otherReason, setOtherReason] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const isCloseLoading = dashboardContext.loadingProcess === InquiryLoadingProcess.CLOSE;

  const isRequestCloseBtnDisabled =
    !closeReason ||
    (closeReason === BlaceB2BType.B2BInquiryType.CloseInquiryOptions.OTHER &&
      !otherReason) ||
    otherReason.length > 75 ||
    isCloseLoading;
  const isOtherOptionSelected =
    closeReason === BlaceB2BType.B2BInquiryType.CloseInquiryOptions.OTHER;

  const clearCloseInquiryFields = () => {
    setCloseReason(null);
    setError(null);
  };

  function handleCloseReasonChange(
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    setCloseReason(value as BlaceB2BType.B2BInquiryType.CloseInquiryOptions);
  }

  const handleOtherReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setOtherReason(event.target.value);
  };

  function handleCloseInquiry() {
    if (typeof dashboardContext?.closeInquiry !== "function") {
      return;
    }
    clearCloseInquiryFields();
    dashboardContext?.closeInquiry(
      closeReason ?? BlaceB2BType.B2BInquiryType.CloseInquiryOptions.OTHER
    );
  }

  const handleCloseModal = () => {
    clearCloseInquiryFields();
    onClose();
  };

  return (
    <BaseInquiryModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      error={error}
      inquiryModalType={InquiryModalTypes.CLOSE}
    >
      <CloseReasonRadioGroup
        value={closeReason}
        onChange={handleCloseReasonChange}
        options={Object.values(BlaceB2BType.B2BInquiryType.CloseInquiryOptions)}
      />
      {isOtherOptionSelected && (
        <TextField
          inputProps={{ maxLength: 200 }}
          helperText={`${otherReason.length}/75`}
          inputRef={(input) => input && input.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          className={cn(styles.otherReasonField, {
            [styles.error]: otherReason.length > 75,
          })}
          fullWidth
          placeholder="Please provide more information"
          value={otherReason}
          onChange={handleOtherReasonChange}
        />
      )}
      <BaseButton
        fullWidth
        loading={isCloseLoading}
        disabled={isRequestCloseBtnDisabled}
        className={styles.closeRequestButton}
        onClick={handleCloseInquiry}
      >
        Close Inquiry
      </BaseButton>
    </BaseInquiryModal>
  );
}


export default CloseInquiryModal;
