import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Log } from "blace-frontend-library";
import cn from "classnames";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { InquiryLogic } from "@/src/model";
import { B2BInquiryServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { InquiryStatusLabels } from "@/src/type/blaceV2";
import styles from "./ChatTabContent.module.scss";
import { ChatTabFallback } from "./component/ChatTabFallback";
import { LogListItem } from "./component/LogListItem";

interface ChatTabContentProps {
  selectedInquiry?: BlaceV2Type.InquirySearchType;
}

export interface InquiryMessagesList {
  [key: number]: BlaceV2Type.InquiryLog[];
}

function ChatTabContent({ 
  selectedInquiry,
}: ChatTabContentProps) {
  const dashboardContext = useContext(DashboardContext);
  const chatTabWrapper: any = useRef();
  const [logDataList, setLogDataList] = useState<BlaceV2Type.InquiryLog[] | null>(null);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const isLogEmpty = !Boolean(logDataList);
  const isFallbackVisible = isFetching || isLogEmpty || isError;

  const chatTabWrapperClasses = useMemo(() => {
    return cn(styles.chatTabWrapper, {
      [styles.withClosedBanner]:
        selectedInquiry?.inquiryStatus === InquiryStatusLabels.Closed,
      [styles.withNeedsUpdateBanner]: InquiryLogic.isInquiryNeedsUpdate(selectedInquiry),
    });
  }, [selectedInquiry]);

  const inquiryMessagesList = useMemo(() => {
    //TODO - move to helper
    if (logDataList) {
      const sortedMessagesList = logDataList.sort(
        (a, b) => a.createDate - b.createDate
      );

      const groupedMessagesByTimestamp = {};
      sortedMessagesList.forEach(result => {
        const timestamp = result.createDate;
        //@ts-ignore
        if (!groupedMessagesByTimestamp[timestamp]) {
          //@ts-ignore
          groupedMessagesByTimestamp[timestamp] = [];
        }
        //@ts-ignore
        groupedMessagesByTimestamp[timestamp].push(result);
      });

      return Object.entries(groupedMessagesByTimestamp).map(
        ([timestamp, objects]) => ({
          [timestamp]: objects,
        })
      ) as InquiryMessagesList[];
    }
    return null;
  }, [logDataList]);

  useEffect(() => {
    const fetchActivityLogData = async () => {
      setIsFetching(true);
      if (!selectedInquiry?.inquiryId) {
        setIsFetching(false);
        return;
      }
      try {
        const responseData = await B2BInquiryServiceV2.getInquiryLogs(selectedInquiry?.inquiryId);
        if (responseData.body?.metadata?.statusCode === 200) {
          const { results } = responseData.body.payload;
          setLogDataList(results);
          setIsFetching(false);
        }
      } catch (error) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "ChatTabContent.tsx",
          "ChatLogError",
          [error]
        );
        setLogDataList(null);
        setIsFetching(false);
        setIsError(true);
      }
    };

    fetchActivityLogData();
  }, [selectedInquiry?.inquiryId, dashboardContext.refreshLogId]);

  useEffect(()=>{
    if (!logDataList) {
      return;
    }

    chatTabWrapper?.current?.scrollTo({
      top: chatTabWrapper?.current?.scrollHeight ?? 0,
      behavior: "instant"
    });
  },[chatTabWrapper, logDataList]);


  if (isFallbackVisible) {
    return (
      <ChatTabFallback
        isFetching={isFetching}
        isLogEmpty={isLogEmpty}
        isError={isError}
      />
    );
  }

  return (
    <Box className={chatTabWrapperClasses} ref={chatTabWrapper}>
      <ul className={styles.inquiryMessagesList}>
        {inquiryMessagesList?.map((inquiryMessages, index) => {
          return (
            <li key={index}>
              <LogListItem inquiryMessages={inquiryMessages} />
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

export default ChatTabContent;
