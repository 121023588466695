import { Dispatch, SetStateAction, useMemo } from "react";
import { Typography } from "@mui/material";
import { BaseButton, KEYS, SharedConfigManager } from "blace-frontend-library";
import { invert } from "lodash";
import { INQUIRY_CLOSED_REASON_EXCHANGE } from "@/src/component/view/Dashboard/DashboardConstant";
import { BlaceB2BType } from "@/src/type";
import styles from "./ClosedInquiryBanner.module.scss";
interface ClosedInquiryBannerProps {
  closedReason?: string;
  setIsReopenInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
}

function ClosedInquiryBanner({ closedReason, setIsReopenInquiryModalOpen }: ClosedInquiryBannerProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  const userCloseOptions = BlaceB2BType.B2BInquiryType.CloseInquiryOptions;

  const closedReasonUserValue = useMemo(() => {
    if (closedReason) {
      return invert(INQUIRY_CLOSED_REASON_EXCHANGE)[closedReason] ?? userCloseOptions.OTHER;
    }
  }, [closedReason, userCloseOptions.OTHER]);

  const handleReopenInquiry = () => {
    setIsReopenInquiryModalOpen(true);
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerTitleWrapper}>
        <img
          src={`${SVG_URL}/closeOutlineIcon.svg`}
          height="20"
          width="20"
          alt="close icon"
        />
        <Typography className={styles.bannerTitle}>
          Inquiry is closed
        </Typography>
      </div>
      <Typography className={styles.bannerReason}>
        Reason: {closedReasonUserValue || "The inquiry has been closed"}
      </Typography>
      <BaseButton className={styles.bannerCTA} onClick={handleReopenInquiry}>
        Reopen inquiry
        <img
          src={`${SVG_URL}/arrowRightIcon.svg`}
          height="11"
          width="11"
          alt="reopen icon"
        />
      </BaseButton>
    </div>
  );
}

export default ClosedInquiryBanner;
