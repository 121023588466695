import { Dispatch, SetStateAction, useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import {
  BaseButton,
  KEYS,
  SharedConfigManager,
  useBreakPointUp,
} from "blace-frontend-library";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { BookingDataInquiry } from "@/src/component/view/Dashboard/Dashboard";
import { CloseInquiryModal } from "@/src/component/view/Dashboard/component/RightContainer/components/CloseInquiryModal";
import { ConfirmBookingModal } from "@/src/component/view/Dashboard/component/RightContainer/components/ConfirmBookingModal";
import { ReopenInquiryModal } from "@/src/component/view/Dashboard/component/RightContainer/components/ReopenInquiryModal";
import { InquiryLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { InquiryStatusLabels } from "@/src/type/blaceV2";
import styles from "./MainContainer.module.scss";
import { ClosedInquiryBanner } from "./components/ClosedInquiryBanner";
import { MiddlePanelTabs } from "./components/MiddlePanelTabs";
import { UpdateInquiryBanner } from "./components/UpdateInquiryBanner";

interface MainContainerProps {
  selectedInquiry?: BlaceV2Type.InquirySearchType;
  isFAQDesktopOpen: boolean;
  isLeftContainerCollapsed: boolean;
  isCloseInquiryModalOpen: boolean;
  isReopenInquiryModalOpen: boolean;
  isConfirmBookingInquiryModalOpen: boolean;
  isRightContainerCollapsed: boolean;
  initialLoad: boolean;
  setIsLeftContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setIsRightContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setIsCloseInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsReopenInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsConfirmBookingInquiryModalOpen: Dispatch<SetStateAction<boolean>>;
  handleInquiryToConfirmBooking: (bookingData: BookingDataInquiry) => void;
  handleStillInContact: () => void;
  setSelectedInquiry: (
    inquiry?: BlaceV2Type.InquirySearchType | undefined
  ) => void;
}

function MainContainer({
  selectedInquiry,
  isFAQDesktopOpen,
  isCloseInquiryModalOpen,
  isReopenInquiryModalOpen,
  isConfirmBookingInquiryModalOpen,
  isLeftContainerCollapsed,
  isRightContainerCollapsed,
  initialLoad,
  setSelectedInquiry,
  setIsLeftContainerCollapsed,
  setIsRightContainerCollapsed,
  setIsConfirmBookingInquiryModalOpen,
  setIsCloseInquiryModalOpen,
  setIsReopenInquiryModalOpen,
  handleInquiryToConfirmBooking,
  handleStillInContact
}: MainContainerProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  const isDesktop = useBreakPointUp("md");

  const navigate = useNavigate();

  const onClickCollapseHandlerLeft = () => {
    setIsLeftContainerCollapsed(!isLeftContainerCollapsed);
  };
  const onClickCollapseHandlerRight = () => {
    setIsRightContainerCollapsed(!isRightContainerCollapsed);
  };

  const handleCloseInquiryModalClose = () => {
    setIsCloseInquiryModalOpen(false);
  };

  const handleCloseInquiryModalOpen = () => {
    setIsCloseInquiryModalOpen(true);
  };

  const handleConfirmBookingModalClose = () => {
    setIsConfirmBookingInquiryModalOpen(false);
  };

  const handleConfirmBookingModalOpen = () => {
    setIsConfirmBookingInquiryModalOpen(true);
  };

  const handleReopenInquiryModalClose = () => {
    setIsReopenInquiryModalOpen(false);
  };

  /**
   * when the initial load contains an inquiry id the selected inquiry must be cleared
   * otherwise the back button works as designed
   */

  const goBack = () => {
    if (initialLoad) {
      if (typeof setSelectedInquiry === "function") {
        setSelectedInquiry(undefined);
      }
    } else {
      navigate(-1);
    }
  };

  const collapseLeftButtonStyles = useMemo(() => {
    return isFAQDesktopOpen
      ? [`${styles.buttonCollapseLeft} ${styles.buttonCollapseFaqOpen}`]
      : [styles.buttonCollapseLeft];
  }, [isFAQDesktopOpen]);

  const collapseRightButtonStyles = useMemo(() => {
    return isFAQDesktopOpen
      ? [`${styles.buttonCollapseRight} ${styles.buttonCollapseFaqOpen}`]
      : [styles.buttonCollapseRight];
  }, [isFAQDesktopOpen]);

  return (
    <div className={styles.mainWrapper}>
      {isDesktop && (
        <BaseButton
          className={cn(collapseLeftButtonStyles)}
          onClick={onClickCollapseHandlerLeft}
          data-testid="left-collapse-btn"
        >
          <img
            src={`${SVG_URL}/${isLeftContainerCollapsed ? "arrowRight" : "arrowLeft"}.svg`}
            height="40"
            width="40"
            alt="collapse icon"
          />
        </BaseButton>
      )}
      {selectedInquiry && (
        <div className={styles.titleLine}>
          {!isDesktop && (
            <BaseButton onClick={goBack} className={styles.backButton} data-testid="back-btn">
              <ArrowBackIcon sx={{ color: "#000" }} />
            </BaseButton>
          )}
          <div className={styles.inquiryTitleContainer}>
            <Typography variant="h3" className={styles.inquiryTitle}>
              {selectedInquiry?.catalogItem?.title}
            </Typography>
            <Typography variant="h6" className={styles.inquirySubtitle}>
              ID: {selectedInquiry?.inquiryId}
            </Typography>
          </div>
        </div>
      )}
      {selectedInquiry?.inquiryStatus === InquiryStatusLabels.Closed && (
        <ClosedInquiryBanner
          closedReason={selectedInquiry?.closedLostReason}
          setIsReopenInquiryModalOpen={setIsReopenInquiryModalOpen}
        />
      )}
      {selectedInquiry && InquiryLogic.isInquiryNeedsUpdate(selectedInquiry) && (
        <UpdateInquiryBanner
          onClose={handleCloseInquiryModalOpen}
          onBook={handleConfirmBookingModalOpen}
          onStillInContact={handleStillInContact}
        />
      )}
      <MiddlePanelTabs selectedInquiry={selectedInquiry} />
      {isDesktop ? (
        <BaseButton
          className={cn(collapseRightButtonStyles)}
          onClick={onClickCollapseHandlerRight}
          data-testid="right-collapse-btn"
        >
          <img
            src={`${SVG_URL}/${isRightContainerCollapsed ? "arrowLeft" : "arrowRight"}.svg`}
            height="40"
            width="40"
            alt="collapse icon"
          />
        </BaseButton>
      ) : (
        <></>
      )}
      <CloseInquiryModal
        isOpen={isCloseInquiryModalOpen}
        onClose={handleCloseInquiryModalClose}
      />
      <ConfirmBookingModal
        id={selectedInquiry?.inquiryId}
        isOpen={isConfirmBookingInquiryModalOpen}
        onClose={handleConfirmBookingModalClose}
        handleInquiryToConfirmBooking={handleInquiryToConfirmBooking}
      />
      <ReopenInquiryModal
        isOpen={isReopenInquiryModalOpen}
        onClose={handleReopenInquiryModalClose}
      />
    </div>
  );
}

export default MainContainer;
