import { Log } from "blace-frontend-library";

export async function putFileToServer(
  url: string,
  fileNameCanonical: string,
  file: File,
  contentType: string = "application/pdf"
) {
  const headers = {
    "Content-Type": contentType,
    "x-ms-blob-type": "BlockBlob",
    "x-ms-version": "2019-12-12",
    "x-ms-blob-content-type": contentType,
    "x-ms-blob-content-disposition": `inline; filename="${fileNameCanonical}"`,
  };

  const request = {
    method: "PUT",
    headers: headers,
    body: file,
  };
  return await fetch(url, request)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      Log.logToDataDog(
        "ERROR",
        "AzureBlobService.tsx",
        "error uploading a file to Azure blob storage",
        [err]
      );
    });
}
