import React, { memo } from "react";
import { BaseIcon, BaseLoader } from "blace-frontend-library";
import cn from "classnames";
import { PhotoLogic } from "@/src/model";
import { RoomPhotoFile } from "@/src/type/app";
import styles from "./RoomPhoto.module.scss";

interface PhotoProps {
  photo: RoomPhotoFile;
  isSelected?: boolean;
  index: number;
  handleDeleteIconClick: (imageHash?: string) => void;
  handlePhotoSelection?: (isSelected: boolean, photo: RoomPhotoFile) => void;
}

const Photo = memo(({ photo, index, isSelected, handleDeleteIconClick }: PhotoProps) => {
  const fileImageUrl = PhotoLogic.getFileImageUrl(photo);

  return (
    <div
      data-testid="room-photo"
      key={photo.imageHash}
      className={cn(styles.photoWrapper, {
        [styles.selected]: isSelected,
      })}
    >
      <img
        src={fileImageUrl}
        alt={`photo ${index + 1}`}
        className={cn(styles.photo, {
          [styles.isLoading]: photo.loading,
        })}
      />
      {photo.loading && (
        <BaseLoader size={16} color="inherit" wrapperClassName={styles.imageLoaderWrapper} />
      )}
      <div
        className={styles.trashIconContainer}
        onClick={() => handleDeleteIconClick(photo.imageHash)}
      >
        <BaseIcon
          iconFileName="trashIcon"
          iconAlt="remove icon"
          data-testid="remove-photo-icon"
          iconSize={24}
        />
      </div>

      {/*
        // TODO will be used for deleting several photos
        <div
          className={cn(styles.checkboxWrapper, {
            [styles.isSelected]: isSelected,
          })}
        >
          <input
            data-testid="photo-selection-checkbox"
            type="checkbox"
            checked={isSelected}
            onChange={(e) => handlePhotoSelection(e.target.checked, photo)}
          />
        </div> */}
    </div>
  );
});

Photo.displayName = "Photo";

export default Photo;
